import React from "react";
import { domainsRefresh } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useDomains } from "../../state/api/domains/useDomains";
import { useTenantDomains } from "../../state/api/domains/useTenantDomains";
import { IDomain } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import LabelButton from "../buttons/LabelButton";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import DeleteDomainButton from "./DeleteDomainButton";
import DomainDnsEntryTable from "./DomainDnsEntryTable";

interface IDomainListItemProps {
  domain: IDomain
}

export default function DomainListItem({ domain }: IDomainListItemProps) {

  const [dnsVisible, setDnsVisible] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const { reloadDomains } = useDomains();
  const { reloadTenantDomains } = useTenantDomains();

  const hasDnsEntries = domain && domain.dnsEntries && !!domain.dnsEntries.length;
  const dnsEntryCount = hasDnsEntries ? domain.dnsEntries.length : 0;

  const callApi = useApi();

  const refreshDomainStatus = async () => {
    setDnsVisible(false);
    setIsRefreshing(true);

    await callApi(domainsRefresh({ domainId: domain._id }));
    await reloadDomains();
    await reloadTenantDomains();
    setIsRefreshing(false);
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <div className="d-flex flex-column align-items-start">
            <span>{domain.domain}</span>
            <LabelButton icon={hasDnsEntries ? (dnsVisible ? "chevron-up" : "chevron-down") : "braces-asterisk"} disabled={!hasDnsEntries} onClick={async () => setDnsVisible(!dnsVisible)} text={hasDnsEntries ? (dnsVisible ? "DNS-Einträge ausblenden" : `DNS-Einträge anzeigen (${dnsEntryCount})`) : "Keine DNS-Einträge vorhanden"} />
          </div>
        </TableCell>
        <TableCell>
          {
            isRefreshing
              ? <LoadingSpinner centered={false} text="Status wird überprüft..." />
              : <Icon icon={domain.isVerified ? "check" : "clock"} color={domain.isVerified ? "success" : undefined} label={domain.isVerified ? "" : "Verifizierung ausstehend"} />
          }
        </TableCell>
        <TableCell className="d-flex justify-content-end w-100 gap-2">
          <Button disabled={domain.isVerified} onClick={refreshDomainStatus} icon="arrow-clockwise" text="Status Aktualisieren" className="float-end me-3" />
          <DeleteDomainButton domainId={domain._id} />
        </TableCell>
      </TableRow>
      {
        dnsVisible && domain && domain.dnsEntries && !!domain.dnsEntries.length && (
          <TableRow>
            <TableCell colSpan={3}>
              <DomainDnsEntryTable domain={domain} />
            </TableCell>
          </TableRow>
        )
      }
    </>
  )
}