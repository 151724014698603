import React from "react";
import Page from "../components/container/Page";
import CalendarOverview from "../components/calendar/CalendarOverview";

export default function Calendar() {
  return (
    <Page header="Kalender" showBackButton>
      <CalendarOverview />
    </Page>
  )
}