import React from "react";
import ClientResponsibilityList from "../../components/clientResponsibility/ClientResponsibilityList";
import RawClientResponsibilityList from "../../components/clientResponsibility/RawClientResponsibilityList";
import Page from "../../components/container/Page";
import WithPermissions from "../../components/permissions/WithPermissions";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import { Locale } from "../../locale/Locale";

export enum ClientResponsibilityOverviewTab {
  MappedToClient = "pretty",
  RawData = "raw"
}

export default function ClientResponsibilityOverview() {

  const [activeTab, setActiveTab] = React.useState<string>(ClientResponsibilityOverviewTab.MappedToClient);

  return (
    <WithPermissions permissions={["clientResponsibilities.all.read"]}>
      <Page showBackButton header={Locale.titles.clientResponsibilityManagement}>
        <WithPermissions permissions={["interfaces.datev.read"]}>
          <TabSwitcher
            tabQueryParamKey="format"
            tabs={[
              {
                data: ClientResponsibilityOverviewTab.MappedToClient,
                label: "Mit Mandatszuweisung"
              },
              {
                data: ClientResponsibilityOverviewTab.RawData,
                label: "Rohdaten aus DATEV"
              }
            ]}
            saveActiveTab={t => setActiveTab(t)}
          />
        </WithPermissions>
        {
          activeTab === ClientResponsibilityOverviewTab.MappedToClient
            ? <ClientResponsibilityList />
            : (
              <WithPermissions permissions={["interfaces.datev.read"]}>
                <RawClientResponsibilityList />
              </WithPermissions>
            )
        }
      </Page>
    </WithPermissions>
  )
}