import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import { IAuthorizationOptions } from "../../../hooks/useAuthorized";
import { setActiveGroup } from "../../../state/slices/sidebarSlice";
import IElementProps from "../../../types/element.types";
import { NavGroup } from "../../../types/nav.types";
import Icon from "../../icons/Icon";
import WithPermissions from "../../permissions/WithPermissions";
import SideNavElement from "./SideNavElement";
import "./SideNavGroup.css";
import { hexWithOpacity } from "../../../util/util";

export interface ISideNavGroupProps extends IElementProps, IAuthorizationOptions {
  isExpandedInitially?: boolean,
  displayCondition?: boolean,
  label: string,
  groupId: NavGroup,
}

export default function SideNavGroup({ children, className, style, permissions, superAdminOnly, displayCondition = true, label, isExpandedInitially = false, groupId }: ISideNavGroupProps) {

  const [hover, setHover] = React.useState<boolean>(false);
  const [isActive, setIsActive] = React.useState<boolean>();

  const dispatch = useAppDispatch();

  const {
    activeGroup
  } = useAppSelector(state => state.sidebar);

  React.useEffect(() => {
    setIsActive(activeGroup === groupId);
  }, [activeGroup]);

  const childrenContainerClass = generateClassName(className, "side-nav-group-content pe-2 h-100 w-100", {
    value: isActive,
    base: "side-nav-group-content-",
    onTrue: "expanded",
    standard: "collapsed"
  });

  const childrenContainerStyle = generateStyle({
    name: "maxHeight",
    value: 0,
    applyCondition: !isActive,
    unit: "px"
  }, {
    name: "paddingBottom",
    value: 12,
    applyCondition: isActive,
    unit: "px"
  });

  const iconClassName = generateClassName("side-nav-group-expander", {
    base: "side-nav-group-expander-",
    value: isActive,
    onTrue: "expanded",
    standard: "collapsed"
  })

  const expanderClassName = generateClassName("side-nav-expander-container d-flex flex-row align-items-center justify-content-between", {
    value: isActive,
    onTrue: "side-nav-expander-container-expanded"
  });

  const clickHandler = () => {
    if (isActive) dispatch(setActiveGroup(NavGroup.None));
    else dispatch(setActiveGroup(groupId));
  }

  if (!displayCondition) return null;

  return (
    <WithPermissions permissions={permissions} superAdminOnly={superAdminOnly}>
      <div
        className="side-nav-group-container"
        style={generateStyle({
          name: "backgroundColor",
          value: hexWithOpacity("secondary", 0.2, true),
          applyCondition: hover || isActive
        })}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div onClick={clickHandler} className={expanderClassName}>
          <SideNavElement title={label} />
          <Icon icon="chevron-right" size={20} className={iconClassName} />
        </div>
        <div className={childrenContainerClass} style={childrenContainerStyle} >
          {
            React.Children.map(children, (child: any, index) => React.cloneElement(child, { key: `sidenav-link-${groupId}-${index}`, parentGroup: groupId }))
          }
        </div>
      </div>
    </WithPermissions>
  )
}