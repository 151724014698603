import { Message } from "@microsoft/microsoft-graph-types";
import React from "react";
import Button from "../../components/buttons/Button";
import SearchableComboBox from "../../components/comboBox/SearchableComboBox";
import Flex from "../../components/container/Flex";
import PageHeader from "../../components/label/PageHeader";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import OfficeInbox from "../../components/mail/inbox/OfficeInbox";
import OfficeInboxMessageView from "../../components/mail/inbox/OfficeInboxMessageView";
import OfficeMailForm from "../../components/mail/inbox/OfficeMailForm";
import WithPermissions from "../../components/permissions/WithPermissions";
import SearchBox from "../../components/search/SearchBox";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useFilteredOfficeMails, { OfficeMailboxTab } from "../../hooks/useFilteredOfficeMails";
import useLabels, { Label } from "../../hooks/useLabels";
import Page from "../../components/container/Page";
import { setRightSidebarExpanded } from "../../state/slices/sidebarSlice";
import { useCurrentUserOfficeAccount } from "../../state/api/office/useCurrentUserOfficeAccount";
import { useMainTenantOfficeAccount } from "../../state/api/office/useMainTenantOfficeAccount";
import { useOfficeAccounts } from "../../state/api/office/useOfficeAccounts";
import { useUser } from "../../state/api/user/useUser";
import { IOfficeAccount, OfficeAccountStatus, OfficeAccountType } from "../../types/officeAccount.types";
import "./OfficeInboxPage.css";
import { IMailfolder } from "../../types/mailfolder.schema";
import LinkOfficeAccountButton from "../../components/mail/account/LinkOfficeAccountButton";


export default function OfficeInboxPage() {

  const [editorVisible, setEditorVisible] = React.useState<boolean>(false);
  const [isResponding, setIsResponding] = React.useState<boolean>(false);
  const [isRespondingAll, setIsRespondingAll] = React.useState<boolean>(false);

  const [selectedFolder, setSelectedFolder] = React.useState<IMailfolder>();
  const [selectedMail, setSelectedMail] = React.useState<Message>();

  const [account, setAccount] = React.useState<IOfficeAccount>();

  const {
    inbox,
    updateFilter,
    filterText
  } = useFilteredOfficeMails();

  const getLabel = useLabels();
  const dispatch = useAppDispatch();

  const {
    user
  } = useUser();

  const {
    currentUserOfficeAccount,
    reloadCurrentUserOfficeAccount,
    loadingCurrentUserOfficeAccount
  } = useCurrentUserOfficeAccount();

  const {
    loadingOfficeAccounts,
    officeAccounts
  } = useOfficeAccounts();

  const {
    loadingTenantOfficeAccount,
    tenantOfficeAccount
  } = useMainTenantOfficeAccount();

  const openMail = (m: Message | undefined) => {
    dispatch(setRightSidebarExpanded(true));
    setSelectedMail(m);
  }

  const respondToMail = (respondAll?: boolean) => {
    setEditorVisible(true);
    setIsResponding(true);
    setIsRespondingAll(!!respondAll);
  }

  const discardMail = () => {
    setEditorVisible(false);
    setIsResponding(false);
  }

  React.useEffect(() => {
    reloadCurrentUserOfficeAccount();
  }, [user]);

  React.useEffect(() => {
    const realAccount = (
      inbox === OfficeMailboxTab.Personal
        ? currentUserOfficeAccount
        : (inbox === OfficeMailboxTab.Tenant ? tenantOfficeAccount : account)
    );
    setAccount(realAccount);
  }, [inbox, user, currentUserOfficeAccount, loadingCurrentUserOfficeAccount, tenantOfficeAccount, loadingTenantOfficeAccount]);

  return (
    <Page
      widerSidebar
      rightSidebar={(selectedMail && account) && (
        <OfficeInboxMessageView
          item={selectedMail}
          respond={respondToMail}
          folderId={selectedFolder?.folderId}
          account={account}
          closeMessage={() => setSelectedMail(undefined)}
        />
      )}
    >
      <Flex className="w-100 h-100">
        <Flex row gap={5} justify="between" className="w-100">
          <Flex className="w-100" row gap={3}>
            <PageHeader showBackButton text={selectedFolder?.displayName ?? "Posteingang"} />
            {
              account && <Button iconSize={30} onClick={async () => setEditorVisible(true)} icon="plus-circle-fill" variant="icon" />
            }
          </Flex>
          <SearchBox
            initialValue={filterText}
            onChange={val => updateFilter({ search: val })}
            delay={500}
          />
        </Flex>
        <WithPermissions permissions={["inboxes.teams.read", "inboxes.tenant.read", "inboxes.all.read"]}>
          <Flex className="w-100" row wrap justify="between">
            <TabSwitcher
              tabs={[
                {
                  data: OfficeMailboxTab.Personal,
                  label: "Ihr Postfach",
                  icon: "person-fill"
                },
                {
                  data: OfficeMailboxTab.Teams,
                  label: getLabel(Label.TeamMailAccounts),
                  permissions: ["inboxes.teams.read"],
                  icon: "people-fill"
                },
                {
                  data: OfficeMailboxTab.Tenant,
                  label: getLabel(Label.TenantName),
                  permissions: ["inboxes.tenant.read"],
                  icon: "building"
                },
                {
                  data: OfficeMailboxTab.All,
                  label: "Alle",
                  permissions: ["inboxes.all.read"],
                  backColor: "#314AF0",
                  icon: "inboxes-fill"
                }
              ]}
              tabQueryParamKey="inbox"
            />
            <Flex row>
              {
                (account && account.status !== OfficeAccountStatus.Active) && (
                  <LinkOfficeAccountButton
                    accountType={account.type}
                    onBehalfOfUser={account.assignments?.personalUser}
                    reAuthAccount={account._id}
                  />
                )
              }
              <WithPermissions permissions={["inboxes.all.read"]}>
                {
                  inbox === OfficeMailboxTab.All && (
                    <SearchableComboBox
                      value={account}
                      values={officeAccounts ?? []}
                      noValuesPlaceholder="Keine Postfächer gefunden"
                      itemToId={item => item._id}
                      itemToString={item => item.accountMail}
                      onChange={item => setAccount(item)}
                      placeholder="Bitte Postfach auswählen..."
                      loading={loadingOfficeAccounts}
                      loadingText="Lädt Konten..."
                    />
                  )
                }
              </WithPermissions>
            </Flex>
          </Flex>
        </WithPermissions>
        <Flex fullWidth fullHeight style={{ overflowY: "auto", minHeight: "300px" }}>
          {
            (loadingOfficeAccounts || loadingTenantOfficeAccount || loadingCurrentUserOfficeAccount)
              ? <LoadingSpinner color="primary" />
              : (
                <OfficeInbox
                  setEditorVisible={setEditorVisible}
                  setSelectedMail={openMail}
                  setSelectedFolder={setSelectedFolder}
                  account={account}
                  isEditorVisible={editorVisible}
                  isResponding={isResponding}
                  selectedFolder={selectedFolder}
                  selectedMail={selectedMail}
                  showAll={inbox === OfficeMailboxTab.All}
                />
              )
          }
        </Flex>
        {
          editorVisible && account && (
            <OfficeMailForm
              respondAll={isRespondingAll}
              item={isResponding && selectedMail ? selectedMail : undefined}
              account={account}
              discardMail={discardMail}
            />
          )
        }
      </Flex>
    </Page>
  )
}