import React from "react";
import { useEmployees } from "../../../../state/api/employees/useEmployees";
import { useTicketAssignments } from "../../../../state/api/tickets/assignments/useTicketAssignments";
import { useCurrentTenantRole } from "../../../../state/api/user/useCurrentTenantRole";
import { useUser } from "../../../../state/api/user/useUser";
import { ITicket, IUser } from "../../../../types/ApiTypes";
import Button from "../../../buttons/Button";
import Flex from "../../../container/Flex";
import LoadingSpinner from "../../../loader/LoadingSpinner";
import FastModalDialog from "../../../modal/FastModalDialog";
import ModalDialog from "../../../modal/ModalDialog";
import UserRoleBanner from "../../../user/RoleBanner";
import UserPicker from "../../../user/UserPicker";

export interface ITicketMessageMentionsProps {
  mentions: Array<string>,
  ticket: ITicket,
  saveMentions: (mentions: Array<string>) => void,
}

export default function TicketMessageMentions(props: ITicketMessageMentionsProps) {

  const {
    mentions
  } = props;

  const mentionCount = mentions?.length ?? 0;

  return (
    <FastModalDialog
      button={
        <Button text={mentionCount ? `${mentionCount} Nutzer erwähnt` : "Nutzer erwähnen"} variant={mentionCount ? "solid" : "text"} color={mentionCount ? "error" : "primary"} icon="at" />
      }
      dialog={<TicketMessageMentionsDialog {...props} />}
    />
  )
}

function TicketMessageMentionsDialog(props: ITicketMessageMentionsProps) {

  const {
    mentions,
    saveMentions,
    ticket
  } = props;

  const { role } = useCurrentTenantRole();
  const { user } = useUser();
  const { employees } = useEmployees();

  const { loadingTicketAssignments, ticketAssignments } = useTicketAssignments(ticket);

  const isIncluded = (id: string) => {
    if (!mentions?.length) return false;
    return mentions.includes(id);
  }

  const addToMentions = (id: string) => {
    if (!mentions?.length) {
      saveMentions([id]);
      return;
    }

    if (isIncluded(id)) return;

    const newMentions = [...mentions, id];
    saveMentions(newMentions);
  }

  const removeFromMentions = (id: string) => {
    if (!mentions?.length) return;
    const newMentions = mentions.filter(mention => mention !== id);
    saveMentions(newMentions);
  }

  if (loadingTicketAssignments) return <LoadingSpinner />;

  const getAvailableUsers = (): Array<string> | null => {
    const result: Array<string> = [];
    if (user?.isSuperAdmin) return null;
    if (!role) return result;
    if (!role.isClient) return null;
    if (!ticketAssignments?.length) return result;
    for (const a of ticketAssignments) {
      if (!a.user) continue;
      result.push(a.user);
    }
    return result;
  }

  const idsToShow = getAvailableUsers();

  if (idsToShow && !idsToShow.length) return null;

  return (
    <ModalDialog
      {...props}
      title="Nutzer erwählen"
      sidebar={
        (close: any) => <Button onClick={async () => close()} text="Speichern" />
      }
    >
      <Flex fullWidth gap={1}>
        <Flex row wrap fullWidth style={{ maxHeight: "400px", overflow: "auto" }}>
          <UserPicker
            showTheseIds={getAvailableUsers()}
            displayed={idsToShow === null ? "employees" : "all"}
            values={mentions}
            saveValues={saveMentions}
          />
        </Flex>
      </Flex>
    </ModalDialog>
  )
}