import { ErrorMessage, Field, FieldAttributes, useFormikContext } from "formik";
import { generateClassName } from "../../hooks/useAttributes";
import React from "react";
import IElementProps from "../../types/element.types";
import "./CheckBox.css";
import Tooltip, { IWithTooltip } from "../tooltip/Tooltip";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import { AppColor } from "../../app/AppStyles";

interface ICheckBoxProps extends IElementProps, IWithTooltip {
  label?: string,
  readOnly?: boolean,
  onChange?: (val: boolean) => void,
  initialValue: boolean,
  textColor?: AppColor,
  bold?: boolean,
  type?: string
}

export default function CustomCheckBox({ label, textColor = "primary", onChange, tooltip, readOnly, initialValue, bold }: ICheckBoxProps) {

  const labelClassName = generateClassName("form-label check-box-label ms-1 m-0", { value: bold, onTrue: "fw-bold" });
  const fieldId = `${label}-checkbox-field`;

  const changeHandler = (e: any) => {
    onChange?.(!!e.target.checked);
  }

  const field = (
    <input
      disabled={readOnly}
      defaultChecked={initialValue}
      id={fieldId}
      onChange={changeHandler}
      style={{
        marginTop: "3px",
        width: "14px",
        minWidth: "14px",
        height: "14px",
        minHeight: "14px",
      }}
      type="checkbox"
      className="form-check-input p-0"
    />
  );

  if (!label) return field;

  return (
    <Tooltip tooltip={tooltip}>
      <Flex>
        <Flex row align="start" gap={1}>
          {field}
          <label htmlFor={fieldId} className={labelClassName} style={{ userSelect: "none", color: `var(--${textColor})`, opacity: readOnly ? "0.6" : undefined }}>{label}</label>
          {
            tooltip && <Icon icon="info-circle" size={12} className="mt-1" />
          }
        </Flex>
      </Flex>
    </Tooltip>
  )
}
