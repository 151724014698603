import React from "react";
import useApi from "../../../hooks/useApi";
import { useSession } from "../../../state/api/session/useSession";
import { useTenants } from "../../../state/api/tenant/useTenants";
import Button from "../../buttons/Button";
import Expandable from "../../comboBox/Expandable";
import { sessionSwitchTenant } from "../../../api/Api";
import { useTenant } from "../../../state/api/tenant/useTenant";
import Typography from "../../text/Typography";
import Pill from "../../pills/Pill";
import ExpandableItem from "../../comboBox/ExpandableItem";
import { ITenant } from "../../../types/ApiTypes";

export default function SessionTenantSelect() {
  const { reloadSession } = useSession();

  const {
    tenant
  } = useTenant();

  const {
    tenants
  } = useTenants();

  const callApi = useApi();

  if (!tenant) return null;
  if (!tenants?.length || tenants.length < 2) return null;

  const usableTenants = tenants.filter(t => !!t.name);

  if (!usableTenants.length) return null;

  const getTenantName = (t: ITenant) => t.name.substring(0, 30);

  return (
    <Expandable
      expander={<Button variant="subtle" text={getTenantName(tenant)} icon="building" />}
    >
      {
        usableTenants.map(t => (
          <ExpandableItem
            key={t._id}
            onClick={async () => {
              const res = await callApi(sessionSwitchTenant({ id: t._id }));
              if (!res?.success) return;
              await reloadSession(undefined, { revalidate: true });
              window.location.reload();
            }}
            disabled={t._id === tenant._id}
            color={t._id === tenant._id ? "primary" : "muted"}
            text={getTenantName(t)}
            icon={t._id === tenant._id ? "check" : undefined}
          />
        ))
      }

    </Expandable>
  )
}