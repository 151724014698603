import React, { PropsWithChildren } from "react";
import Error from "../pages/Error";
import { formatDate } from "../util/formatter";

export interface IErrorBoundaryState {
  hasError: boolean,
  error: Error | null
}

export default class ErrorBoundary extends React.Component<PropsWithChildren, IErrorBoundaryState> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  logErrorToMyService(error: any, errorInfo: any) {
    console.log("Error at " + formatDate(new Date(), true, true) + ":")
    console.log(error);
    console.log(errorInfo);
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) return <Error hideBackButton error={this.state.error} />
    return this.props.children;
  }
}