import React from "react";
import Page from "../../components/container/Page";
import VerifyUserForm from "../../components/user/VerifyUserForm";

export default function VerifyUser() {
  return (
    <Page fullscreen centered>
      <VerifyUserForm />
    </Page>
  )
}