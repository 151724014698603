import React from "react";
import { useTenants } from "../../state/api/tenant/useTenants";
import { ITenant } from "../../types/ApiTypes";
import SearchableComboBox, { ISearchableComboBoxBase } from "../comboBox/SearchableComboBox";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";

export default function TenantSelect(props: ISearchableComboBoxBase<ITenant>) {

  const {
    loadingTenants,
    tenants
  } = useTenants();

  return (
    <SearchableComboBox
      {...props}
      label="Tenant auswählen"
      values={tenants}
      loading={loadingTenants}
      itemToId={(u) => u._id}
      renderItem={(u) => <Typography>{u.name?.substring(0, 35)}</Typography>}
      itemToString={(u, e) => {
        if (!e) return `${u}`;
        return u.name.substring(0, 35);
      }}
      placeholder="Tenant auswählen..."
    />
  )

}