import { Form, Formik, FormikBag } from "formik";
import React, { useState } from "react";
import Logo from "../../../components/logo/Logo";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import Page from "../../../components/container/Page";
import { sessionGetAvailableVerificationMethods, sessionSetVerificationMethod, usersRequestNewVerificationCode, usersVerifyLogIn } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import { ISessionUpdateVerificationMethodRequest, ISessionVerificationRequest } from "../../../api/ApiRequests";
import { SessionVerificationMethod } from "../../../types/ApiTypes";
import LoadingSpinner from "../../../components/loader/LoadingSpinner";
import VerificationMethodSelect from "../../../components/user/session/VerificationMethodSelect";
import LogInWithOtherUserButton from "../../../components/user/session/LogInWithOtherUserButton";
import "./SelectVerificationMethod.css";
import Flex from "../../../components/container/Flex";
import { useSession } from "../../../state/api/session/useSession";
import Typography from "../../../components/text/Typography";
import LogInPage from "./LogInPage";

export default function SelectVerificationMethod() {

  const [availableMethods, setAvailableMethods] = React.useState<Array<SessionVerificationMethod>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { reloadSession } = useSession();

  const callApi = useApi();

  React.useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();

    const fetchAvailableMethods = async () => {
      const res = await callApi(sessionGetAvailableVerificationMethods(abortController));

      if (abortController.signal.aborted) return;

      if (!res?.success || !res?.data?.length) setAvailableMethods([]);
      else setAvailableMethods(res.data);

      setLoading(false);
    }

    fetchAvailableMethods();

    return () => abortController.abort();

  }, []);

  return (
    <LogInPage description="Bitte wählen Sie eine Authentifizierungsmethode aus:" loadingText="Lädt verfügbare Authentifizierungsmethoden..." loading={loading}>
      <Formik
        initialValues={{
          method: SessionVerificationMethod.None
        } as ISessionUpdateVerificationMethodRequest}
        onSubmit={async (values, actions) => {
          if (!values) return;

          const res = await callApi(sessionSetVerificationMethod(values));

          if (!res) return;

          await reloadSession();
        }}
      >
        {
          formik => {
            const selectMethod = (m: SessionVerificationMethod) => {
              formik.setFieldValue("method", m);
              formik.submitForm();
            }

            return (
              <Form className="select-verification-method-container align-items-center justify-content-center text-center m-3 d-flex flex-column gap-3">
                <div className="d-flex flex-sm-row flex-column align-items-center justify-content-center gap-4">
                  {
                    availableMethods && !!availableMethods.length
                      ? availableMethods.map(a => (
                        <VerificationMethodSelect
                          loading={formik.values.method === a && formik.isSubmitting}
                          disabled={formik.isSubmitting}
                          key={a}
                          method={a}
                          saveMethod={selectMethod}
                        />
                      ))
                      : <Typography basedOnThisBackground="primary" size="18" bold wrap>Es gibt leider keine Möglichkeit, Ihre Session zu verifizieren. Bitte verwenden Sie einen anderen Nutzer.</Typography>
                  }
                </div>
                <LogInWithOtherUserButton />
              </Form>
            )
          }
        }
      </Formik>
    </LogInPage>
  )
}