import React from "react";
import { AppColor } from "../../../app/AppStyles";
import { useAppSelector } from "../../../hooks/reduxHooks";
import useTeamsUtil from "../../../hooks/useTeamsUtil";
import useUserUtil from "../../../hooks/useUserUtil";
import { formatDate } from "../../../util/formatter";
import Card from "../../card/Card";
import Pill from "../../pills/Pill";
import UserRoleIndicator from "../RoleBanner";
import UserAvatar from "./UserAvatar";
import Flex from "../../container/Flex";
import { useUser } from "../../../state/api/user/useUser";
import { useCurrentTenantRole } from "../../../state/api/user/useCurrentTenantRole";

export default function UserProfileHeader() {
  const { user } = useUser();
  const { role } = useCurrentTenantRole();

  const {
    getName
  } = useUserUtil();

  const {
    getAvailableTeamsForUser
  } = useTeamsUtil();

  if (!user) return null;

  const availableTeams = getAvailableTeamsForUser(user);

  return (
    <Flex className="w-100">
      <Flex row>
        <UserAvatar user={user} mailAddress={user.mailAddress} size={128} />
        <Flex>
          <h3 className="fw-bold m-0 p-0">{getName(user)}</h3>
          {
            (role && !role.isClient) && <UserRoleIndicator user={user} />
          }
          <Flex gap={0}>
            <span>Erstellt: {formatDate(user.createdAt)}</span>
            <span>Zuletzt aktualisiert: {formatDate(user.updatedAt)}</span>
          </Flex>
        </Flex>
      </Flex>
      <Card className="w-100">
        <Flex className="w-100">
          {
            role && !role.isClient && (
              <Flex>
                <strong>Meine Teams</strong>
                <Flex row wrap>
                  {
                    availableTeams && availableTeams.length
                      ? availableTeams.map(t => <Pill key={t._id} text={t.name} color="#F0F0F0" />)
                      : <span>Keine Teams.</span>
                  }

                </Flex>
              </Flex>
            )
          }
        </Flex>
      </Card>
    </Flex>
  )

}