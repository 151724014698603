import React from "react";
import { generateClassName } from "../../hooks/useAttributes";
import useUserUtil from "../../hooks/useUserUtil";
import { useUsers } from "../../state/api/user/useUsers";
import { IRoleDocument, ITenant, IUser } from "../../types/ApiTypes";
import IElementProps from "../../types/element.types";
import { getTextColorFromBackground } from "../../util/util";
import Flex from "../container/Flex";
import Banner from "../pills/Banner";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import "./RoleBanner.css";
import { getModel } from "../../util/mongoUtil";
import { useRoles } from "../../state/api/roles/useRoles";
import { useTenants } from "../../state/api/tenant/useTenants";

interface IRoleBannerProps extends IElementProps {
  user?: IUser | string | null,
  variant?: "pill" | "text",
  role?: IRoleDocument | null | undefined,
  showMail?: boolean,
  hideIcon?: boolean,
  showOnEmptyText?: string,
  tenant?: ITenant | string | null,
  displayNameAsValue?: boolean,
  displayRoleAtAllTimes?: boolean,
  onClick?: () => Promise<any>,
  inactive?: boolean,
  color?: string,
  additionalText?: string,
  displayTenantNameAsValue?: boolean
}

export default function RoleBanner({ hideIcon, additionalText, color, user, variant = "pill", role, inactive, onClick, displayRoleAtAllTimes, tenant, showOnEmptyText, displayNameAsValue, displayTenantNameAsValue = false, showMail, className, children }: IRoleBannerProps) {

  const {
    getName,
    getAuthority
  } = useUserUtil();

  const rgbToHex = (r: number, g: number, b: number) => {
    return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
  }

  const {
    loadingUsers,
    usersById
  } = useUsers();

  const {
    rolesById
  } = useRoles();

  const {
    tenantsById
  } = useTenants()

  const realUser = getModel(user, usersById);
  const authority = getAuthority(realUser, tenant);

  const realRole = role ?? getModel(authority?.userAuthority?.role, rolesById);
  const realTenant = getModel(tenant, tenantsById);

  const getColor = (): string => {
    if (variant === "text") return "white";
    if (inactive) return "#E8E8E8";
    if (!realRole) return "#F0F0F0";

    if (realRole.color) return realRole.color;
    if (realRole.isClient) return "#F0BB92"

    const accessLevel = realRole.accessLevel;

    if (accessLevel === undefined || accessLevel === null) return "#FFFFFF";
    if (accessLevel > 100) return "#000000";

    const maxR = 200;
    const maxG = 180;
    const maxB = 220;

    const r = accessLevel < 50 ? 0 : (accessLevel - 50) * 5.1;
    const g = accessLevel < 50 ? 255 - accessLevel * 5.1 : 0;
    const b = accessLevel < 50 ? accessLevel * 5.2 : 255 - (accessLevel - 50) * 5.2;

    const realR = r < 0 ? 0 : r > maxR ? maxR : r;
    const realG = g < 0 ? 0 : g > maxG ? maxG : g;
    const realB = b < 0 ? 0 : b > maxB ? maxB : b;

    return rgbToHex(realR, realG, realB);
  }

  const realClassName = generateClassName(className, {
    value: inactive,
    onTrue: "role-banner-inactive"
  });

  if (loadingUsers) return <Pill variant={variant} loading color="bright" icon={hideIcon ? undefined : "person"} className={realClassName} onClick={onClick} withDivider />

  if (!realUser && !realRole) {
    if (showOnEmptyText) return <Banner text={showOnEmptyText} color="background" />
    return null;
  }

  const name = getName(realUser);

  if (!name && displayNameAsValue) {
    if (showOnEmptyText) return <Banner text={showOnEmptyText} color="background" />
    return null;
  }

  const roleColor = getColor();
  const showRoleOnly = (!displayNameAsValue && !displayTenantNameAsValue);
  const usedColor = color ?? roleColor;

  return (
    <Pill
      loading={loadingUsers}
      color={usedColor}
      variant={variant}
      icon={hideIcon ? undefined : (realRole?.iconName || "person")}
      iconImage={realRole?.iconBase64}
      className={realClassName}
      onClick={onClick}
      withDivider
    >
      <Flex gap={0} fullWidth align="start">
        {
          (!showRoleOnly && displayNameAsValue) && <Typography color={getTextColorFromBackground(usedColor)}>{name}</Typography>
        }
        {
          (!showRoleOnly && showMail && realUser)
            ? <Typography color={getTextColorFromBackground(usedColor)} size={9}>{realUser.mailAddress}</Typography>
            : null
        }
        {
          additionalText && <Typography color={getTextColorFromBackground(usedColor)} size={9}>{additionalText}</Typography>
        }
        {
          (!showRoleOnly && displayTenantNameAsValue && realTenant)
            ? <Typography color={getTextColorFromBackground(usedColor)} size={9}>{realTenant.name}</Typography>
            : null
        }
        {((displayRoleAtAllTimes || showRoleOnly) && realRole) && <Typography color={getTextColorFromBackground(usedColor)} size={showRoleOnly ? undefined : 9}>{realRole.displayName}</Typography>}
      </Flex>
    </Pill>
  )
}