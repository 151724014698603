import React from "react";
import { absenceAdd } from "../../../api/Api";
import { IUserCreateAbsenceRequest } from "../../../api/ApiRequests";
import { AppColor } from "../../../app/AppStyles";
import useAbsenceUtil from "../../../hooks/useAbsenceUtil";
import useApi from "../../../hooks/useApi";
import useUserUtil from "../../../hooks/useUserUtil";
import { useAnyUserAbsence } from "../../../state/api/user/useAnyUserAbsence";
import { useUser } from "../../../state/api/user/useUser";
import { AbsenceType, IUser } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import Flex from "../../container/Flex";
import FormikField from "../../forms/FormikField";
import ModalForm from "../../modal/ModalForm";
import WithPermissions from "../../permissions/WithPermissions";
import UserSelect from "../UserSelect";

export interface ICreateUserAbsenceButtonProps {
  text: string,
  title: string,
  icon?: string,
  color?: AppColor,
  disabled?: boolean,
  user: IUser | null | undefined,
  type: AbsenceType,
  buttonClass?: string
}

export default function CreateUserAbsenceButton({ text, title, user, buttonClass, type, color, disabled, icon }: ICreateUserAbsenceButtonProps) {

  const { user: currentUser } = useUser();

  const {
    getName
  } = useUserUtil();

  const {
    isIntegratedWithPointChamp,
    getAvailableDeputies,
    getDateInAbsenceFormat,
    canAccessAbsenceManagement,
    loading
  } = useAbsenceUtil();

  const callApi = useApi();

  const { reloadUserAbsence } = useAnyUserAbsence(user);

  const isManagedInPointChamp = !!isIntegratedWithPointChamp && type === AbsenceType.Vacation;

  if (!user) return null;

  const isOwn = currentUser && currentUser._id === user._id;
  const availableDeputies = getAvailableDeputies(user);

  if (loading) return null;
  if (!canAccessAbsenceManagement) return null;

  return (
    <WithPermissions permissions={isOwn ? ["absence.own.create"] : ["absence.all.create"]}>
      <ModalForm
        title={isOwn ? title : `${title} für ${getName(user)}`}
        disabled={!!disabled || isManagedInPointChamp}
        button={<Button className={buttonClass} text={isManagedInPointChamp ? "Urlaubsverwaltung über PointChamp" : text} icon={icon} color={color} />}
        initialValues={{
          deputyId: "",
          userId: user._id,
          type: type,
          from: getDateInAbsenceFormat(),
          to: getDateInAbsenceFormat()
        } as IUserCreateAbsenceRequest}
        onSubmit={async (values) => {
          const res = await callApi(absenceAdd(values));

          if (!res) return false;

          await reloadUserAbsence();
          return true;
        }}
      >
        {
          (formik) => (
            <Flex className="d-flex flex-column gap-2 w-100">
              <Flex fullWidth row>
                <FormikField type="date" name="from" label="Von" noMargin className="w-100" />
                <FormikField type="date" name="to" label="Bis" noMargin className="w-100" />
              </Flex>
              <em>Sie können nach der Anlage der Abwesenheit innerhalb des Zeitraums der Abwesenheit das Enddatum bearbeiten.</em>
              {
                availableDeputies && availableDeputies.length && (
                  <UserSelect
                    label="Vertretung auswählen"
                    showTheseIds={availableDeputies}
                    onChange={(e) => formik.setFieldValue(`deputyId`, e ? e._id : "")}
                    value={formik.values.deputyId}
                  />
                )
              }
            </Flex>
          )
        }
      </ModalForm>
    </WithPermissions>
  )
}