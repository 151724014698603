import React from "react";
import Flex from "../container/Flex";
import "./PageIndicator.css";
import { generateClassName } from "../../hooks/useAttributes";

export interface IPageIndicatorProps {
  currentPage: number,
  totalPages: number,
  onPageChange: (page: number) => void
}

export default function PageIndicator(props: IPageIndicatorProps) {

  const {
    currentPage,
    totalPages,
    onPageChange
  } = props;

  const pagesDisplayed = 5;

  if (totalPages <= 1) return null;

  const createPageIndicator = (p: number, displayed: boolean = true) => {
    if (p > totalPages || p < 0) return null;
    if (!displayed) return null;

    return (
      <div className={generateClassName("page-indicator", { value: p === currentPage, onTrue: "page-indicator-active" })} onClick={() => onPageChange(p)}>
        {p + 1}
      </div>
    )
  }

  return (
    <Flex row className="pb-1 page-indicator-container">
      {createPageIndicator(0, currentPage > 0)}
      {totalPages > pagesDisplayed && currentPage > 3 && <div>...</div>}
      {createPageIndicator(currentPage - 2, currentPage > 2)}
      {createPageIndicator(currentPage - 1, currentPage > 1)}
      {createPageIndicator(currentPage)}
      {createPageIndicator(currentPage + 1, currentPage < totalPages - 1)}
      {createPageIndicator(currentPage + 2, currentPage < totalPages - 2)}
      {totalPages > pagesDisplayed && currentPage < totalPages - 4 && <div>...</div>}
      {createPageIndicator(totalPages - 1, currentPage < totalPages - 3)}
    </Flex>
  )
}