import { ApiRoutes } from "../../../api/ApiRoutes";
import useFilterQuery from "../../../hooks/useFilterQuery";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ExternalService, IAffectedLogEntryEntities, ILogEntry, LogEntryEventType, LogEntryType } from "../../../types/log.schema";
import { IQueryCursor } from "../../../types/query.schema";

export interface ILogFilter extends IQueryCursor, IAffectedLogEntryEntities {
  createdAtStart: Date;
  createdAtEnd: Date;
  type?: LogEntryType;
  event?: LogEntryEventType;
  service?: ExternalService;
  tenant: string
}

export const useLogs = (f?: ILogFilter) => {

  const {
    getUrlWithFilter
  } = useFilterQuery();

  const { data, isLoading, mutate } = useTiggiQuery<Array<ILogEntry>>(getUrlWithFilter(ApiRoutes.Logs, f), {
    enabled: !!f
  });

  return {
    logs: data,
    loadingLogs: isLoading,
    reloadLogs: mutate
  }
}