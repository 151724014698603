import React from "react";
import { useOfficeAccounts } from "../../../state/api/office/useOfficeAccounts";
import { IOfficeAccount } from "../../../types/officeAccount.types";
import SearchableComboBox, { ISearchableComboBoxBase } from "../../comboBox/SearchableComboBox";

export default function OfficeAccountSelect(props: ISearchableComboBoxBase<IOfficeAccount>) {

  const {
    loadingOfficeAccounts,
    officeAccounts
  } = useOfficeAccounts();

  return (
    <SearchableComboBox
      {...props}
      loading={loadingOfficeAccounts}
      values={officeAccounts ?? []}
      itemToId={s => s._id}
      itemToString={s => s.accountMail}
    />
  )
}