import React from "react";
import { IDomain } from "../../types/ApiTypes";
import Table from "../tables/Table";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import CopyableDnsEntry from "./CopyableDnsEntry";

export default function DomainDnsEntryTable({ domain }: { domain: IDomain }) {
  if (!domain?.dnsEntries?.length) return null;

  return (
    <Table
      border
      responsive
      headers={[{ label: "Typ" }, { label: "Name" }, { label: "Wert" }]}
      renderItem={dns => (
        <TableRow key={dns.value}>
          <TableCell content={dns.type} />
          <TableCell>
            <CopyableDnsEntry value={dns.name} />
          </TableCell>
          <TableCell>
            <CopyableDnsEntry value={dns.value} />
          </TableCell>
        </TableRow>
      )}
      items={domain.dnsEntries}
    />
  )
}