import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { usersRequestPasswordResetTokenByMail } from "../../api/Api";
import { IUserNewPasswordRequest, IUserResetPasswordWithMailRequest } from "../../api/ApiRequests";
import { AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import useApi from "../../hooks/useApi";
import useModal from "../../hooks/useModal";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import FieldWithLabel from "../forms/FormikField";

interface IUserNewPasswordValues extends IUserNewPasswordRequest {
  newPasswordRepeat: string
}

export default function RequestPasswordResetByMailForm() {

  const callApi = useApi();
  const showModal = useModal();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        mailAddress: "",
      } as IUserResetPasswordWithMailRequest}
      onSubmit={async (values, actions) => {
        const res = await callApi(usersRequestPasswordResetTokenByMail(values), true);

        if (!res) return;

        showModal({
          text: "Ein Link mit weiteren Informationen wurde erfolgreich an Ihre E-Mail versendet.",
          type: ModalType.Success
        });
        navigate(AppRoutes.Home.path);
      }}
    >
      {
        (formik) => {
          return (
            <Form className="w-100">
              <Flex fullWidth gap={3}>
                <FieldWithLabel
                  rounded
                  labelColor="bright"
                  className="w-100"
                  type="email"
                  name="mailAddress"
                  noHover
                  label="E-Mail-Adresse"
                  placeholder="Bitte geben Sie die Mailadresse Ihres Kontos ein..."
                />
                <Button color="bright" type="submit" text="Bestätigen" loading={formik.isSubmitting} />
              </Flex>
            </Form>
          )
        }
      }

    </Formik>
  )
}