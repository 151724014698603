import React from "react";
import Button from "../../buttons/Button";
import useApi from "../../../hooks/useApi";
import { sessionEndImposterSession } from "../../../api/Api";
import { useSession } from "../../../state/api/session/useSession";

export default function EndImpostersessionButton() {
  const { setSessionToken, reloadSession } = useSession();

  const callApi = useApi();

  const endImposterSession = async () => {
    const res = await callApi(sessionEndImposterSession());
    if (!res || !res.success) return;
    setSessionToken(res.data.token);
    await reloadSession();
  }

  return (
    <Button variant="outline" icon="slash" color="bright" text="Beenden" onClick={endImposterSession} />
  )
}