import React from "react";
import { ITicket } from "../../../../types/ApiTypes";
import { useTicketMessageMails } from "../../../../state/api/tickets/messages/useTicketMessageMails";
import { useTicketClientContact } from "../../../../state/api/tickets/assignments/useTicketClientContact";
import Flex from "../../../container/Flex";
import MailLogCard from "../../../log/mail/MailLogCard";
import { useUser } from "../../../../state/api/user/useUser";
import ModalDialog from "../../../modal/ModalDialog";
import Button from "../../../buttons/Button";
import LoadingSpinner from "../../../loader/LoadingSpinner";
import { ITicketMessage, TicketMessageSource } from "../../../../types/ticketMessage.schema";

export interface ITicketMessageInfoDialogProps {
  ticketMessage: ITicketMessage,
  ticket: ITicket,
  expander?: React.ReactElement
}

export default function TicketMessageInfoDialog({ ticket, expander, ticketMessage }: ITicketMessageInfoDialogProps) {

  const { user } = useUser();

  if (ticketMessage.source !== TicketMessageSource.Mail) return null;
  if (!user?.isSuperAdmin) return null;

  return (
    <ModalDialog
      button={expander ?? <Button variant="text" color="muted" icon="info-circle" />}
      title="Quellen"
    >
      {
        close => <TicketMessageInfoDialogContent ticket={ticket} ticketMessage={ticketMessage} />
      }
    </ModalDialog>

  )
}

function TicketMessageInfoDialogContent({ ticket, ticketMessage }: ITicketMessageInfoDialogProps) {

  const { ticketMessageMails, loadingTicketMessageMails } = useTicketMessageMails(ticketMessage._id);

  return (
    <Flex>
      {
        loadingTicketMessageMails
          ? <LoadingSpinner text="Lädt Mails..." />
          : (
            ticketMessageMails && !!ticketMessageMails.length
              ? ticketMessageMails.map(d => <MailLogCard key={d._id} item={d} />)
              : <span>Keine Mails für diese Nachricht</span>
          )
      }
    </Flex>
  )
}