import DOMPurify from "dompurify";
import { Form, Formik } from "formik";
import React from "react";
import { ticketMessagesUpdate } from "../../../../api/Api";
import { ITicketMessageUpdateRequest } from "../../../../api/ApiRequests";
import useApi from "../../../../hooks/useApi";
import { generateClassName } from "../../../../hooks/useAttributes";
import useTicketMessageUtil from "../../../../hooks/useTicketMessageUtil";
import { useTicketMessages } from "../../../../state/api/tickets/messages/useTicketMessages";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";
import Button from "../../../buttons/Button";
import Editor from "../../../richText/Editor";
import TicketChatHtmlMessageContent from "./TicketChatHtmlMessageContent";
import "./TicketMessageText.css";
import Flex from "../../../container/Flex";

export interface ITicketMessageTextProps {
  message: ITicketMessage,
  ticketId: string,
  text?: string,
  setEditMode?: (val: boolean) => void,
  editMode?: boolean,
  shorten?: boolean
}

export default function TicketMessageText(props: ITicketMessageTextProps) {
  const {
    message,
    text = "",
  } = props;

  const {
    getCurrentMessage
  } = useTicketMessageUtil();

  if (!message) return <span>Kein Text</span>;

  const realText = !!text ? text : getCurrentMessage(message);

  if (!realText) return <span>Kein Text</span>;

  return (
    <EditableTicketMessageText
      {...props}
      text={realText}
    />
  );
}

function EditableTicketMessageText(props: ITicketMessageTextProps) {

  const {
    message,
    ticketId,
    editMode,
    setEditMode,
    shorten,
    text
  } = props;

  const {
    reloadMessages
  } = useTicketMessages(ticketId);

  const callApi = useApi();
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const resizeInput = () => {
    if (!inputRef || !inputRef.current) return;
    inputRef.current.style.height = '';
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
  }

  React.useEffect(() => resizeInput(), [inputRef])

  React.useEffect(() => {
    if (!editMode) return;
    if (!inputRef.current) return;

    inputRef.current.focus();
    inputRef.current.selectionStart = inputRef.current.value.length;
  }, [editMode]);

  if (!text) return null;

  const inputClassName = generateClassName("editable-ticket-message-field", {
    value: shorten,
    onTrue: "editable-ticket-message-field-short text-truncate"
  });

  return (
    <Formik
      initialValues={{
        text: text,
        id: message._id
      } as ITicketMessageUpdateRequest}
      enableReinitialize
      onSubmit={async (values) => {

        const text = DOMPurify.sanitize(values.text).toString().trim();

        if (!text) return;

        const res = await callApi(ticketMessagesUpdate({
          id: values.id,
          text: text
        }));

        if (!res) return;

        await reloadMessages();
        setEditMode?.(false);
      }}
    >
      {
        formik => {
          return (
            <Form
              className="position-relative w-100 d-flex flex-column gap-2 align-items-start"
            >
              {
                editMode
                  ? (
                    <Editor
                      className={inputClassName}
                      onChange={val => formik.setFieldValue("text", val)}
                      disabled={formik.isSubmitting || !editMode}
                      content={formik.values.text}
                    />
                  )
                  : <TicketChatHtmlMessageContent message={formik.values.text} isPreview={!!shorten} />
              }
              {
                editMode && (
                  <Flex row fullWidth justify="end">
                    {
                      !formik.isSubmitting && (
                        <Button
                          icon="x"
                          size="small"
                          text="Abbrechen"
                          color="error"
                          onClick={() => {
                            formik.resetForm();
                            setEditMode?.(false);
                          }}
                        />
                      )
                    }
                    <Button icon="check" size="small" loading={formik.isSubmitting} color="success" type="submit" text="Speichern" />
                  </Flex>
                )
              }
            </Form>
          )
        }
      }
    </Formik>
  )
}