import React from "react";
import { useTeams } from "../../state/api/teams/useTeams";
import { ITeam } from "../../types/team.schema";
import SearchableComboBox, { ISearchableComboBoxBase } from "../comboBox/SearchableComboBox";

export interface ITeamSelectProps extends ISearchableComboBoxBase<ITeam> {
  getAvailableValues?: (teams?: ITeam[]) => ITeam[];
}

export default function TeamSelect(props: ITeamSelectProps) {

  const {
    getAvailableValues
  } = props;

  const { teams, loadingTeams } = useTeams();
  const t = getAvailableValues?.(teams) ?? teams;

  return (
    <SearchableComboBox
      loading={loadingTeams}
      {...props}
      values={t ?? []}
      itemToId={(t) => t._id}
      itemToString={(t) => t.name}
    />
  )
}