import React from "react";
import Flex from "../../container/Flex";
import { useTicketEscalationWorkflow } from "../../../state/api/escalation/useTicketEscalationWorkflows";
import LoadingSpinner from "../../loader/LoadingSpinner";
import TicketEscalationWorkflowForm from "./TicketEscalationWorkflowForm";
import TicketEscalationWorkflowItem from "./TicketEscalationWorkflowItem";
import WithPermissions from "../../permissions/WithPermissions";
import Typography from "../../text/Typography";

export default function EscalationWorkflowForms() {

  const { loadingTicketEscalationWorkflow, ticketEscalationWorkflow } = useTicketEscalationWorkflow();

  if (loadingTicketEscalationWorkflow) return <LoadingSpinner />

  return (
    <WithPermissions permissions={["tenant.own.update"]}>
      <Flex row>
        <Flex>
        </Flex>
      </Flex>
      <Flex className="w-100">
        <Flex row justify="between" className="w-100">
          <Flex gap="0" fullWidth>
            <Typography color="primary" size="20">Eskalations-Workflows</Typography>
            <Typography size="12">Eskalationszeiträume laufen nur während der Öffnungszeiten. Bitte berücksichtigen Sie dies in Ihrer Zeitberechnung</Typography>
          </Flex>
          <TicketEscalationWorkflowForm lastItem={ticketEscalationWorkflow && ticketEscalationWorkflow.length ? ticketEscalationWorkflow[ticketEscalationWorkflow.length - 1] : undefined} />
        </Flex>
        {
          loadingTicketEscalationWorkflow
            ? <LoadingSpinner />
            : (
              <Flex className="w-100">
                {
                  ticketEscalationWorkflow && ticketEscalationWorkflow.length
                    ? ticketEscalationWorkflow.map((workflow, index) => <TicketEscalationWorkflowItem key={workflow._id} workflow={workflow} lastItem={index > 0 ? ticketEscalationWorkflow[index - 1] : undefined} />)
                    : <p className="text-muted">Keine Workflows vorhanden</p>
                }
              </Flex>
            )
        }
      </Flex>
    </WithPermissions>
  )
}