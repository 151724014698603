import React from "react";
import Button from "../../components/buttons/Button";
import Expandable from "../../components/comboBox/Expandable";
import SearchableComboBox from "../../components/comboBox/SearchableComboBox";
import Flex from "../../components/container/Flex";
import Page from "../../components/container/Page";
import DateTimePicker from "../../components/forms/datetime/DateTimePicker";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import DeleteLogsManuallyButton from "../../components/log/api/DeleteLogsManuallyButton";
import LogEntryEventTypeBanner from "../../components/log/api/LogEntryEventTypeBanner";
import LogEntryForm from "../../components/log/api/LogEntryForm";
import LogEntryTypeBanner from "../../components/log/api/LogEntryTypeBanner";
import ExternalServiceBanner from "../../components/services/ExternalServiceBanner";
import Table from "../../components/tables/Table";
import TableCell from "../../components/tables/TableCell";
import TableRow from "../../components/tables/TableRow";
import { ITabData } from "../../components/tabswitcher/TabSwitcher";
import Typography from "../../components/text/Typography";
import useLogUtil from "../../hooks/useLogUtil";
import useQueryBasedFilter from "../../hooks/useQueryBasedFilter";
import { ILogFilter, useLogs } from "../../state/api/log/useLogs";
import { useTenant } from "../../state/api/tenant/useTenant";
import { ExternalService, LogEntryEventType, LogEntryType } from "../../types/log.schema";
import { getDateInXFrom, getDateInXFromNow } from "../../util/util";
import OfficeAccountSelect from "../../components/mail/account/OfficeAccountSelect";
import TenantSelect from "../../components/tenant/TenantSelect";
import UserSelect from "../../components/user/UserSelect";
import Card from "../../components/card/Card";

export enum LogViewerTenant {
  All = "all",
  Current = "current"
}

export default function LogViewer() {

  const { tenant } = useTenant();

  const { filter, updateFilter } = useQueryBasedFilter<ILogFilter>({
    createdAtStart: getDateInXFromNow({ minutes: -5 }),
    createdAtEnd: getDateInXFromNow({ days: 1, hour: 0, minute: 0 }),
    limit: 100,
    service: undefined,
    type: LogEntryType.Error,
    tenant: tenant._id,
    skip: 0,
    event: undefined,
    client: undefined,
    mailAccount: undefined,
    officeAccount: undefined,
    ticket: undefined,
    ticketMessage: undefined,
    user: undefined
  });

  const { loadingLogs, logs } = useLogs(filter);

  const eventTabs: Array<ITabData> = [
    {
      data: LogEntryEventType.GCloud,
      label: "Google Cloud"
    }, {
      data: LogEntryEventType.MsOfficeEvent,
      label: "MS Office"
    }, {
      data: LogEntryEventType.MsOfficeLifecycle,
      label: "MS Office Lifecycle"
    }, {
      data: LogEntryEventType.SendGrid,
      label: "SendGrid"
    }, {
      data: LogEntryEventType.Stripe,
      label: "Stripe"
    }, {
      data: LogEntryEventType.TicketEscalation,
      label: "Tiggi: Escalation"
    }
  ]

  const typeTabs: Array<ITabData> = [{
    data: LogEntryType.Error,
    label: "Fehler"
  }, {
    data: LogEntryType.Event,
    label: "Events"
  }, {
    data: LogEntryType.General,
    label: "Informationen"
  }, {
    data: LogEntryType.Schedule,
    label: "Zeitplan"
  }];

  return (
    <Page header="Logbuch" showBackButton actionButtons={<DeleteLogsManuallyButton />}>
      <Card className="w-100" header="Filter" >
        <Flex row fullWidth>
          <Flex>
            <Typography bold color="primary">Typ</Typography>
            <SearchableComboBox
              values={typeTabs}
              bold
              itemToId={t => t.data}
              itemToString={t => t.label ?? ""}
              value={filter.type}
              onChange={t => updateFilter({ type: t?.data as LogEntryType })}
            />
          </Flex>
          <Flex>
            <Typography bold color="primary">Event-Typ</Typography>
            <SearchableComboBox
              values={eventTabs}
              bold
              itemToId={t => t.data}
              itemToString={t => t.label ?? ""}
              value={filter.event}
              onChange={t => updateFilter({ event: t?.data as LogEntryEventType })}
            />
          </Flex>
          <Flex>
            <Typography color="primary" bold>Zeitraum</Typography>
            <Flex row>
              <DateTimePicker date={filter.createdAtStart} onChange={d => updateFilter({ createdAtStart: d })} />
              <Typography>-</Typography>
              <DateTimePicker max={getDateInXFromNow({ hours: 1 })} date={filter.createdAtEnd} onChange={d => updateFilter({ createdAtEnd: d })} />
            </Flex>
          </Flex>
        </Flex>
        <Typography bold color="primary">Betroffene Ressourcen</Typography>
        <Flex row>
          <OfficeAccountSelect label="Office-Konto" onChange={a => updateFilter({ officeAccount: a?._id })} value={filter.officeAccount} />
          <TenantSelect onChange={t => updateFilter({ tenant: t?._id })} value={filter.tenant} />
          <UserSelect label="Nutzer" onChange={u => updateFilter({ user: u?._id })} value={filter.user} useDefaultValue={false} />
        </Flex>
      </Card>
      {
        loadingLogs
          ? <LoadingSpinner />
          : (
            <Table
              responsive
              headers={[{ label: "Datum" }, { label: "Inhalt / Nachricht", valueKey: "content" }, { label: "Metadaten" }, { label: "" }]}
              items={logs ?? []}
              renderItem={l => (
                <TableRow key={l._id}>
                  <TableCell date={l.createdAt} showTime />
                  <TableCell>
                    <Flex className="w-100" style={{ minWidth: "35vw" }}>
                      <textarea readOnly className="w-100 text-wrap" style={{ border: "none", background: "none" }} value={l.content} />
                    </Flex>
                  </TableCell>
                  <TableCell>
                    <Flex gap="2">
                      <LogEntryTypeBanner log={l} />
                      <LogEntryEventTypeBanner log={l} />
                      <ExternalServiceBanner service={l.service} />
                    </Flex>
                  </TableCell>
                  <TableCell>
                    <LogEntryForm log={l} />
                  </TableCell>
                </TableRow>
              )}
            />
          )
      }
    </Page >
  )
}