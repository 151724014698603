import React from "react";
import usePassword from "../../../hooks/usePassword";
import Flex from "../../container/Flex";
import CheckmarkIcon from "../../icons/CheckmarkIcon";

export interface IPasswordValidationResultProps {
  password: string
}

export default function PasswordValidationResult({ password }: IPasswordValidationResultProps) {
  const { getValidationResult } = usePassword();

  if (!password) return null;

  const validation = getValidationResult(password);

  return (
    <Flex className="w-100">
      <CheckmarkIcon
        value={validation.hasMinLength}
        falseLabel="Passwort mindestens 8 Zeichen"
        trueLabel="Passwortlänge passt"
      />
      <CheckmarkIcon
        value={validation.hasLowerCase}
        falseLabel="Passwort muss Kleinbuchstaben enthalten"
        trueLabel="Passwort enthält Kleinbuchstaben"
      />
      <CheckmarkIcon
        value={validation.hasUpperCase}
        falseLabel="Passwort muss Großbuchstaben enthalten"
        trueLabel="Passwort enthält Großbuchstaben"
      />
      <CheckmarkIcon
        value={validation.hasNumber}
        falseLabel="Passwort muss eine Zahl enthalten"
        trueLabel="Passwort enthält eine Zahl"
      />
      <CheckmarkIcon
        value={validation.hasSpecialCharacter}
        falseLabel="Passwort muss ein Sonderzeichen enthalten"
        trueLabel="Passwort enthält ein Sonderzeichen"
      />
    </Flex>
  )
}