import React from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import "./ModalBanner.css";
import ModalNotification from "./ModalNotification";

export default function ModalNotifications() {

  const {
    notifications
  } = useAppSelector(state => state.notifications);

  if (!notifications?.length) return null;

  return (
    <div
      className="position-fixed d-flex flex-column gap-3"
      style={{
        zIndex: 9999999,
        width: "min(400px, 80vw)",
        bottom: "15px",
        right: "15px"
      }}
    >
      {
        notifications?.map((n, i) => <ModalNotification notification={n} key={n.id ?? `notification-${i}`} />)
      }
    </div>
  )
}