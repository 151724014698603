import React from "react";
import { ticketAssignmentsUpdate } from "../../../api/Api";
import { ITicketAssignmentAddRequest } from "../../../api/ApiRequests";
import { AppColor } from "../../../app/AppStyles";
import useApi from "../../../hooks/useApi";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import { useTicketAssignee } from "../../../state/api/tickets/assignments/useTicketAssignee";
import { useTicketAssignments } from "../../../state/api/tickets/assignments/useTicketAssignments";
import { useTicketClientContact } from "../../../state/api/tickets/assignments/useTicketClientContact";
import { ITicket, ITicketAssignment } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import WithPermissions from "../../permissions/WithPermissions";

export interface IUpdateTicketAssignmentButtonProps extends Omit<Omit<ITicketAssignmentAddRequest, "ticket">, "isCc"> {
  color: AppColor,
  ticket: ITicket,
  assignment: ITicketAssignment,
  text: string,
  icon: string
}

export default function UpdateTicketAssignmentButton({ color, icon, text, client, assignment, isMainAssignee, isMainClientContact, ticket, user }: IUpdateTicketAssignmentButtonProps) {
  const permissions = useTicketPermission("update", ticket);
  const { reloadTicketAssignee } = useTicketAssignee(ticket);
  const { reloadTicketClientContact } = useTicketClientContact(ticket);
  const { reloadTicketAssignments } = useTicketAssignments(ticket);

  const callApi = useApi();

  const doAction = async () => {
    const res = await callApi(ticketAssignmentsUpdate({
      ...assignment,
      client,
      user,
      isCc: !isMainAssignee && !isMainClientContact,
      isMainAssignee,
      isMainClientContact
    }));

    if (!res || !res.success) return;

    await reloadTicketAssignee();
    await reloadTicketAssignments();
    await reloadTicketClientContact();
  }

  if (!assignment.isActive) return null;

  return (
    <WithPermissions permissions={permissions}>
      <Button variant="text" text={text} color={color} icon={icon} onClick={doAction} />
    </WithPermissions>
  )
}