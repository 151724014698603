import React from "react";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import Card from "../card/Card";
import LoadingSpinner from "../loader/LoadingSpinner";
import { AppColor } from "../../app/AppStyles";
import { IUserStatistics, IUserStatisticsCounts, IUserStatisticsDocument } from "../../types/ApiTypes";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../config/AppRoutes";
import { TicketSubcategory } from "../../hooks/useFilteredTickets";
import Icon from "../icons/Icon";
import ResetStatisticsButton from "./ResetStatisticsButton";
import { TicketProductivityTab } from "./ProductivityOverview";
import { useUser } from "../../state/api/user/useUser";
import { getId, getModel } from "../../util/mongoUtil";
import TicketStatisticPie from "./TicketStatisticPie";
import { ITeam } from "../../types/team.schema";
import { useTeams } from "../../state/api/teams/useTeams";
import useUserUtil from "../../hooks/useUserUtil";
import { generatePleasantBlueHex, getColorOrShade } from "../../util/util";

export interface ITicketStatsCardGridProps {
  cardColor?: AppColor,
  statistics: IUserStatisticsDocument | undefined,
  allStatistics?: any[],
  hasTeam?: boolean,
  isLoading: boolean,
  isPersonal?: boolean,
  resetTarget: string,
  currentTab: TicketProductivityTab
}

export default function TicketStatsCardGrid({ statistics, hasTeam, isPersonal, currentTab, resetTarget, allStatistics, isLoading, cardColor = "muted" }: ITicketStatsCardGridProps) {

  const {
    user
  } = useUser();

  const {
    teamsById
  } = useTeams();

  const {
    getName
  } = useUserUtil();

  const navigate = useNavigate();

  const getStatsPie = (dataKey: keyof IUserStatisticsCounts) => {
    if (!allStatistics) return null;

    const usableStats = [];
    let index = 0;

    for (const s of allStatistics) {
      const team = getModel<ITeam>(s.team, teamsById);
      const name = hasTeam ? team?.name : getName(s.user);

      if (!name) continue;

      usableStats.push({
        name,
        value: hasTeam ? s.statistics.tickets.counts[dataKey] : s.tickets.counts[dataKey],
        fill: hasTeam ? getColorOrShade(team!.color) : generatePleasantBlueHex(index)
      });

      index++;
    }

    return <TicketStatisticPie statistics={usableStats} />
  }

  const getLink = (category: TicketSubcategory) => {
    switch (currentTab) {
      case TicketProductivityTab.Personal:

        if (getId(user) === resetTarget) return `${AppRoutes.Tickets.path}?show=mine&view=grid&subcategory=${category}`;
        return `${AppRoutes.Tickets.path}?show=all&view=grid&subcategory=${category}&assignee=${resetTarget}`;

      default: return "";
    }
  }
  const getStatsCard = (title: string, key: keyof IUserStatisticsCounts, category: TicketSubcategory) => {

    const value = statistics?.tickets?.counts?.[key] ?? 0;

    return (
      <Card
        wrapperClass="w-100"
        color={cardColor}
        header={
          <Flex row justify="between" className="w-100">
            <Typography basedOnThisBackground={cardColor} bold>{title}</Typography>
            {
              isPersonal && category && <Icon invertThisColor={cardColor} icon="box-arrow-up-right" />
            }
          </Flex>
        }
        onClick={isPersonal && category ? () => navigate(getLink(category)) : undefined}
      >
        <Flex row fullHeight fullWidth justify="between">
          <Flex fullHeight row align="end">
            {
              isLoading
                ? <LoadingSpinner size={50} />
                : <Typography size={50} lineHeight={45} basedOnThisBackground={cardColor}>{value}</Typography>
            }
            <Typography size={20} basedOnThisBackground={cardColor} >{value === 1 ? "Ticket" : "Tickets"}</Typography>
          </Flex>
          {
            getStatsPie(key)
          }
        </Flex>
      </Card>
    )
  }

  return (
    <div className="w-100 productivity-grid">
      {getStatsCard("Unbeantwortete Tickets", "unansweredTickets", TicketSubcategory.Unanswered)}
      {getStatsCard("Offene Tickets", "openTicketsCurrently", TicketSubcategory.All)}
      {getStatsCard("Geschlossene Tickets letzte 7 Tage", "closedTicketsPastWeek", TicketSubcategory.ClosedPastWeek)}
      {getStatsCard("Tickets in Eskalation", "ticketsInEscalation", TicketSubcategory.Escalated)}
      {getStatsCard("Tickets in Wiedervorlage", "ticketsInResubmission", TicketSubcategory.Resubmission)}
    </div>
  )
}