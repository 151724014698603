import React from "react";
import { ticketsAssign } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import useRoleUtil from "../../../hooks/useRoleUtil";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import useTicketUtil from "../../../hooks/useTicketUtil";
import useUserUtil from "../../../hooks/useUserUtil";
import { useTeams } from "../../../state/api/teams/useTeams";
import { useTicketAssignee } from "../../../state/api/tickets/assignments/useTicketAssignee";
import { useCurrentTenantRole } from "../../../state/api/user/useCurrentTenantRole";
import { useUser } from "../../../state/api/user/useUser";
import { ITicket, IUser, UserRole } from "../../../types/ApiTypes";
import { getId } from "../../../util/mongoUtil";
import Button from "../../buttons/Button";
import Expandable from "../../comboBox/Expandable";
import WithPermissions from "../../permissions/WithPermissions";
import AssignmentUserItem from "./AssignmentUserItem";
import "./AssignTicketToTeamMember.css";

interface IAssignTicketToTeamMemberProps {
  afterAssigningTicket?: () => void,
  ticket: ITicket
}

export default function AssignTicketToTeamMember({ ticket, afterAssigningTicket }: IAssignTicketToTeamMemberProps) {

  const permissions = useTicketPermission("update", ticket);
  const { loadingTicketAssignee, ticketAssignee } = useTicketAssignee(ticket);

  const {
    teamsById
  } = useTeams();

  const { role } = useCurrentTenantRole();
  const { user } = useUser();

  const {
    getUsersForRole,
    getCurrentTenantAuthority
  } = useUserUtil();

  const {
    convertToUserRole
  } = useRoleUtil();

  const {
    isClosed
  } = useTicketUtil();

  const ticketIsClosed = isClosed(ticket);

  const callApi = useApi();

  const authority = getCurrentTenantAuthority(user);

  const assignToOtherMember = async (user: IUser) => {
    const res = await callApi(ticketsAssign({ ticketId: ticket._id, userId: user._id }));
    if (!res) return;
    if (afterAssigningTicket) afterAssigningTicket();
  }

  const getAvailableRoles = (): UserRole[] => {

    if ((authority && authority.userAuthority && authority.userAuthority.isTenantAdmin)
      || (user && user.isSuperAdmin)) return [
        UserRole.Clerk,
        UserRole.Client,
        UserRole.Partner,
        UserRole.Secretary,
        UserRole.TeamLead
      ];

    const assignedUserRole = role ? convertToUserRole(role) : UserRole.Clerk;

    switch (assignedUserRole) {
      case UserRole.TeamLead: return [UserRole.Clerk, UserRole.TeamLead];
      case UserRole.Partner: return [UserRole.Clerk, UserRole.TeamLead, UserRole.Partner];
      default: return [UserRole.Clerk];
    }
  }

  const availableRoles = getAvailableRoles();

  const usedTeam = teamsById[getId(ticket.team)];

  const users = getUsersForRole(usedTeam?.members, availableRoles, ticketAssignee && ticketAssignee.user ? [ticketAssignee.user] : []);
  const hasUsers = users && !!users.length;

  const buttonText = ticketIsClosed ? "Ticket ist geschlossen" : (hasUsers ? "An Teammitglied übergeben" : "Keine Teammitglieder verfügbar");

  return (
    <WithPermissions permissions={permissions}>
      <Expandable
        disabled={!hasUsers}
        expander={
          <Button variant="outline" icon="chevron-right" iconPosition="end">{buttonText}</Button>
        }
      >
        {
          hasUsers && users.map(u => <AssignmentUserItem key={u._id} onClick={async () => await assignToOtherMember(u)} user={u} />)
        }
      </Expandable>
    </WithPermissions>
  )
}