import React from "react";
import { generateStyle } from "../../../hooks/useAttributes";
import useUserUtil from "../../../hooks/useUserUtil";
import { useAnyUserAvatar } from "../../../state/api/user/useAnyUserAvatar";
import { DbId, IUser } from "../../../types/ApiTypes";
import { getId } from "../../../util/mongoUtil";
import { getTextColorFromBackground } from "../../../util/util";
import LoadingSpinner from "../../loader/LoadingSpinner";
import { useLetterColors } from "../../../hooks/useLetterColors";

export interface IUserAvatarProps {
  user?: DbId<IUser>,
  mailAddress: string,
  size?: number
}

export default function UserAvatar({ mailAddress, user, size = 48 }: IUserAvatarProps) {

  const { loadingUserAvatar, reloadUserAvatar, userAvatar } = useAnyUserAvatar(getId(user));

  const {
    getInitials
  } = useUserUtil();

  const {
    getLetterColor
  } = useLetterColors();

  const initials = getInitials(user, mailAddress);

  const getColorsFromName = (): { firstColor: string, lastColor: string } => {
    return {
      firstColor: getLetterColor(initials.firstInitial),
      lastColor: getLetterColor(initials.lastInitial)
    }
  }

  const colorForUser = getColorsFromName();

  const generateGradientForAvatar = () => {
    const startOfGradient = (initials.firstInitial.charCodeAt(0) + 0) / 26;
    return `linear-gradient(80deg, ${colorForUser.firstColor} ${startOfGradient}%, ${colorForUser.lastColor} 100%)`
  }

  const gradient = generateGradientForAvatar();

  if (!gradient) return null;

  const style = generateStyle({
    name: "width",
    value: size,
    unit: "px"
  }, {
    name: "height",
    value: size,
    unit: "px"
  }, {
    name: "minWidth",
    value: size,
    unit: "px"
  }, {
    name: "minHeight",
    value: size,
    unit: "px"
  }, {
    name: "background",
    value: gradient
  }, {
    name: "borderRadius",
    value: size * 10,
    unit: "px"
  }, {
    name: "color",
    value: getTextColorFromBackground(colorForUser.firstColor, "black")
  });

  const letterStyle = generateStyle({
    name: "fontSize",
    value: size / 2,
    unit: "px"
  });

  return (
    <div className="d-flex flex-row align-items-center justify-content-center overflow-hidden" style={style}>
      {
        loadingUserAvatar
          ? <LoadingSpinner size={size} />
          : userAvatar && <img className="w-100 h-100" src={userAvatar.small} />
      }
      {
        (loadingUserAvatar || !userAvatar) && <strong className="position-absolute" style={letterStyle}>{initials.firstInitial}{initials.lastInitial}</strong>
      }
    </div>
  )
}
