import React from "react";
import { useTenant } from "../../state/api/tenant/useTenant";
import { generateClassName } from "../../hooks/useAttributes";

export interface ILogoProps {
  responsive?: boolean
}

export default function Logo(props: ILogoProps) {

  const {
    responsive
  } = props;

  const { tenant } = useTenant();

  return (
    <div className="text-center d-flex flex-column" >
      <h1 className={generateClassName("fw-bold m-0 p-0 ", {
        value: responsive,
        onTrue: "fs-4 display-md-5",
        standard: "display-5"
      })}>
        {
          tenant?.name || "Ticketsystem"
        }
      </h1>
    </div>
  )
}