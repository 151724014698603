import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import FeedbackButton from "../feedback/FeedbackButton";
import "./TopNav.css";
import Icon from "../icons/Icon";
import { setSidebarExpanded } from "../../state/slices/sidebarSlice";
import { generateClassName } from "../../hooks/useAttributes";
import UserMenu from "../user/UserMenu";
import NotificationDrawer from "../notifications/NotificationsDrawer";
import Flex from "../container/Flex";
import { useSettings } from "../../state/api/settings/useSettings";
import CreateImposterSessionButton from "../user/session/CreateImposterSessionButton";
import SessionTenantSelect from "../user/session/SessionTenantSelect";

export default function TopNav() {

  const { settings } = useSettings();

  const dispatch = useAppDispatch();

  const {
    isExpanded
  } = useAppSelector(state => state.sidebar);

  const sideNavToggleClass = generateClassName("sidenav-expander-icon");

  return (
    <nav className="top-nav gap-3 d-flex flex-row align-items-center justify-content-between">
      {
        isExpanded
          ? <div />
          : <Icon onClick={() => dispatch(setSidebarExpanded(true))} className={sideNavToggleClass} size={30} icon="list" />
      }
      <Flex row gap={4}>
        <SessionTenantSelect />
        <CreateImposterSessionButton />
        <Flex row gap={2}>
          <FeedbackButton />
          {
            settings && settings.links && settings.links.faq && (
              <Icon
                size={38}
                hexColor="#B2C0D9"
                tooltip="FAQ"
                icon="question"
                onClick={() => window.open(settings.links.faq, "_blank")}
              />
            )
          }
          <NotificationDrawer />
        </Flex>
        <UserMenu />
      </Flex>
    </nav>
  )
}