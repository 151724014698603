import React from "react";
import { AbsenceSource, IUser, IUserAbsence } from "../../../types/ApiTypes";
import TableRow from "../../tables/TableRow";
import UserAbsenceDeleteButton from "./UserAbsenceDeleteButton";
import TableCell from "../../tables/TableCell";
import { formatDate, formatDateTiggiStyle } from "../../../util/formatter";
import useUserUtil from "../../../hooks/useUserUtil";
import AbsenceTypeBanner from "./AbsenceTypeBanner";
import AbsenceSourceBanner from "./AbsenceSourceBanner";

export interface IUserAbsenceListItemProps {
  absence: IUserAbsence,
  user: IUser
}

export default function UserAbsenceListItem({ absence, user }: IUserAbsenceListItemProps) {

  const {
    getName
  } = useUserUtil();

  return (
    <TableRow>
      <TableCell content={formatDateTiggiStyle(absence.from, true)} />
      <TableCell content={formatDateTiggiStyle(absence.to, true)} />
      <TableCell content={absence.deputy ? getName(absence.deputy) : "-"} />
      <TableCell content={absence.createdBy ? getName(absence.createdBy) : "-"} />
      <TableCell>
        <AbsenceTypeBanner type={absence.type} />
      </TableCell>
      <TableCell>
        <AbsenceSourceBanner source={absence.source} />
      </TableCell>
      <TableCell justify="end">
        <UserAbsenceDeleteButton absence={absence} user={user} />
      </TableCell>
    </TableRow>
  )
}