import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";
import React from "react";
import { tenantsOnboardingSetHasPaymentDetails } from "../../../../api/Api";
import { ModalType } from "../../../../config/ModalTypes";
import useApi from "../../../../hooks/useApi";
import useModal from "../../../../hooks/useModal";
import Button from "../../../buttons/Button";
import PageLoader from "../../../loader/PageLoader";
import Typography from "../../../text/Typography";
import Flex from "../../../container/Flex";

export interface IOnboardingPaymentDetailsProps {
  tenantId: string,
  registrationToken: string,
  clientSecret: string,
  nextPage: () => void
}

export default function OnboardingPaymentDetails({ tenantId, registrationToken, clientSecret, nextPage }: IOnboardingPaymentDetailsProps) {

  const [err, setErr] = React.useState<string | null>(null);

  const stripe = useStripe();
  const elements = useElements();

  const callApi = useApi();
  const showModal = useModal();

  const getErrorFromElements = async (): Promise<string | null> => {
    try {
      const result = await elements?.fetchUpdates();
      if (!result) return null;

      const {
        error
      } = result;

      if (!error) return null;

      console.log("ELEMENT ERROR: ", error);

      return error.message;
    }
    catch { }

    return null;
  }

  const fetchElementError = async () => {
    const elementError = await getErrorFromElements();
    setErr(elementError);
  }

  React.useEffect(() => {
    fetchElementError();
  }, [elements]);

  return (
    <Formik
      initialValues={{}}
      onSubmit={async () => {
        if (err || !stripe || !elements) {
          console.log("going to next page");
          nextPage();
          return;
        }

        try {
          const { error: submitError } = await elements.submit();

          if (!!submitError) {
            console.log(submitError);

            showModal({
              text: "Bei der Validierung Ihrer Zahlungsangaben ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben.",
              type: ModalType.Error
            });
            return;
          }

          const {
            error: paymentError
          } = await stripe.confirmPayment({ elements, clientSecret, confirmParams: { return_url: window.location.href } });

          if (!!paymentError) {
            console.log(paymentError);

            showModal({
              text: "Bei der Validierung Ihrer Zahlungsangaben ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben.",
              type: ModalType.Error
            });
            return;
          }

          await callApi(tenantsOnboardingSetHasPaymentDetails({ tenantId: tenantId, registrationToken: registrationToken }));
        }
        catch {
          showModal({
            text: "Es ist ein Fehler bei der Zahlung aufgetreten. Sie können fortfahren und Ihre Zahlungsinformationen später hinzufügen.",
            type: ModalType.Error
          });

          await callApi(tenantsOnboardingSetHasPaymentDetails({ tenantId, registrationToken: registrationToken }));
        }

        nextPage();
      }}
    >
      {
        (formik) => (
          <Form className="w-100 h-100 d-flex flex-column justify-content-start gap-3">
            {
              err
                ? (
                  <Flex fullWidth gap="3">
                    <Flex gap="0">
                      <Typography wrap bold color="error" size="24">Es ist ein Fehler aufgetreten.</Typography>
                      <Typography wrap bold size="18">Sie können fortfahren und Ihre Zahlungsdetails später hinterlegen.</Typography>
                    </Flex>
                    <Flex gap="1">
                      <Typography wrap size="12">Details des Fehlers:</Typography>
                      <Typography wrap size="12">{err}</Typography>
                    </Flex>
                  </Flex>
                )
                : (
                  <>
                    <h6 className="fw-bold">Bitte geben Sie Ihre Zahlungsdetails ein.</h6>
                    <p>Wir stellen Ihnen eine 30-tägige Testperiode zur Verfügung. Sie können Ihr Abo jederzeit beenden und Ihr Zahlungsmittel wird erst mit Ablauf der Testperiode belastet.</p>
                  </>
                )
            }
            {
              stripe
                ? <PaymentElement id="payment-element" />
                : <PageLoader />
            }
            <Button
              type="submit"
              text="Weiter"
              disabled={!stripe && !err}
              loading={formik.isSubmitting}
            />
          </Form>
        )
      }
    </Formik>
  )
}