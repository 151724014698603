import React from "react";
import useUserUtil from "../../hooks/useUserUtil";
import { useAddressees } from "../../state/api/addressees/useAddressees";
import { useClientContacts } from "../../state/api/clientContacts/useClientContacts";
import { useUsers } from "../../state/api/user/useUsers";
import { DatevAddresseeType, IAddressee, IUser } from "../../types/ApiTypes";
import { getId } from "../../util/mongoUtil";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import Table from "../tables/Table";
import ClientContactListItem from "./ClientContactListItem";
import TabSwitcher from "../tabswitcher/TabSwitcher";

export interface ISortedClientContact {
  user?: IUser,
  addressees: IAddressee[]
}

export default function ClientContactList() {

  const [showAssigned, setShowAssigned] = React.useState<boolean>(true);

  const { clientContacts, loadingClientContacts } = useClientContacts();
  const { addressees, loadingAddressees } = useAddressees();

  const {
    usersById
  } = useUsers();

  const getSortedClientContacts = (): { all: ISortedClientContact[], unassigned: ISortedClientContact[] } => {
    const r: { all: ISortedClientContact[], unassigned: ISortedClientContact[] } = { all: [], unassigned: [] };

    if (loadingClientContacts || loadingAddressees) return r;

    if (!addressees?.length) {

      if (!clientContacts?.length) return r;

      r.all = clientContacts.map((e: IUser): ISortedClientContact => ({
        addressees: [],
        user: e
      }));

      return r;
    }

    const noUserItem: ISortedClientContact = { addressees: [] }
    const items: { [key: string]: ISortedClientContact } = {};

    for (var addressee of addressees) {
      if (!addressee) continue;
      if (addressee.type === DatevAddresseeType.LegalPerson) continue;

      const userId = getId(addressee.user);

      if (!userId) {
        noUserItem.addressees.push(addressee);
        continue;
      }

      const u = usersById[userId];

      if (!u) continue;

      if (!items[userId]) items[userId] = {
        addressees: [],
        user: u
      };

      items[userId].addressees.push(addressee);
    }

    const result = Object.values(items);

    result.sort((a, b) => {
      if (!a.user && !b.user) return 0;
      if (!a.user) return -1;
      if (!b.user) return 1;
      if (!a.user.firstName) return -1;
      if (!b.user.firstName) return 1;

      return a.user.firstName.localeCompare(b.user.firstName);
    })

    r.all = result;
    r.unassigned = [noUserItem];

    return r;
  }

  const {
    getName
  } = useUserUtil();

  const contacts = getSortedClientContacts();

  return (
    <WithPermissions permissions={["users.clientContacts.read"]}>
      <TabSwitcher
        tabs={[{ data: "assigned", label: "Alle" }, { data: "unassigned", label: "Ohne Nutzer" }]}
        initialData="assigned"
        saveActiveTab={d => {
          setShowAssigned(d === "assigned");
        }}
      />
      {
        loadingAddressees || loadingClientContacts
          ? <LoadingSpinner centered={false} text="Mandantenkontakte werden geladen..." />
          : (
            <Table
              responsive
              border
              className="w-100"
              headers={[
                {
                  label: "Name",
                  filterItem: (c, filter) => {
                    if (!c.user) return false;
                    const name = getName(c.user);

                    if (name && name.toLowerCase().includes(filter.toLowerCase())) return true;
                    if (c.user.mailAddress && c.user.mailAddress.toLowerCase().includes(filter.toLowerCase())) return true;

                    return false;
                  }
                }, {
                  label: "Addressaten",
                  filterItem: (c, filter) => {
                    if (!c.addressees || !c.addressees.length) return false;
                    const values = c.addressees.map(a => a.actualName);
                    return values.some(v => v.toLowerCase().includes(filter.toLowerCase()));
                  }
                }, {
                  label: "Mandanten"
                }, {
                  label: "Alias"
                },
                { label: "" }
              ]}
              items={showAssigned ? contacts.all : contacts.unassigned}
              renderItem={(s, i) => <ClientContactListItem key={`${s.user ? s.user._id : "no-user-addressees"}-${i}`} clientContact={s} />}
            />
          )
      }
    </WithPermissions>
  )
}