import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";
import useTiggiQuery, { TiggiQueryOpts } from "../../../../hooks/useTiggiQuery";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";

export const useTicketMessages = (id: string, opts?: TiggiQueryOpts) => {

  const { data, isLoading, mutate } = useTiggiQuery<Array<ITicketMessage>>(
    ApiRoutes.Tickets.Messages.getValue({ param: RouteParam.TicketId, value: id }),
    {
      enabled: !!id,
      ...opts,
    }
  );

  return {
    messages: data,
    loadingMessages: isLoading,
    reloadMessages: mutate
  }
}