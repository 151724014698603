import React, { ReactElement } from "react";
import { IModalFormBase } from "./ModalForm";
import { IModalBase } from "./ModalDialog";

interface IFastModalDialogProps {
  button: ReactElement,
  dialog: ReactElement<IModalBase>,
  disabled?: boolean
}

export default function FastModalDialog(props: IFastModalDialogProps) {

  const {
    button,
    dialog,
    disabled
  } = props;

  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <>
      {
        button && React.cloneElement(button, { disabled, onClick: () => { if (!disabled) setVisible(true) } })
      }
      {
        visible && React.cloneElement<IModalBase>(dialog, { visible, setVisible, isFast: true })
      }
    </>
  )
}