import React from "react";
import useQueryBasedFilter from "../../../hooks/useQueryBasedFilter";
import { IMailLogFilter, useMailLog } from "../../../state/api/log/useMailLogs";
import { getDateInXFromNow } from "../../../util/util";
import Card from "../../card/Card";
import Flex from "../../container/Flex";
import DateTimePicker from "../../forms/datetime/DateTimePicker";
import LoadingSpinner from "../../loader/LoadingSpinner";
import OfficeAccountSelect from "../../mail/account/OfficeAccountSelect";
import WithPermissions from "../../permissions/WithPermissions";
import Table from "../../tables/Table";
import Typography from "../../text/Typography";
import MailLogOverviewItem from "./MailLogOverviewItem";

export default function MailLogOverview() {

  const { filter, updateFilter } = useQueryBasedFilter<IMailLogFilter>({
    createdAtEnd: getDateInXFromNow({ days: 1, hour: 0, minute: 0 }),
    createdAtStart: getDateInXFromNow({ minutes: -5 }),
    from: undefined,
    officeAccount: undefined,
    subject: undefined,
    ticket: undefined,
    ticketMessage: undefined,
    to: undefined,
    type: undefined,
    limit: 50,
    skip: 0
  });

  const { loadingMailLogs, mailLogs } = useMailLog(filter);

  return (
    <WithPermissions permissions={["logs.email.read"]}>
      <Card className="w-100" header="Filter" >
        <Flex row fullWidth>
          <Flex>
            <Typography color="primary" bold>Zeitraum</Typography>
            <Flex row>
              <DateTimePicker date={filter.createdAtStart} onChange={d => updateFilter({ createdAtStart: d })} />
              <Typography>-</Typography>
              <DateTimePicker max={getDateInXFromNow({ hours: 1 })} date={filter.createdAtEnd} onChange={d => updateFilter({ createdAtEnd: d })} />
            </Flex>
          </Flex>
        </Flex>
        <Typography bold color="primary">Betroffene Ressourcen</Typography>
        <Flex row>
          <OfficeAccountSelect label="Office-Konto" onChange={a => updateFilter({ officeAccount: a?._id })} value={filter.officeAccount} />
        </Flex>
      </Card>
      {
        loadingMailLogs
          ? <LoadingSpinner />
          : (
            <Table
              headers={[
                { label: "" },
                { label: "Datum" },
                { label: "Absender" },
                { label: "Empfänger" },
                { label: "Betreff" },
                { label: "Quelle" },
                { label: "Verarbeitungsstatus" },
                { label: "" }
              ]}
              items={mailLogs ?? []}
              renderItem={e => <MailLogOverviewItem key={e._id} logEntry={e} />}
            />
          )
      }
    </WithPermissions>
  )
}