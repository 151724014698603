import React from "react";
import Page from "../../components/container/Page";
import { Locale } from "../../locale/Locale";
import CreateServiceButton from "../../components/services/CreateServiceButton";
import ServiceOverview from "../../components/services/ServiceOverview";
import FeatureOverview from "../../components/services/FeatureOverview";
import CreateFeatureButton from "../../components/services/CreateFeatureButton";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import CouponOverview from "../../components/coupons/CouponOverview";
import CreateCouponButton from "../../components/coupons/CreateCouponButton";
import InvoiceOverview from "../../components/invoices/InvoiceOverview";

export enum ServiceManagementTab {
  Subscriptions = "subscriptions",
  Features = "features",
  Coupons = "coupons",
}

export default function ServiceManagementPage() {
  const [tab, setTab] = React.useState<ServiceManagementTab>(ServiceManagementTab.Subscriptions);

  const getActionButton = () => {
    switch (tab) {
      case ServiceManagementTab.Subscriptions: return <CreateServiceButton />
      case ServiceManagementTab.Features: return <CreateFeatureButton />
      case ServiceManagementTab.Coupons: return <CreateCouponButton />
    }
  }

  const getContent = () => {
    switch (tab) {
      case ServiceManagementTab.Subscriptions: return <ServiceOverview />
      case ServiceManagementTab.Features: return <FeatureOverview />
      case ServiceManagementTab.Coupons: return <CouponOverview />
    }
  }

  return (
    <Page
      showBackButton
      header={Locale.titles.serviceManagement}
      actionButtons={getActionButton()}
    >
      <TabSwitcher tabQueryParamKey="view" tabs={[
        { data: ServiceManagementTab.Subscriptions, label: "Abos", icon: "credit-card-2-front-fill" },
        { data: ServiceManagementTab.Features, label: "Features", icon: "check2-square" },
        { data: ServiceManagementTab.Coupons, label: "Rabatte & Codes", icon: "qr-code" }
      ]} saveActiveTab={(t) => setTab(t as ServiceManagementTab)} />
      {
        getContent()
      }
    </Page>
  )
}