import { Message } from "@microsoft/microsoft-graph-types";
import React, { useCallback } from "react";
import { officeAccountsGetNextMails } from "../../../api/Api";
import useFilteredOfficeMails from "../../../hooks/useFilteredOfficeMails";
import { useOfficeAcountFolderMails } from "../../../state/api/office/useOfficeAccountFolderMails";
import LinkOfficeAccountButton from "../account/LinkOfficeAccountButton";
import LabelButton from "../../buttons/LabelButton";
import Flex from "../../container/Flex";
import Icon from "../../icons/Icon";
import LoadingSpinner from "../../loader/LoadingSpinner";
import OfficeInboxSortToggle from "./filter/OfficeInboxSortToggle";
import OfficeInboxUnreadSwitch from "./filter/OfficeInboxUnreadSwitch";
import OfficeInboxFolders from "./folders/OfficeInboxFolders";
import { IWithSelectedMailProps } from "./OfficeInbox";
import OfficeInboxItem from "./OfficeInboxItem";
import "./OfficeInboxMails.css";
import { IOfficeAccount, OfficeAccountStatus, OfficeAccountType } from "../../../types/officeAccount.types";
import Typography from "../../text/Typography";
import InfiniteScroll from "../../pagination/InfiniteScroll";

export interface IOfficeInboxMailsProps extends IWithSelectedMailProps {
  account: IOfficeAccount
}

export default function OfficeInboxMails({ account, setSelectedFolder, selectedFolder, selectedMail, setSelectedMail }: IOfficeInboxMailsProps) {
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { filter } = useFilteredOfficeMails();

  const {
    folderMails,
    loadingFolderMails,
    reloadFolderMails
  } = useOfficeAcountFolderMails(account?._id, selectedFolder?.folderId, filter);

  const loadMoreRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(async (entries) => {
      const first = entries[0];
      if (first.isIntersecting && hasMore) {
        await loadMore();
      }
    }, { threshold: 0 });

    if (!!loadMoreRef.current && hasMore) observer.observe(loadMoreRef.current);

    return () => observer.disconnect();
  }, [loadMoreRef.current, hasMore, folderMails]);

  React.useEffect(() => {
    if (!folderMails) return;
    setHasMore(!!folderMails["@odata.nextLink"]);
  }, [selectedFolder, filter]);

  const mailDisplayRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!mailDisplayRef.current) return;
    mailDisplayRef.current.scrollTo({ top: 0 });
  }, [selectedFolder]);

  const loadMore = async () => {
    setLoading(true);

    try {
      const nextMails = await officeAccountsGetNextMails({
        id: account._id,
        nextUrl: folderMails?.["@odata.nextLink"]
      });

      if (!nextMails?.data) return setHasMore(false);

      const newMails = folderMails ? [...folderMails.value, ...nextMails.data.value] : nextMails.data.value;

      if (!newMails) return setHasMore(false);

      console.log(folderMails?.value);
      console.log(newMails);

      await reloadFolderMails(prev => ({ value: newMails, "@odata.nextLink": nextMails.data?.["@odata.nextLink"] || "" }), { revalidate: false });

      if (!nextMails.data["@odata.nextLink"]) return setHasMore(false);
    }
    finally {
      setLoading(false);
    }
  }

  const afterDelete = (item: Message) => {
    if (selectedMail && selectedMail.id === item.id) setSelectedMail(undefined);
    const newItems = folderMails && folderMails.value ? folderMails.value.filter(i => i.id !== item.id) : [];
    reloadFolderMails(prev => ({ value: newItems, "@odata.nextLink": folderMails && folderMails["@odata.nextLink"] }));
  }

  if (account.status !== OfficeAccountStatus.Active) return (
    <Flex>
      <Typography bold color="error" size="24">Neuanmeldung erforderlich!</Typography>
      <Typography>Der Zugriff auf Ihr Konto ist abgelaufen oder wir benötigen Ihr Einverständnis zum Zugriff auf Ihren Account.<br />Bitte melden Sie sich erneut an oder kontaktieren Sie Ihren Administrator.</Typography>
      <LinkOfficeAccountButton accountType={account.type} onBehalfOfUser={account.assignments?.personalUser} reAuthAccount={account._id} />
    </Flex>
  )

  return (
    <Flex className="h-100 w-100 position-relative" row align="start">
      <OfficeInboxFolders
        folder={selectedFolder}
        account={account}
        onChange={f => {
          setSelectedFolder(f);
        }}
      />
      <Flex className="h-100 office-inbox-mail-list ms-3" style={{ width: "fit-content" }}>
        <Flex row className="w-100" justify="between">
          <OfficeInboxUnreadSwitch />
          <OfficeInboxSortToggle />
        </Flex>
        <div className="d-flex flex-column w-100 h-100 pe-2" style={{ overflowY: "auto", overflowX: "hidden", minWidth: "370px" }} ref={mailDisplayRef} >
          {
            loadingFolderMails
              ? <LoadingSpinner />
              : (
                folderMails?.value?.length
                  ? (
                    <>
                      {
                        folderMails.value.map(mail => (
                          <OfficeInboxItem
                            afterDelete={afterDelete}
                            folderId={selectedFolder?.folderId}
                            afterMarkUnread={() => setSelectedMail(undefined)}
                            account={account}
                            isActive={!!selectedMail && selectedMail.id === mail.id}
                            item={mail}
                            key={mail.id}
                            onClick={i => setSelectedMail(i)}
                          />))
                      }
                      <Flex row>
                        <div ref={loadMoreRef} />
                        {loading && <LoadingSpinner />}
                        {
                          hasMore
                            ? <LabelButton className="justify-self-center mb-2" text="Mehr laden" onClick={async (e) => await loadMore()} />
                            : <Typography color="muted" size="12">Keine weiteren Einträge</Typography>
                        }
                      </Flex>
                    </>
                  )
                  : (
                    <Flex justify="center" align="center" className="w-100">
                      <Icon icon="cup-hot" size={24} />
                      <span>Dieser Ordner ist leer.</span>
                    </Flex>
                  )
              )
          }
        </div>
      </Flex>
    </Flex>
  )

}

