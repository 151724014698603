import React from "react";
import useUserUtil from "../../hooks/useUserUtil";
import { IUser } from "../../types/ApiTypes";
import Icon from "../icons/Icon";
import Pill from "../pills/Pill";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import RoleBanner from "./RoleBanner";
import UserUpdateForm from "./UserUpdateForm";


interface IUserListItemProps {
  user: IUser
}

export default function UserListItem({ user }: IUserListItemProps) {

  const {
    getName,
  } = useUserUtil();

  return (
    <TableRow>
      <TableCell fillAvailableSpace>
        <div className="d-flex flex-column mb-2">
          <div className="d-flex flex-column mb-1">
            <strong>{getName(user)}</strong>
            <span style={{ fontSize: "0.9em" }}>{user.mailAddress}</span>
          </div>
          <UserTenantRoleList user={user} />
        </div>
      </TableCell>
      <TableCell labelOnFalse="Verifizierung ausstehend" labelOnTrue="Verifiziert" value={user.isVerified} />
      <TableCell value={user.isSuperAdmin} />
      <TableCell value={user.isDeveloper} />
      <TableCell date={user.createdAt} />
      <TableCell date={user.updatedAt} />
      <TableCell justify="end">
        <UserUpdateForm user={user} />
      </TableCell>
    </TableRow>
  )
}

function UserTenantRoleList({ user }: { user: IUser }) {

  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const maxElements = 2;

  if (!user.authority || !user.authority.length) return <span>Kein Tenant-Zugriff</span>

  const availableTenants = user.authority.map((a) => {
    if (!a || !a.tenant) return null;
    return <RoleBanner key={`${a.tenant}-${user._id}`} tenant={a.tenant} user={user} displayTenantNameAsValue />
  }).filter(a => a != null).map((a, index) => index >= maxElements && !isExpanded ? null : a);

  const moreThanMaxElements = availableTenants.length - maxElements;

  return (
    <div className="d-flex flex-row flex-wrap align-items-center justify-content-start gap-2">
      {
        availableTenants
      }
      {
        moreThanMaxElements > 0 && (
          isExpanded
            ? <Icon icon="x" onClick={() => setIsExpanded(false)} />
            : <Pill onClick={() => setIsExpanded(true)} text={`+ ${moreThanMaxElements}`} />
        )
      }
    </div>
  )
}
