import React from "react";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useDocumentClearanceReminders } from "../../state/api/documentClearance/useDocumentClearanceReminders";
import { useCurrentTenantRole } from "../../state/api/user/useCurrentTenantRole";
import { getMonthName } from "../../util/formatter";
import Accordion from "../accordion/Accordion";
import ClientTypeBanner from "../clients/ClientTypeBanner";
import Table from "../tables/Table";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import { IDocumentClearanceMonth } from "./DocumentClearanceOverview";
import GiveDocumentClearanceButton from "./GiveDocumentClearanceButton";

export default function DocumentClearanceMonthOverview({ month, isExpandedInitially }: { isExpandedInitially: boolean, month: IDocumentClearanceMonth }) {

  const { role } = useCurrentTenantRole();
  const { reloadDocumentClearanceReminders } = useDocumentClearanceReminders();

  const dispatch = useAppDispatch();

  return (
    <Accordion icon="plus" iconExpanded="dash" isExpandedInitially={isExpandedInitially} text={getMonthName(month.month)} iconFirst expanderClass="fw-bold" >
      {
        month.data && month.data.length
          ? (
            <Table
              headers={[{ label: "Mandant" }, { label: "Status" }, { label: "Erledigt am" }, { label: "Nächste Erinnerung" }, { label: "" }]}
              className="ms-4"
              style={{ fontSize: "0.8em" }}
              items={month.data}
              renderItem={m => {
                if (!m || !m.settings) return null;

                return (
                  <TableRow key={m.reminder._id}>
                    <TableCell>
                      <ClientTypeBanner client={m.settings.client} showClientName />
                    </TableCell>
                    <TableCell value={m.reminder.isDone} labelOnTrue="Freigabe erteilt" labelOnFalse="Freigabe steht aus" />
                    <TableCell date={m.reminder.doneOn} />
                    <TableCell date={m.reminder.nextReminderOn} />
                    <TableCell>
                      {
                        role && role.isClient && <GiveDocumentClearanceButton isDone={m.reminder.isDone} reminderId={m.reminder._id} afterClearanceGiven={() => reloadDocumentClearanceReminders()} />
                      }
                    </TableCell>
                  </TableRow>

                )
              }}
            />
          )
          : <span>Keine Einträge</span>
      }
    </Accordion>
  )
}