import React from "react";
import { TicketEscalationAction } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";

export interface ITicketEscalationActionChipProps {
  action: TicketEscalationAction,
  isActive: boolean,
  readOnly: boolean,
  canBeSelected: boolean,
  saveAction?: (action: TicketEscalationAction) => void,
  deleteAction?: (action: TicketEscalationAction) => void
}

export default function TicketEscalationActionChip({ action, canBeSelected, isActive, deleteAction, readOnly, saveAction }: ITicketEscalationActionChipProps) {

  const getText = () => {
    switch (action) {
      case TicketEscalationAction.EscalateTicket: return "Zuweisung an Vorgesetzten";
      case TicketEscalationAction.MailToSupervisor: return "Vorgesetzten benachrichtigen";
      case TicketEscalationAction.MailToClient: return "Mandanten benachrichtigen";
      case TicketEscalationAction.CloseTicket: return "Ticket schließen";
    }
  }

  if (!canBeSelected) return null;

  return (
    <Pill
      rounded
      color={isActive ? "#43ff6433" : "#F0F0F0"}
      textColor={isActive ? "#43Aa64" : undefined}
      onClick={
        readOnly
          ? undefined
          : () => {
            if (readOnly) return;
            else if (isActive && deleteAction) deleteAction(action);
            else if (saveAction) saveAction(action);
          }
      }
      icon={isActive ? "check" : "plus"}
      text={getText()}
    />
  )
}