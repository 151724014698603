import React from "react";
import { sessionSetVerificationMethod } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import { useSession } from "../../../state/api/session/useSession";
import { SessionVerificationMethod } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";

export default function ResetSelectedVerificationMethodButton() {

  const { reloadSession } = useSession();

  const callApi = useApi();

  return (
    <Button
      variant="subtle"
      icon="escape"
      color="secondary"
      text="Andere Verifizierungsmethode"
      loadingText="Lädt..."
      onClick={async () => {
        await callApi(sessionSetVerificationMethod({ method: SessionVerificationMethod.None }));
        await reloadSession(undefined, { revalidate: true });
      }}
    />
  );
}