import React from "react";
import { clientsUpdateEmployeeResponsibilities, IApiResponse } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useAreasOfResponsibility } from "../../state/api/areasOfResponsibilities/useAreasOfResponsibility";
import { useClientEmployeeResponsibilities } from "../../state/api/clients/useClientEmployeeResponsibilities";
import { useDatevEmployees } from "../../state/api/employees/useDatevEmployees";
import { IWithClient } from "../../types/client.element.schema";
import { DatevStatus } from "../../types/client.schema";
import { IDatevEmployeeResponsibility } from "../../types/employeeResponsibility.schema";
import Button from "../buttons/Button";
import Expandable from "../comboBox/Expandable";
import ExpandableItem from "../comboBox/ExpandableItem";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import Typography from "../text/Typography";
import RoleBanner from "../user/RoleBanner";
import "./ImportClientsForm.css";

export interface IUpdateClientFormProps extends IWithClient { }

export default function UpdateClientEmployeeResponsibilityDialog(props: IUpdateClientFormProps) {

  const {
    client
  } = props;

  const {
    areasOfResponsibility
  } = useAreasOfResponsibility();

  const {
    datevEmployees,
    datevEmployeesByDatevId
  } = useDatevEmployees();

  const {
    clientEmployeeResponsibilities,
    loadingClientEmployeeResponsibilities,
    reloadClientEmployeeResponsibilities
  } = useClientEmployeeResponsibilities(client._id);

  const callApi = useApi();

  return (
    <WithPermissions permissions={["clients.all.update"]} >
      <ModalForm
        button={<Button size="small" variant="subtle" text="Bearbeiten" icon="pen" />}
        title="Verantwortungen bearbeiten"
        loading={loadingClientEmployeeResponsibilities}
        initialValues={{
          id: client._id,
          responsibilities: clientEmployeeResponsibilities ?? []
        }}
        enableReinitialize
        onSubmit={async (values) => {

          const res: IApiResponse | null = await callApi(clientsUpdateEmployeeResponsibilities(values));

          if (!res?.success) return false;

          await reloadClientEmployeeResponsibilities();

          return true;
        }}
      >
        {
          (formik) => {

            const highestId = Math.max(...formik.values.responsibilities.map((e: IDatevEmployeeResponsibility) => parseInt(e.id)), 0);
            const availableResponsibilities = areasOfResponsibility?.filter(e => !formik.values.responsibilities?.find(c => c.area_of_responsibility_id === e.id));

            return (
              <Flex fullWidth gap="4">
                <Flex row fullWidth justify="between">
                  <Flex gap="0">
                    <Typography size="18" upper color="primary" bold>Verantwortungen</Typography>
                    <Typography color="primary">Die hier hinterlegten Verantwortungen werden nach DATEV gespiegelt.</Typography>
                  </Flex>
                  <Expandable
                    expander={(
                      <Button
                        variant="subtle"
                        text="Hinzufügen"
                        disabledText="Alle Verantwortungen vergeben"
                        icon="plus"
                      />
                    )}
                    disabled={!availableResponsibilities?.length}
                  >
                    {
                      availableResponsibilities?.map(e => (
                        <ExpandableItem
                          text={e.name}
                          onClick={() => formik.setFieldValue("responsibilities", [
                            ...formik.values.responsibilities, {
                              area_of_responsibility_id: e.id,
                              area_of_responsibility_name: e.name,
                              client_id: client.id,
                              client_name: client.name,
                              client_number: client.number,
                              client_status: client.status,
                              employee_display_name: "",
                              employee_id: "",
                              employee_number: "",
                              employee_status: DatevStatus.Active,
                              id: (highestId + 1).toString()
                            } as IDatevEmployeeResponsibility])
                          }
                          key={e.id}
                        />
                      ))
                    }
                  </Expandable>
                </Flex>
                {
                  formik.values.responsibilities.length
                    ? formik.values.responsibilities.map((v: IDatevEmployeeResponsibility, i) => {
                      return (
                        <Flex gap="1">
                          <Typography bold>{v.area_of_responsibility_name}</Typography>
                          <Flex row>
                            <SearchableComboBox
                              expander={v.employee_id ? <RoleBanner user={datevEmployeesByDatevId?.[v.employee_id]?.user} displayNameAsValue displayRoleAtAllTimes /> : undefined}
                              values={datevEmployees}
                              shouldItemRender={e => !!e.user}
                              value={v.employee_id}
                              itemToId={e => e.id}
                              itemToString={(e) => e.name}
                              onChange={(e) => {
                                formik.setFieldValue(`responsibilities.${i}.employee_display_name`, e?.name)
                                formik.setFieldValue(`responsibilities.${i}.employee_number`, e?.number)
                                formik.setFieldValue(`responsibilities.${i}.employee_id`, e?.id)
                              }}
                            />
                            <Button
                              text="Entfernen"
                              color="error"
                              variant="subtle"
                              icon="trash"
                              onClick={() => formik.setFieldValue("responsibilities", formik.values.responsibilities.filter((_, index) => index !== i))}
                            />
                          </Flex>
                        </Flex>
                      )
                    })
                    : <Typography>Keine Verantwortungen</Typography>
                }
              </Flex>
            )
          }
        }
      </ModalForm>
    </WithPermissions>
  )
}