import React from "react";
import { IUploadableFile } from "../../types/ApiTypes";
import Button, { IButtonProps } from "../buttons/Button";

import useFileSelect, { IFileSelectOpts } from "../../hooks/useFileSelect";

export interface IFileSelectButtonPropsBase extends IButtonProps, IFileSelectOpts {
  maxBytes?: number
}

export interface IFileSelectButtonProps extends IFileSelectButtonPropsBase {
  values: IUploadableFile[],
  onChange: (files: IUploadableFile[]) => void,
  accept: string,
}

export default function FileSelectButton(props: IFileSelectButtonProps) {

  const {
    accept,
    values,
    onChange,
    disabled
  } = props;

  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    handleNewFiles
  } = useFileSelect(props);

  React.useEffect(() => {
    resetValue();
  }, [values]);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const resetValue = () => {
    if (!inputRef?.current) return;
    inputRef.current.value = "";
  }

  const preventDefaults = (e: any) => {
    if (!e) return;
    if (e.preventDefault) e.preventDefault();
    if (e.stopPropagation) e.stopPropagation();
  }



  const inputClick = async (e: any) => {
    setLoading(true);

    try {
      preventDefaults(e);

      if (!e?.target?.files?.length) return;

      const files = e.target.files;
      const newFiles = await handleNewFiles(values, files);

      if (!newFiles) return;

      onChange(newFiles);
    }
    finally {
      setLoading(false);
    }
  }

  const selectFile = async () => {
    if (!inputRef || !inputRef.current) return;
    inputRef.current.click();
  }

  const handleDragDrop = (e: DragEvent) => {
    preventDefaults(e);
    if (!e?.dataTransfer?.files?.length) return;
    inputClick({ target: { files: e.dataTransfer.files } });
  }

  return (
    <>
      <Button
        onClick={selectFile}
        variant="subtle"
        disabled={disabled || !inputRef?.current}
        loading={loading}
        color={props.color ?? "primary"}
        className={props.className}
        icon={props.icon}
        text={props.text ?? "Dateien auswählen"}
      />
      <input
        ref={inputRef}
        name="file-select-button-hiden-input"
        accept={accept}
        multiple
        style={{
          visibility: "hidden",
          display: "none"
        }}
        type="file"
        onChange={inputClick}
      />
    </>
  )
}