import React from "react";
import { AppColor } from "../../app/AppStyles";
import IElementProps from "../../types/element.types";
import { IWithServerAction, ServerAction, ServerActionStatus } from "../../types/serverAction.schema";
import Icon from "../icons/Icon";

export interface IServerActionStatusIndicatorProps<T extends IWithServerAction> extends IElementProps {
  action?: ServerAction,
  status?: ServerActionStatus,
  colorSuccess?: AppColor,
  colorProcessing?: AppColor,
  colorError?: AppColor,
  fetchStatus: () => Promise<T | undefined>,
  retryAction: () => Promise<any>
}

export default function ServerActionStatusIndicator<T extends IWithServerAction>(props: IServerActionStatusIndicatorProps<T>) {
  const {
    action,
    retryAction,
    onClick,
    fetchStatus,
    colorError,
    colorSuccess,
    colorProcessing,
    status
  } = props;

  const [currentStatus, setCurrentStatus] = React.useState<ServerActionStatus | undefined>(status);
  const [loading, setLoading] = React.useState<boolean>(ServerActionStatus.Processing === status);

  const minCheckInterval = 10000;
  const maxCheckInterval = 30000;
  const statusChecker = React.useRef<any>();

  const isDone = (s: ServerActionStatus) => {
    return s !== ServerActionStatus.Processing;
  }

  const clickHandler = async () => {
    if (currentStatus === ServerActionStatus.Failed && !!retryAction) {
      setCurrentStatus(ServerActionStatus.Processing);
      await retryAction();
      return;
    }

    await onClick?.();
  }

  const checkStatus = (at: number = minCheckInterval) => {
    clearTimeout(statusChecker.current);

    statusChecker.current = setTimeout(async () => {
      try {
        const r = await fetchStatus();
        if (!r) return;
        if (!isDone(r.status)) checkStatus(at + 5000);
        setCurrentStatus(r.status);
      }
      catch { }
    }, at > maxCheckInterval ? maxCheckInterval : at);
  }

  React.useEffect(() => {
    if (currentStatus !== ServerActionStatus.Processing) {
      setLoading(false);
      clearTimeout(statusChecker.current);
      return;
    }

    setLoading(true);
    checkStatus(10000);

    return () => {
      clearTimeout(statusChecker.current);
    }
  }, [currentStatus]);

  const isSuccess = currentStatus === ServerActionStatus.Completed;
  const isFailed = currentStatus === ServerActionStatus.Failed;

  const getIcon = () => {
    switch (action) {
      case ServerAction.SendMail:
        return isSuccess ? "envelope-check-fill" : isFailed ? "envelope-slash-fill" : "envelope";
    }
  }

  const getColor = (): AppColor | undefined => {
    if (isSuccess) return colorSuccess ?? "success";
    if (isFailed) return colorError ?? "error";
    return colorProcessing;
  }

  const getTooltipText = () => {
    switch (action) {
      case ServerAction.SendMail:
        if (isSuccess) return "Mail versendet!";
        if (isFailed) return "Für eine erneute Zustellung klicken!";
        return "Mail im Versand...";
    }
  }

  const getErrorMessage = () => {
    switch (action) {
      case ServerAction.SendMail: return "Mail konnte nicht versendet werden!";
    }
  }
  if (!status || !action) return null;

  return (
    <Icon
      onClick={clickHandler}
      label={currentStatus === ServerActionStatus.Failed ? getErrorMessage() : undefined}
      tooltip={getTooltipText()}
      icon={getIcon()}
      loading={loading}
      color={getColor()}
    />
  )
}