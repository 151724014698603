import React from "react";
import useUserUtil from "../../../hooks/useUserUtil";
import { ITicket, ITicketAssignment } from "../../../types/ApiTypes";
import Card from "../../card/Card";
import ClientTypeBanner from "../../clients/ClientTypeBanner";
import Flex from "../../container/Flex";
import Divider from "../../menu/sidebar/Divider";
import Typography from "../../text/Typography";
import UserRoleBanner from "../../user/RoleBanner";
import DeleteTicketAssignmentButton from "./DeleteTicketAssignmentButton";
import ToggleAssignmentStatusButton from "./ToggleAssignmentStatusButton";
import UpdateTicketAssignmentButton from "./UpdateTicketAssignmentButton";
import UpdateTicketAssignmentForm from "./UpdateTicketAssignmentForm";

export interface ITicketAssignmentItemProps {
  assignment: ITicketAssignment,
  isNotMainAssignment: boolean,
  ticket: ITicket
}

export default function TicketAssignmentItem({ assignment, isNotMainAssignment, ticket }: ITicketAssignmentItemProps) {

  const {
    getName,
    getCurrentTenantRole
  } = useUserUtil();

  const getTypeName = () => {
    if (assignment.isCc) return "Beobachter";
    if (assignment.isMainAssignee) return "Bearbeiter";
    if (assignment.isMainClientContact) return "Mandant";
  }

  const getColor = () => {
    if (assignment.isMainAssignee) return "primary";
    if (assignment.isMainClientContact) return "secondary";
    return "muted";
  }

  const role = getCurrentTenantRole(assignment.user);

  return (
    <Card
      color={getColor()}
      header={
        <Flex fullWidth justify="between" row >
          <Typography basedOnThisBackground={getColor()}>
            {
              assignment.user
                ? getName(assignment.user)
                : assignment.mailAddress
            }
          </Typography>
          <Typography basedOnThisBackground={getColor()}>{getTypeName()}</Typography>
        </Flex>
      }
    >
      <Flex fullWidth>
        <Flex row justify="between" fullWidth>
          {
            !assignment.isMainClientContact && <UserRoleBanner user={assignment.user} />
          }
          {
            assignment.client && <ClientTypeBanner showClientName client={assignment.client} />
          }
          <DeleteTicketAssignmentButton
            canDelete={!!isNotMainAssignment || (!assignment.isMainAssignee && !assignment.isMainClientContact)}
            ticket={ticket}
            assignment={assignment}
          />
        </Flex>
        {
          !assignment.isMainAssignee && !assignment.isMainClientContact && !!role && (
            <>
              <Divider height={1} opacity={0.45} />
              <Typography basedOnThisBackground={getColor()} bold>Aktionen</Typography>
              <Flex row wrap fullWidth justify="between">
                <Flex row>
                  <UpdateTicketAssignmentButton
                    assignment={assignment}
                    icon="check"
                    ticket={ticket}
                    client={assignment.client}
                    user={assignment.user}
                    isMainAssignee={!role.isClient}
                    isMainClientContact={role.isClient}
                    color={role.isClient ? "bright" : "success"}
                    text={role.isClient ? "Als Mandant übernehmen" : "Als Bearbeiter übernehmen"}
                  />
                </Flex>
              </Flex>
            </>
          )
        }

        {
          assignment && (
            <>
              <Divider height={1} opacity={0.45} />
              <Flex row justify="between" fullWidth>
                <UpdateTicketAssignmentForm ticket={ticket} assignment={assignment} />
                <ToggleAssignmentStatusButton ticket={ticket} assignment={assignment} />
              </Flex>
            </>
          )
        }
      </Flex>
    </Card>
  )
}