import React from "react";
import WithPermissions from "../../components/permissions/WithPermissions";
import TenantCreateForm from "../../components/tenant/TenantCreateForm";
import TenantList from "../../components/tenant/TenantList";
import useLabels, { Label } from "../../hooks/useLabels";
import Page from "../../components/container/Page";

export default function TenantManagement() {
  const getLabel = useLabels();

  return (
    <WithPermissions superAdminOnly>
      <Page header={`Alle ${getLabel(Label.TenantName, true)} verwalten`} actionButtons={<TenantCreateForm />} showBackButton>
        <TenantList />
      </Page>
    </WithPermissions>
  )
}