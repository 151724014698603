import { Message } from "@microsoft/microsoft-graph-types";
import { IUploadableEmployee } from "../components/employees/ImportEmployeeForm";
import { IOfficeMailFormValues } from "../components/mail/inbox/OfficeMailForm";
import {
  AbsenceType,
  AliasType,
  CdpAgreement,
  DocumentClearanceReminderInterval,
  IAliasContent,
  IAttachment,
  ICloudGatewayLogIn,
  ICloudGatewayQueryOptions,
  IFileMetadata,
  IMailTemplateDocument,
  IRoleDocument,
  ISettings,
  ISignatureDocument,
  ITenant,
  ITenantAddress,
  ITenantAuthority,
  ITenantSettings,
  ITicketAssignment,
  ITicketAssignmentDocument,
  IUploadableFile,
  IUser,
  IUserClientsDocument,
  IUserDefaultTeamsDocument,
  IUserSettings,
  SessionVerificationMethod,
  TicketSource,
  TicketState,
  TicketType
} from "../types/ApiTypes";
import { IClient } from "../types/client.schema";
import { IOfficeAccountDocument, OfficeAccountType } from "../types/officeAccount.types";
import { IGlobalMailTemplateDocument } from "../types/globalMailTemplates.schema";
import { ITeamBase } from "../types/team.schema";
import { IDebuggableResource } from "../types/debug.schema";

export interface ISettingsUpdateRequest extends ISettings { }

export interface IRequestWithId {
  id: string
}

export interface ISendTestMailOverImapRequest extends IRequestWithId {
  to: string,
  text: string
}

export interface ITenantUpdateMailAccountRequest extends IRequestWithId {
  usersReceiveUnsafeAttachmentsPerMailDefault: boolean,
  dontSendTicketErrorMailsWithPersonalAccount: boolean,
  sendMailsPreferrablyWithPersonalAccounts: boolean,
  sendTicketOpenedMail: boolean,
  sendMessageHistoryWithEveryTicketMail: boolean,
  dontSendTicketErrorMails: boolean,
  defaultSalutation: string,
}

export interface ICreateDocumentClearanceSettingsBody {
  amountOfRemindersToBeSent: number,
  daysBetweenReminders: number,
  remindOnDayOfMonth: number,
  clearingUser: string,
  interval: DocumentClearanceReminderInterval
}

export interface IUserPhoneNumberVerifyRequest {
  otp: string
}

export interface IUserPhoneNumberUpdateRequest {
  phoneNumber: string
}

export interface ITenantOnboardingRequest {
  registrationToken: string,
  tenantId: string
}

export interface IClientImportLogRequest {
  importId: string
}

export interface ITenantOnboardingCreateInitialUserRequest extends ITenant, ITenantOnboardingRequest {
  firstName: string,
  lastName: string,
  roleId: string
}

export interface ITenantOnboardingUpdateBillingDataRequest extends ITenantOnboardingRequest, ITenantUpdateBillingDataRequest { }

export interface ITenantByIdRequest {
  _id: string
}

export interface ITenantDefaultPaymentMethodRequest {
  id: string,
  paymentMethodId: string
}

export interface ITenantOnboardingSetHasTenantDetailsRequest extends ITenantOnboardingRequest {
}

export interface ITenantUpdateBillingDataRequest {
  _id: string,
  address: ITenantAddress,
  company: string,
  mailAddress: string
}

export interface ITenantSubscriptionRequest {
  tenantId: string,
  planId: string,
}

export interface ITenantOnboardingAddSubscriptionRequest extends ITenantSubscriptionAddRequest, ITenantOnboardingRequest { }

export interface ITenantSubscriptionAddRequest extends ITenantSubscriptionRequest {
  promoCode?: string
}

export interface ITenantOnboardingValidateSubdomain extends ITenant, ITenantOnboardingRequest {
  subdomain: string
}

export interface ITenantOnboardingUpdateRequest extends ITenant, ITenantOnboardingRequest {
}

export interface ITenantOnboardingGetInitialPasswordRequest extends ITenantOnboardingRequest {
}

export interface ITenantOnboardingDomainAddRequest extends ITenant, ITenantOnboardingRequest {
  subdomain: string
}

export interface ITenantOnboardingDomainGetStatusRequest extends ITenantOnboardingRequest {
  tenantId: string,
  domainId: string
}

export interface ITenantUpdateSettingsRequest extends ITenantSettings {
  tenantId: string
}

export interface ITenantVerifyRequest {
  token: string
}

export interface ITenantCreateRequest extends IDebuggableResource {
  mailAddress: string,
  onboardingIsManaged: boolean
}

export interface IDomainDeleteRequest {
  domainId: string
}

export interface IDomainRefreshRequest {
  domainId: string
}

export interface ITenantDeleteRequest {
  tenantId: string,
  deleteTenantToken: string
}

export interface ITenantRequestDeletionTokenRequest {
  tenantId: string
}

export interface IDomainCreateRequest {
  domain: string
}

export interface IUserUpdateAbsenceRequest extends IRequestWithId {
  to: string,
  deputyId: string,
}

export interface IUserCreateAbsenceRequest extends IUserUpdateAbsenceRequest {
  from: string,
  to: string,
  userId: string,
  deputyId: string,
  type: AbsenceType
}

export interface IUserLogInRequest {
  mailAddress: string,
  password: string
}

export interface IUserNewPasswordRequest {
  token: string,
  oldPassword: string,
  newPassword: string
}

export interface IUserUpdateRequest {
  _id: string,
  mailAddress: string,
  firstName: string,
  lastName: string,
  mailNamePrefix: string,
  title: string,
  isTestingUser: boolean,
  authority: ITenantAuthority[],
  isSuperAdmin: boolean,
  isDeveloper: boolean
}

export interface IUserUpdateSettingsRequest extends IUserSettings, IRequestWithId { }

export interface IUserVerificationRequest {
  token: string,
  password: string
}

export interface IUserVerifyResetTokenRequest {
  token: string
}

export interface IUserBlockRequest {
  mailAddress: string
}

export interface IUserResetPasswordWithMailRequest {
  mailAddress: string
}

export interface IUserDeleteRequest {
  userId: string
}

export interface IUserRegistratationRequest extends IUser {
}

export interface ISessionUpdateVerificationMethodRequest {
  method: SessionVerificationMethod
}

export interface ISessionVerificationRequest {
  otp: string
}

export interface ITicketCreationRequest {
  subject: string,
  sendMail: boolean,
  firstMessage?: string,
  isDeveloperTicket?: boolean,
  source: TicketSource,
  createFromMailId?: string,
  attachments: Array<IUploadableFile>,
  createFromMailAccountId?: string,
  firstMessageIsFromClient: boolean,
  firstNote?: string,
  assignee?: string,
  type: TicketType,
  clientMail: string,
  clientContactId?: string,
  clientId?: string
}

export interface ITicketSetInternalSubjectRequest {
  internalSubject: string,
  ticketId: string
}

export interface ITicketsUpdateMultipleRequest {
  ticketIds: string[]
}

export interface ITicketsUpdateStatusRequest extends ITicketsUpdateMultipleRequest {
  state: TicketState,
  sendMail: boolean,
  resubmitAt: string,
  message?: string
}


export interface ITicketAddNoteRequest {
  text: string,
  ticketId: string
}

export interface ITicketEscalateRequest {
  ticketId: string,
  escalateTo: string
}

export interface ITicketAssignToTeamRequest {
  ticketId: string,
  assignee: string,
  teamId: string
}

export interface ITicketUpdateLastAccessRequest {
  ticketId: string
}

export interface ITicketAssignRequest {
  ticketId: string,
  clientContactId?: string,
  clientId?: string,
  userId: string
}

export interface ITicketMergeRequest {
  ticketIds: Array<string>,
  mergeIntoTicket: string,
  mergeMessages: boolean
}

export interface ITicketDeleteRequest extends ITicketsUpdateMultipleRequest {
  sendMail: boolean
}

export interface ITeamDeleteRequest {
  teamId: string
}

export interface ICreateOrUpdateCloudGatewayLogIn extends ICloudGatewayLogIn { }

export interface IUpdateCloudGatewayQuerySettings extends ICloudGatewayQueryOptions, IRequestWithId { }

export interface ITeamUpdateRequest extends IRequestWithId, ITeamBase {
  members?: string[],
  areaOfResponsibilityId: string
}

export interface ITeamCreateRequest extends ITeamBase {
  members?: string[]
}

export interface IClientCreateRequest {
  name: string,
  legalForm?: string,
  importNumber?: string,
  clientManager?: string,
}

export interface IClientUpdateRequest extends IClient {
}

export interface ITicketMessageAddAttachment extends IFileMetadata, IUploadableFile {
}

export interface ITicketMessageDownloadAttachmentRequest extends IRequestWithId {
  attachments: Array<IAttachment>
}

export interface ITicketMessageAddRequest extends IRequestWithId {
  attachments: Array<ITicketMessageAddAttachment>,
  mentions: Array<string>,
  text: string
}

export interface ITicketMessageUpdateRequest extends IRequestWithId {
  text: string
}

export interface IClientDeleteRequest extends IRequestWithId {

}

export interface IEmployeesImportRequest {
  employees: IUploadableEmployee[]
};

export interface IClientsImportFromDatevRequest {
  clients: any[],
  alsoImportInactiveData: boolean
}

export interface IAzureGetSignInUrlRequest extends IDebuggableResource {
  accountType: OfficeAccountType,
  onBehalfOfUser?: string,
  reAuthAccount?: string
}

export interface IAzureWithUrlResponse {
  url: string
}

export interface IOfficeAccountInboxRequest extends IRequestWithId {
  nextUrl?: string
}

export interface IOfficeGraphResponse<T> {
  "@odata.nextLink"?: string,
  value: Array<T>
}

export interface IOfficeInboxContentResponse extends IOfficeGraphResponse<Message> { }

export interface IOfficeSendMailForAccountRequest extends IRequestWithId {
  mail: IOfficeMailFormValues,
  originalSubject?: string,
  inResponseToMailId?: string
}

export interface IOfficeAccountMailByIdRequest extends IRequestWithId {
  messageId: string
}

export interface IOfficeAccountUpdateMailRequest extends IRequestWithId, IOfficeAccountMailByIdRequest {
  isRead?: boolean
}

export interface IOfficeAccountAttachmentPreviewRequest extends IRequestWithId {
  mailId: string
}

export interface IOfficeAccountAttachmentContentRequest extends IRequestWithId {
  mailId: string,
  attachmentId: string
}

export interface IOfficeAccountAddMailAsMessageToTicketRequest extends IRequestWithId {
  mailId: string,
  ticketId: string
}

export interface IOfficeAccountUpdateRequest extends IOfficeAccountDocument { }

export interface IUpdateUserClientsRequest extends IUserClientsDocument, IRequestWithId { }

export interface ICreateAliasRequest extends IAliasContent, IRequestWithId { }

export interface IUserUpdateDefaultTeamRequest extends IRequestWithId, IUserDefaultTeamsDocument { }

export interface ITicketEscalateManuallyRequest {
  ticketId: string,
  escalationWorkflowId: string
}

export interface IMailTemplateCreateRequest extends IMailTemplateDocument { }

export interface IMailTemplateUpdateRequest extends IMailTemplateDocument, IRequestWithId { }

export interface ISessionCreateImposterRequest {
  user: string,
  minutes: number
}

export interface ITestMailTemplateRequest {
  templateId: string,
  isGlobal: boolean,
  ticketId: string,
  ticketMessageId: string,
}

export interface IUpdateSignatureRequest extends ISignatureDocument {

}

export enum RoundMailTargetType {
  Tickets = "tickets",
  Clients = "clients"
}

export interface ISendRoundMailRequest {
  targetType: RoundMailTargetType,
  targets: Array<string>,
  subject: string,
  content: string
}

export interface ICdpGrantConsentRequest {
  version: CdpAgreement
}

export interface ITicketAssignmentAddRequest extends ITicketAssignmentDocument { }
export interface ITicketAssignmentUpdateRequest extends ITicketAssignment { }
export interface ITicketAssignmentDeleteRequest extends IRequestWithId {
  ticketId: string
}

export interface IAssignAliasFromTicketRequest extends IRequestWithId {
  clientId: string,
  userId: string,
  type: AliasType
}

export interface IRoleCreateRequest extends IRoleDocument { }

export interface IRoleUpdateRequest extends IRoleDocument, IRequestWithId { }