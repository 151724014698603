import React from "react";
import { useTicketActivity } from "../../../state/api/tickets/useTicketActivity";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Flex from "../../container/Flex";
import TicketActivityItem from "../TicketActivityItem";
import { ITicket } from "../../../types/ApiTypes";
import SidebarHeader from "../../menu/sidebar/SidebarHeader";
import { useCurrentTenantRole } from "../../../state/api/user/useCurrentTenantRole";

export default function TicketActivity({ ticket }: { ticket: ITicket }) {

  const {
    role
  } = useCurrentTenantRole();

  const { loadingTicketActivity, reloadTicketActivity, ticketActivity } = useTicketActivity(ticket);

  if (role && role.isClient) return null;
  if (loadingTicketActivity) return <LoadingSpinner />

  return (
    <Flex>
      <SidebarHeader text="Aktivität" />
      {
        ticketActivity && ticketActivity.length
          ? ticketActivity.map(activity => <TicketActivityItem ticket={ticket} key={activity._id} activity={activity} />)
          : <span>Keine Aktivität</span>
      }
    </Flex>
  )
}