import { Form, Formik, useFormikContext } from "formik";
import React from "react";
import { usersUpdateSettings } from "../../api/Api";
import { IUserUpdateSettingsRequest } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import useUserUtil from "../../hooks/useUserUtil";
import { useUser } from "../../state/api/user/useUser";
import Flex from "../container/Flex";
import CheckBox from "../forms/CheckBox";
import Editor from "../richText/Editor";
import SalutationSelect from "../salutations/SalutationSelect";
import Typography from "../text/Typography";
import VerifiedPhoneNumber from "./phoneNumber/VerifiedPhoneNumber";
import { IUser } from "../../types/ApiTypes";

export default function UserSettings() {

  const { user, reloadUser } = useUser();

  const {
    normalizeUser
  } = useUserUtil();


  const callApi = useApi();
  const normalizedUser = normalizeUser(user);

  return (
    <div className="d-flex flex-column w-100 h-100 gap-3">
      <VerifiedPhoneNumber user={normalizedUser} />
      <Formik
        enableReinitialize
        initialValues={normalizedUser}
        onSubmit={async (values) => {
          const res = await callApi(usersUpdateSettings({
            ...values.settings,
            id: values._id,
          }));

          if (!res) return;

          await reloadUser();
        }}
      >
        {
          (formik) => (
            <Form className="w-100">
              <UserUpdateSettingsForm hideSalutation />
            </Form>
          )
        }
      </Formik>
    </div>
  )
}

export function UserUpdateSettingsForm({ hideSalutation }: { hideSalutation?: boolean }) {

  const {
    values,
    setFieldValue
  } = useFormikContext<IUser>();

  return (
    <Flex fullWidth gap={3}>
      <Flex fullWidth gap="3">
        <Flex row fullWidth justify="between">
          <Typography color="primary" bold>Mail-Einstellungen</Typography>
          <CheckBox name="settings.mails.receiveSystemNotifications" label="System-Emails erhalten" />
        </Flex>
        {
          values.settings?.mails?.receiveSystemNotifications
            ? (
              <Flex fullWidth>
                {
                  !hideSalutation && (
                    <SalutationSelect value={values.settings.mails?.defaultSalutation} onChange={(s) => setFieldValue("settings.mails.defaultSalutation", s)} />
                  )
                }
              </Flex>
            )
            : <Typography>Mailerhalt ist deaktiviert.</Typography>
        }
      </Flex>
      <Flex fullWidth gap="3">
        <Flex row fullWidth justify="between">
          <Typography color="primary" bold>Abwesenheit</Typography>
          <CheckBox name="settings.absence.sendAbsenceNotifications" label="Abwesenheitsbenachrichtigung aktivieren" />
        </Flex>
        {
          values.settings?.absence?.sendAbsenceNotifications && (
            <>
              <em>Folgender Text wird versandt, wenn eine Mail an Sie erhalten wird, oder eine Aktivität für ein an Sie zugewiesenes Ticket aufgezeichnet wird und Sie <strong>nicht</strong> die Option der Weiterleitung von Tickets an einen Vertreter aktiviert haben.</em>
              <Editor
                onChange={(value) => setFieldValue("settings.absence.absenceNotificationMessage", value)}
                content={values.settings?.absence?.absenceNotificationMail}
              />
            </>
          )
        }
        <CheckBox name="settings.absence.assignTicketsToDeputy" label="Tickets während Abwesenheit an Stellvertreter zuweisen" />
      </Flex>
    </Flex>
  )
}