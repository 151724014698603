import React from "react";
import { azureGetSignInUrl } from "../../../api/Api";
import { IAzureGetSignInUrlRequest } from "../../../api/ApiRequests";
import useApi from "../../../hooks/useApi";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import Button from "../../buttons/Button";
import WithPermissions from "../../permissions/WithPermissions";
import { OfficeAccountType } from "../../../types/officeAccount.types";
import ModalForm from "../../modal/ModalForm";
import Flex from "../../container/Flex";
import Typography from "../../text/Typography";
import { useUser } from "../../../state/api/user/useUser";
import CheckBox from "../../forms/CheckBox";

export default function LinkOfficeAccountButton(props: IAzureGetSignInUrlRequest) {

  const {
    accountType,
    onBehalfOfUser,
    reAuthAccount
  } = props;

  const callApi = useApi();

  const {
    getInboxPermissions
  } = useInboxPermissions();

  const {
    user
  } = useUser();

  const isPersonalWithoutUser = accountType === OfficeAccountType.Personal && !props.onBehalfOfUser;

  const text = !!reAuthAccount
    ? "Authentifizierung erforderlich"
    : (
      isPersonalWithoutUser
        ? "Bitte loggen Sie sich ein"
        : "Office365-Account verknüpfen"
    );

  const color = !!reAuthAccount ? "error" : "primary";
  const icon = !!reAuthAccount ? "exclamation" : "plus";

  const permissions = getInboxPermissions("create", accountType, props.onBehalfOfUser);

  return (
    <WithPermissions permissions={permissions} >
      <ModalForm
        title={text}
        disabled={!reAuthAccount && isPersonalWithoutUser}
        button={<Button text={text}
          color={color}
          icon={icon}
        />}
        initialValues={props}
        onSubmit={async (values) => {
          const res = await callApi(azureGetSignInUrl(values));
          if (!res?.success) return false;
          window.location.href = res.data.url;
          return true;
        }}
      >
        {
          formik => (
            <Flex fullWidth>
              <Typography>Sie werden zum Login auf die Seite von Microsoft weitergeleitet. Wählen Sie bitte das Konto aus, welches Sie mit Tiggi verknüpfen wollen. Wichtig: Sollte der Log-In nicht funktionieren, weisen Sie bitte Ihren Administrator darauf hin, dass Tiggi als vertrauenswürdige App zum Azure Tenant Ihrer Kanzlei hinzugefügt werden muss.</Typography>
              {user?.isSuperAdmin && !onBehalfOfUser && <CheckBox name="isDebugResource" label="Debug-Account erstellen" />}
            </Flex>
          )
        }
      </ModalForm>

    </WithPermissions>
  )
}