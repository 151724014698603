import React from "react";
import Page from "../../components/container/Page";
import BillingDetails from "../../components/billing/BillingDetails";
import PaymentMethods from "../../components/billing/PaymentMethods";
import CurrentSubscriptionOverview from "../../components/subscription/CurrentSubscriptionOverview";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import { Locale } from "../../locale/Locale";
import CreatePaymentMethodButton from "../../components/billing/CreatePaymentMethodButton";
import InvoiceOverview from "../../components/invoices/InvoiceOverview";

enum BillingPageTab {
  BillingDetails = "details",
  PaymentMethods = "payment",
  Subscription = "subscriptions",
  Invoices = "invoices"
}

export default function BillingOverviewPage() {

  const [tab, setTab] = React.useState<BillingPageTab>(BillingPageTab.BillingDetails);

  const getActionButton = () => {
    switch (tab) {
      case BillingPageTab.PaymentMethods: return <CreatePaymentMethodButton />
    }
  }

  const getContent = () => {
    switch (tab) {
      case BillingPageTab.BillingDetails: return <BillingDetails />
      case BillingPageTab.PaymentMethods: return <PaymentMethods />
      case BillingPageTab.Subscription: return <CurrentSubscriptionOverview />
      case BillingPageTab.Invoices: return <InvoiceOverview />
    }
  }

  return (
    <Page showBackButton header={Locale.titles.billing} actionButtons={getActionButton()}>
      <TabSwitcher
        saveActiveTab={t => setTab(t as BillingPageTab)}
        tabQueryParamKey="view"
        tabs={[
          {
            data: BillingPageTab.BillingDetails,
            label: "Rechnungsdetails",
            icon: "geo-alt-fill"
          },
          {
            data: BillingPageTab.Invoices,
            label: "Rechnungen",
            icon: "receipt"
          },
          {
            data: BillingPageTab.PaymentMethods,
            label: "Zahlungsmethoden",
            icon: "wallet"
          },
          {
            data: BillingPageTab.Subscription,
            label: "Abonnements",
            icon: "view-list"
          },
        ]}
      />
      {
        getContent()
      }
    </Page>
  )
}