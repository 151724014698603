import { ModalType } from "../config/ModalTypes";
import { IUploadableFile } from "../types/ApiTypes";
import { formatFileSize } from "../util/formatter";
import useModalNotifications from "./useModalNotifications";

export interface IFileSelectOpts {
  maxBytes?: number,
  removeFileTypePrefix?: boolean
}

export default function useFileSelect(opts: IFileSelectOpts = {}) {

  const {
    maxBytes,
    removeFileTypePrefix
  } = opts;

  const showNotifications = useModalNotifications();

  const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (!reader.result) return null;
      const r = typeof reader.result === "string" ? reader.result : reader.result.toString();

      if (removeFileTypePrefix) resolve(r.split(",")[1]);
      else resolve(r);
    };
    reader.onerror = reject;
  });


  const toUploadable = async (f: File): Promise<IUploadableFile | null> => {
    try {
      const content = await toBase64(f);

      if (!content) return null;

      return {
        name: f.name,
        type: f.type,
        size: f.size,
        content
      }
    }
    catch { }
    return null;
  };

  const checkSizeOverflow = (oldBytes: number, newBytes: number): boolean => {
    if (!maxBytes) return true;


    if (oldBytes + newBytes > maxBytes) {
      showNotifications({
        text: `Die Dateien sind zu groß. Die maximal erlaubte Größe beträgt ${formatFileSize(maxBytes)}.`,
        type: ModalType.Error
      });
      return false;
    }

    return true;
  }

  const handleNewFiles = async (existing: IUploadableFile[], files: FileList | File[]): Promise<IUploadableFile[] | null> => {
    let oldBytes = existing.reduce((acc, f) => acc + f.size, 0);

    let bytes = 0;
    const next: Array<IUploadableFile> = [];

    for (const f of files) {
      const uploadable = await toUploadable(f);
      if (!uploadable) continue;
      next.push(uploadable);
      bytes += f.size;
    }

    if (!checkSizeOverflow(oldBytes, bytes)) return null;

    return [...existing, ...next];
  }

  return {
    handleNewFiles,
    toBase64
  }
}