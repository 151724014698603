import React from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import useWindowSize from "../../hooks/useWindowSize";
import "./Expandable.css";

export interface IExpandableProps {
  expander: React.ReactElement,
  children: React.ReactNode | ((close: Function) => React.ReactNode),
  disabled?: boolean,
  keepOpen?: boolean,
  noBackground?: boolean,
}

export interface IExpandOrigin {
  top: number,
  left: number,
  bottom: number,
  right: number,
  height: number,
  width: number
}

export default function Expandable({ expander, keepOpen, noBackground, children, disabled }: IExpandableProps) {

  const [expanded, setExpanded] = React.useState<boolean>(true);
  const [expandOrigin, setExpandOrigin] = React.useState<IExpandOrigin>();
  const [expandToTop, setExpandToTop] = React.useState<boolean>(false);
  const [expandFromRight, setExpandFromRight] = React.useState<boolean>(false);
  const [shouldClose, setShouldClose] = React.useState<boolean>(false);
  const [isDisappearing, setIsDisappearing] = React.useState<boolean>(false);

  const windowSize = useWindowSize();

  const contentRef = React.useRef<HTMLDivElement>(null);
  const expanderRef = React.useRef<HTMLDivElement>(null);
  const closeTimer = React.useRef<any>();

  React.useEffect(() => {

    const docClickHandler = (e: MouseEvent) => {
      if (!expanded || disabled) return;

      if (contentRef?.current && contentRef.current.contains(e.target as Node)) return;
      if (expanderRef?.current && expanderRef.current.contains(e.target as Node)) return;

      setExpanded(false);
    }

    document.addEventListener("click", docClickHandler, true);

    return () => document.removeEventListener("click", docClickHandler);
  }, []);

  React.useEffect(() => setExpanded(false), [windowSize]);

  React.useEffect(() => {
    clearTimeout(closeTimer.current);
    setIsDisappearing(false);

    if (keepOpen) return;
    if (!shouldClose) return;

    setIsDisappearing(true);

    closeTimer.current = setTimeout(() => {
      setExpanded(false);
      setIsDisappearing(false);
    }, 1000);

    return () => clearTimeout(closeTimer.current);
  }, [shouldClose, keepOpen]);


  const openExpander = () => {
    clearTimeout(closeTimer.current);
    setIsDisappearing(false);
    setShouldClose(false);

    if (disabled) return;

    setExpanded(true);
  }

  const closeExpander = () => setShouldClose(true);

  const toggleExpanded = () => {
    if (disabled) return;
    if (expanded) return setExpanded(false);

    setExpanded(true);

    if (!expanderRef?.current) return;

    const location = expanderRef.current.getBoundingClientRect();

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    setExpandToTop((location.top > (windowHeight / 2)));
    setExpandFromRight((location.left > (windowWidth / 2)));

    setExpandOrigin({
      top: location.top,
      left: location.left,
      bottom: location.bottom,
      right: location.right,
      height: location.height + 5,
      width: location.width
    })
  }

  const expandableContentClass = generateClassName("expandable-content d-flex flex-column gap-0", {
    base: "expandable-content-expand-to-",
    value: expandToTop,
    onTrue: "top",
    standard: "bottom"
  }, {
    base: "expandable-content-expand-from-",
    value: expandFromRight,
    onTrue: "right",
    standard: "left",
  }, {
    value: !noBackground,
    onTrue: "expandable-content-background"
  }, {
    value: isDisappearing,
    onTrue: "expandable-content-disappearing"
  });

  return (
    <div
      className="d-flex flex-column gap-2 expandable"
      onMouseLeave={closeExpander}
      onMouseEnter={() => setIsDisappearing(false)}
    >
      <div
        className="expandable-expander"
        onClick={toggleExpanded}
        ref={expanderRef}
      >
        {React.cloneElement(expander, { disabled: disabled })}
      </div>
      {
        ((expanded) && expandOrigin) && (
          <div
            className={expandableContentClass}
            onMouseOver={openExpander}
            style={generateStyle({
              name: "top",
              value: expandOrigin.top + expandOrigin.height,
              applyCondition: !expandToTop
            }, {
              name: "left",
              value: expandOrigin.left,
              applyCondition: !expandFromRight
            }, {
              name: "right",
              value: window.innerWidth - expandOrigin.right,
              applyCondition: expandFromRight
            }, {
              name: "bottom",
              value: window.innerHeight - expandOrigin.bottom + expandOrigin.height,
              applyCondition: expandToTop
            }, {
              name: "transformOrigin",
              value: `${expandFromRight ? "right" : "left"} ${expandToTop ? "top" : "bottom"}`,
            }, {
              name: "minWidth",
              value: expandOrigin.width > 200 ? expandOrigin.width : 200
            })}
          >
            <div
              className="d-flex flex-column position-relative gap-0 w-100 h-100"
              ref={contentRef}
            >
              {
                typeof children === "function"
                  ? children(() => {
                    setShouldClose(false);
                    clearTimeout(closeTimer.current);
                    setIsDisappearing(false);
                    setExpanded(false)
                  })
                  : children
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

