import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tenantsOnboardingAddToCurrentDomains, tenantsOnboardingCreateManagingUser, tenantsOnboardingGetCurrentState, tenantsOnboardingGetGlobalService, tenantsOnboardingGetRolesForTenant, tenantsOnboardingSelectBaseSubscription, tenantsOnboardingSetBillingData, tenantsOnboardingUpdate, tenantsOnboardingValidateTenantSubdomain } from "../../../api/Api";
import { ITenantOnboardingAddSubscriptionRequest, ITenantOnboardingCreateInitialUserRequest, ITenantOnboardingDomainAddRequest, ITenantOnboardingUpdateBillingDataRequest, ITenantOnboardingUpdateRequest } from "../../../api/ApiRequests";
import { AppRouteParams, AppRoutes } from "../../../config/AppRoutes";
import { ModalType } from "../../../config/ModalTypes";
import useAnyRoleUtil from "../../../hooks/useAnyRoleUtil";
import useApi from "../../../hooks/useApi";
import { generateClassName } from "../../../hooks/useAttributes";
import useModal from "../../../hooks/useModal";
import useUserUtil from "../../../hooks/useUserUtil";
import Error from "../../../pages/Error";
import { IDomain, IPlan, IRole, ITenant } from "../../../types/ApiTypes";
import { RootUrl, StripePK } from "../../../util/envVars";
import { getId } from "../../../util/mongoUtil";
import Button from "../../buttons/Button";
import Card from "../../card/Card";
import SearchableComboBox from "../../comboBox/SearchableComboBox";
import Flex from "../../container/Flex";
import FieldWithLabel from "../../forms/FormikField";
import LoadingSpinner from "../../loader/LoadingSpinner";
import PageLoader from "../../loader/PageLoader";
import PlanCard from "../../services/PlanCard";
import Typography from "../../text/Typography";
import RoleBanner from "../../user/RoleBanner";
import MultiStepWizard from "../../wizard/MultiStepWizard";
import MultiStepWizardPage from "../../wizard/MultiStepWizardPage";
import TenantBillingDetailsForm from "../TenantBillingDetailsForm";
import OnboardingPaymentDetails from "./elements/OnboardingPaymentDetails";
import "./TenantOnboardingWizard.css";
import ElementsErrorBoundary from "./elements/ElementsErrorBoundary";

interface ITenantOnboardingCreateInitialUserFormValues extends ITenantOnboardingCreateInitialUserRequest {
  role?: IRole
}

enum TenantOnboardingPage {
  GetTenantAdminInformation = 0,
  GetCompanyBillingDetails = 1,
  SelectSubscription = 2,
  GetCompanyPaymentDetails = 3,
  GetTenantNiceName = 4,
  CreateTenantDomain = 5,
  SuccessPage = 6
}

const getStripe = async () => {
  try {
    return await loadStripe(StripePK);
  }
  catch (err) {
    console.log("STRIPE ERR", err);
    return null;
  }
}

const stripePromise = getStripe();

export default function TenantOnboardingWizard({ hasPaymentError }: { hasPaymentError: boolean }) {

  const [initialIndex, setInitialIndex] = React.useState<TenantOnboardingPage>(TenantOnboardingPage.GetTenantAdminInformation);
  const [currentIndex, setCurrentIndex] = React.useState<TenantOnboardingPage>(TenantOnboardingPage.GetTenantAdminInformation);
  const [loadingRoles, setLoadingRoles] = React.useState<boolean>(true);
  const [loadingSubs, setLoadingSubs] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [roles, setRoles] = React.useState<IRole[]>([]);
  const [domain, setDomain] = React.useState<IDomain>({} as IDomain);
  const [tenant, setTenant] = React.useState<ITenant | null>(null);
  const [stripeClientSecret, setStripeClientSecret] = React.useState<string>("");
  const [globalSubscriptions, setGlobalSubscriptions] = React.useState<Array<IPlan>>([]);

  const {
    getFilteredRoles,
  } = useAnyRoleUtil();

  const {
    getAuthority,
    getName
  } = useUserUtil();

  const verificationToken = useParams()[AppRouteParams.VerificationToken.keyName];

  const callApi = useApi();
  const showModal = useModal();
  const navigate = useNavigate();

  const fetchTenantRoles = async (registrationToken: string, tenantId: string, ac: AbortController) => {

    setLoadingRoles(true);

    const res = await tenantsOnboardingGetRolesForTenant({ registrationToken, tenantId }, ac);

    if (res.canceled) return;

    try {
      if (!res.success) return;
      setRoles(res.data ?? []);
    }
    finally {
      setLoadingRoles(false);
    }
  }

  const fetchTenantByToken = async (token: string, ac: AbortController) => {
    setLoading(true);

    const res = await tenantsOnboardingGetCurrentState({ token }, ac);

    if (res.canceled) return;

    try {
      if (!res.success || !res.data) {
        showModal({
          text: res.message || "Der angegebene Code ist ungültig.",
          type: ModalType.Error
        });

        navigate(AppRoutes.Home.path);
        return;
      }
      else setTenant(res.data);
    }
    finally {
      setLoading(false);
    }
  }

  const fetchGlobalSubscription = async (registrationToken: string, tenantId: string, ac: AbortController) => {
    setLoadingSubs(true);

    const globalSubs = await tenantsOnboardingGetGlobalService({ registrationToken, tenantId }, ac);

    if (globalSubs.canceled) return;

    try {
      setGlobalSubscriptions(globalSubs?.data ?? []);
    }
    finally {
      setLoadingSubs(false);
    }
  };

  React.useEffect(() => {
    if (!verificationToken) return;
    if (!tenant) return;

    const abortController = new AbortController();
    fetchTenantRoles(verificationToken, tenant._id, abortController);
    return () => abortController.abort();

  }, [tenant, verificationToken]);

  React.useEffect(() => {
    if (!tenant) {
      setInitialIndex(TenantOnboardingPage.GetTenantAdminInformation);
      return;
    }

    const authority = getAuthority(tenant.onboardedBy, tenant);

    if (!tenant.onboardedBy?.firstName || !tenant.onboardedBy?.lastName || !authority?.userAuthority?.role || !authority?.userAuthority?.isTenantAdmin) setInitialIndex(TenantOnboardingPage.GetTenantAdminInformation);
    else if (!tenant.address || !tenant.company) setInitialIndex(TenantOnboardingPage.GetCompanyBillingDetails);
    else if (!tenant.subscriptions || !tenant.subscriptions.length) setInitialIndex(TenantOnboardingPage.SelectSubscription);
    else if (!tenant.hasPaymentDetails && stripeClientSecret) setInitialIndex(TenantOnboardingPage.GetCompanyPaymentDetails);
    else if (!tenant.name) setInitialIndex(TenantOnboardingPage.GetTenantNiceName);
    else if (!tenant.domains || !tenant.domains.length || typeof tenant.domains[0] === "string") setInitialIndex(TenantOnboardingPage.CreateTenantDomain);
    else {
      setDomain(tenant.domains[0]);
      setInitialIndex(TenantOnboardingPage.SuccessPage);
    }

  }, [tenant]);

  React.useEffect(() => {
    if (!verificationToken) {
      showModal({
        text: "Bitte geben Sie einen gültigen Verifizierungscode an.",
        type: ModalType.Error
      });

      navigate(AppRoutes.Home.path);
      return;
    }

    const abortController = new AbortController();

    fetchTenantByToken(verificationToken, abortController);

    return () => abortController.abort();

  }, [verificationToken]);

  React.useEffect(() => {
    if (!verificationToken) return;
    if (!tenant) return;
    if (currentIndex !== TenantOnboardingPage.SelectSubscription && initialIndex !== TenantOnboardingPage.SelectSubscription) return;
    if (!!globalSubscriptions && !!globalSubscriptions.length) return;

    const abortController = new AbortController();
    fetchGlobalSubscription(verificationToken, tenant._id, abortController);
    return () => abortController.abort();
  }, [currentIndex, initialIndex, verificationToken, tenant]);

  if (loading) return <PageLoader />
  if (!verificationToken) return <Error message="Bitte geben Sie einen gültigen Code an." />
  if (!tenant) return <Error message="Der angegebene Code ist ungültig und kann mit keiner Registrierung in Verbindung gebracht werden." />

  const actualRoles = getFilteredRoles(roles, r => !r.isClient);

  const getTitle = () => {
    if (!tenant) return "Wilkommen!";
    if (!tenant.onboardedBy) return "Ihre Kanzlei";
    else if (currentIndex < TenantOnboardingPage.SuccessPage) return `Hallo, ${getName(tenant.onboardedBy)}`;
    else return "Geschafft!";
  }

  return (
    <Flex
      fullWidth
      fullHeight
      className="ps-sm-2 pe-sm-2 pe-3 ps-3 pt-5 position-relative gap-sm-5"
      style={{ minHeight: "98vh" }}
    >
      <Flex gap={0} className="mb-sm-5">
        <h1
          className="display-1 fw-bold"
          style={{ color: `var(--${initialIndex === TenantOnboardingPage.SuccessPage ? "success" : "primaryAccent"})` }}
        >
          {getTitle()}
        </h1>
        <h1>Ihr neues Ticketsystem wartet auf Sie!</h1>
      </Flex>
      <MultiStepWizard
        className="h-100"
        initialIndex={initialIndex}
        updateIndex={setCurrentIndex}
      >
        {
          (nextPage, prevPage, index) => {

            const createActions = (formik?: FormikProps<any>) => (
              <Flex row justify="between" fullWidth>
                {
                  (index !== undefined && index > 0)
                    ? <Button onClick={prevPage} icon="arrow-left" text="Zurück" variant="subtle" />
                    : <div />
                }
                <Button
                  icon="arrow-right"
                  onClick={() => formik ? formik.submitForm() : nextPage()}
                  loading={formik?.isSubmitting}
                  text="Weiter"
                />
              </Flex>
            );

            return [
              <MultiStepWizardPage>
                <Formik
                  enableReinitialize
                  initialValues={{
                    firstName: tenant.onboardedBy && tenant.onboardedBy.firstName || "",
                    lastName: tenant.onboardedBy && tenant.onboardedBy.lastName || "",
                    roleId: "",
                    _id: tenant?._id ?? "",
                    name: tenant?.name ?? "",
                    registrationToken: verificationToken,
                    role: undefined
                  } as ITenantOnboardingCreateInitialUserFormValues}
                  onSubmit={async (values) => {
                    if (!values) return;

                    if (!values.role) {
                      showModal({
                        text: "Bitte wählen Sie eine Rolle aus.",
                        type: ModalType.Error
                      });
                      return;
                    }

                    values.roleId = values.role._id;

                    const res = await callApi(tenantsOnboardingCreateManagingUser(values));

                    if (!res) return;

                    nextPage();
                    setTenant(res.data);
                  }}
                >
                  {
                    (formik) => (
                      <Form className="d-flex flex-column w-100 gap-4 position-relative">
                        <h6 className="fw-bold">Bitte stellen Sie sich vor.</h6>
                        <div className="d-flex flex-row align-items-center w-100 gap-3">
                          <FieldWithLabel bold label="Ihr Vorname" name="firstName" className="w-100" />
                          <FieldWithLabel bold label="Ihr Nachname" name="lastName" className="w-100" />
                        </div>
                        <SearchableComboBox
                          useDefaultValue
                          loading={loadingRoles}
                          label="Ihre Rolle im Unternehmen"
                          renderItem={r => <RoleBanner role={r} />}
                          placeholder="Rolle auswählen..."
                          bold
                          className="w-100 mb-3"
                          values={actualRoles || roles || []}
                          onChange={(r) => formik.setFieldValue("role", r)}
                          value={formik.values.role}
                          itemToId={(role: IRole) => role._id}
                          itemToString={(role: IRole) => role.displayName}
                        />
                        {createActions(formik)}
                      </Form>
                    )
                  }
                </Formik>
              </MultiStepWizardPage>,
              <MultiStepWizardPage>
                <Formik
                  enableReinitialize
                  initialValues={{
                    address: {
                      firstLine: tenant?.address?.firstLine ?? "",
                      secondLine: tenant?.address?.secondLine ?? "",
                      postalCode: tenant?.address?.postalCode ?? "",
                      city: tenant?.address?.city ?? "",
                      state: tenant?.address?.state ?? "",
                      country: tenant?.address?.country ?? "Germany",
                      countryCode: tenant?.address?.countryCode ?? "DE"
                    },
                    company: tenant?.company ?? "",
                    registrationToken: verificationToken,
                    _id: tenant._id
                  } as ITenantOnboardingUpdateBillingDataRequest}
                  onSubmit={async (values) => {
                    const res = await callApi(tenantsOnboardingSetBillingData(values));

                    if (!res) return;

                    setTenant(res.data);
                    nextPage();
                  }}
                >
                  {
                    (formik) => (
                      <Flex fullWidth gap="4">
                        <Flex>
                          <h6 className="fw-bold">Bitte teilen Sie uns etwas mehr über Ihre Kanzlei mit.</h6>
                          <Typography wrap>Diese Daten werden als Rechnungsadresse verwendet.</Typography>
                        </Flex>
                        <TenantBillingDetailsForm
                          formik={formik}
                          fromOnboarding
                        />
                      </Flex>
                    )
                  }
                </Formik>
              </MultiStepWizardPage>,
              <MultiStepWizardPage>
                {
                  loadingSubs
                    ? <PageLoader />
                    : (
                      globalSubscriptions && !!globalSubscriptions.length
                        ? (
                          <Formik
                            enableReinitialize
                            initialValues={{
                              planId: globalSubscriptions[0]._id,
                              tenantId: tenant._id,
                              promoCode: "",
                              registrationToken: verificationToken
                            } as ITenantOnboardingAddSubscriptionRequest}
                            onSubmit={async (values) => {
                              const res = await callApi(tenantsOnboardingSelectBaseSubscription(values));

                              if (!res) return;

                              const {
                                tenant,
                                secret
                              } = res.data;

                              setTenant(tenant);
                              setStripeClientSecret(secret);

                              nextPage();
                            }}
                          >
                            {
                              formik => (
                                <Form className="d-flex flex-column h-100 w-100 gap-4 position-relative">
                                  <Flex fullWidth>
                                    <Flex fullWidth gap="0">
                                      <h6 className="fw-bold">Wählen Sie Ihr Abonnement</h6>
                                      <Typography wrap>Wählen Sie das Abonnement, das am besten zu Ihnen passt.</Typography>
                                    </Flex>
                                    <Flex fullWidth row wrap gap="4" className="p-3">
                                      {
                                        globalSubscriptions.map(sub => {

                                          const isActive = getId(formik.values.planId) === getId(sub._id);

                                          return (
                                            <PlanCard
                                              color={
                                                isActive
                                                  ? "secondary"
                                                  : "muted"
                                              }
                                              className={generateClassName({
                                                value: isActive,
                                                onTrue: "onboarding-plan-card-selected",
                                                standard: "onboarding-plan-card"
                                              })}
                                              onClick={() => formik.setFieldValue("planId", sub._id)}
                                              key={sub._id}
                                              plan={sub}
                                            >
                                              <Button
                                                readOnly
                                                color={isActive ? "success" : "muted"}
                                                text={isActive ? "Ausgewählt" : "Auswählen"}
                                                icon={isActive ? "check-circle" : "check"}
                                              />
                                            </PlanCard>
                                          )
                                        })
                                      }
                                    </Flex>
                                  </Flex>
                                  {createActions(formik)}
                                  <Card
                                    color="muted"
                                    fitContent
                                  >
                                    <Typography size="14" color="primary" bold>Gutschein-Code</Typography>
                                    <p>Sichern Sie sich exklusive Rabatte und Vergünstigungen mit einem Gutscheincode.</p>
                                    <FieldWithLabel
                                      bold
                                      label="Ihr Code"
                                      name="promoCode"
                                      placeholder="ABCDEF..."
                                      className="w-100"
                                    />
                                  </Card>
                                </Form>
                              )
                            }
                          </Formik>
                        )
                        : (
                          <Flex fullWidth>
                            <Typography>Aktuell ist kein Abonnement verfügbar. Bitte kontaktieren Sie uns.</Typography>
                            {createActions()}
                          </Flex>
                        )
                    )
                }
              </MultiStepWizardPage>,
              <MultiStepWizardPage>
                {
                  (!hasPaymentError && stripeClientSecret && stripePromise)
                    ? (

                      <Elements stripe={stripePromise} options={{ clientSecret: stripeClientSecret, loader: "auto", appearance: { theme: "stripe" } }} >
                        <OnboardingPaymentDetails
                          clientSecret={stripeClientSecret}
                          nextPage={nextPage}
                          tenantId={tenant._id}
                          registrationToken={verificationToken}
                        />
                      </Elements>
                    )
                    : (
                      <Flex fullWidth>
                        <Typography>Aktuell steht die Abrechnung nicht zur Verfügung. Sie können gerne fortfahren und diesen Schritt später abschließen.</Typography>
                        {createActions()}
                      </Flex>
                    )
                }
              </MultiStepWizardPage>,
              <MultiStepWizardPage>
                <Formik
                  enableReinitialize
                  initialValues={{
                    _id: tenant._id,
                    name: tenant.name || tenant.company || "",
                    registrationToken: verificationToken
                  } as ITenantOnboardingUpdateRequest}
                  onSubmit={async (values, actions) => {
                    if (!values) return;

                    const res = await callApi(tenantsOnboardingUpdate(values));

                    if (!res) return;

                    nextPage();
                  }}
                >
                  {
                    (formik) => (
                      <Form className="d-flex flex-column h-100 w-100 gap-4 position-relative">
                        <h6 className="fw-bold">Bitte geben Sie den Anzeigenamen für Ihr Portal ein.</h6>
                        <p>Dieser Name wird an mehreren Stellen im Portal verwendet, etwa als Absender für E-Mails an Ihre Mandanten oder als Vorschlag für Ihre Domain auf unserem System. Es handelt sich hier lediglich um den Anzeigenamen und er muss daher nicht die Rechtsform Ihrer Kanzlei oder ähnliche Bestandteile enthalten.</p>
                        <FieldWithLabel bold label="Name der Kanzlei" name="name" className="w-100" />
                        {createActions(formik)}
                      </Form>
                    )
                  }
                </Formik>
              </MultiStepWizardPage>,
              <MultiStepWizardPage>
                <Formik
                  initialValues={{
                    subdomain: (tenant.name?.replace(" ", "-").replaceAll("&", "").replaceAll(".", "").replaceAll(" ", "").toLowerCase()) ?? "",
                    _id: tenant._id,
                    registrationToken: verificationToken
                  } as ITenantOnboardingDomainAddRequest}
                  onSubmit={async (values, actions) => {
                    if (!values) return;

                    const res = await callApi(tenantsOnboardingAddToCurrentDomains(values));

                    if (!res) return;

                    setDomain(res.data);
                    nextPage();
                  }}
                >
                  {
                    (formik) => (
                      <Form className="d-flex flex-column h-100 w-100 gap-4 position-relative">
                        <h6 className="fw-bold">Ihr Portal - Ihre Domain!</h6>
                        <p>Um unseren Service für Ihre Mandanten zur Verfügung zu stellen, benötigen Sie eine Domain. Sie erhalten von uns eine Basis-Domain. Mithilfe unseres kinderleichten und vollautomatisierten Authentifizierungsprozess können Sie mit wenigen DNS-Einträgen auf Ihre eigene Subdomain umstellen. Anschließend steht der Service nach wenigen Minuten unter der angegebenen Adresse zur Verfügung.</p>
                        <h6>Ihre Start-Domain</h6>
                        {
                          (!domain || !domain.domain) && <TenantDomainField name="subdomain" values={formik.values} tenant={tenant} saveValue={(v) => formik.setFieldValue("domain", v)} />
                        }
                        {createActions(formik)}
                      </Form>
                    )
                  }
                </Formik>
              </MultiStepWizardPage>,
              <MultiStepWizardPage>
                <div className="w-100 h-100 d-flex flex-column gap-4">
                  <Flex gap="1">
                    <h6 className="fw-bold">Vielen Dank, dass Sie sich für unseren Service entschieden haben.</h6>
                    <Typography wrap>Wir bereiten nun einige Dinge vor. Wir informieren Sie per <strong>E-Mail</strong>, sobald wir fertig sind. Sie können dieses Fenster schließen.</Typography>
                    <Typography size="12" italic wrap>Dieser Vorgang dauert in der Regel bis zu fünfzehn Minuten, kann in Ausnahmefällen aber auch länger dauern.</Typography>
                  </Flex>
                </div>
              </MultiStepWizardPage>
            ]
          }
        }
      </MultiStepWizard>
      <Button
        className="position-absolute mt-5 top-0 end-0"
        variant="subtle"
        color={initialIndex === TenantOnboardingPage.SuccessPage ? "success" : "primary"}
        icon="bell"
        externalLink to={AppRoutes.Feedback.path}
        text="Feedback"
      />
    </Flex>
  )
}

function TenantDomainField({ name, saveValue, values }: { name: string, values: ITenantOnboardingDomainAddRequest, saveValue: (v: string) => void, tenant: ITenant }) {

  const [checkingDomain, setCheckingDomain] = React.useState<boolean>(false);
  const [domainValid, setDomainValid] = React.useState<boolean>(false);

  const callApi = useApi();

  React.useEffect(() => {
    setDomainValid(false);
    setCheckingDomain(true);

    if (!values || !values.subdomain) {
      setCheckingDomain(false);
      setDomainValid(false);
      return;
    }


    const abortController = new AbortController();

    setTimeout(async () => {

      const res = await callApi(tenantsOnboardingValidateTenantSubdomain(values, abortController));

      setDomainValid(!!res);
      setCheckingDomain(false);
    }, 500);

    return () => abortController.abort();

  }, [values]);

  const fieldClass = generateClassName({
    value: domainValid,
    onTrue: "tenant-domain-field-valid",
    standard: "tenant-domain-field-invalid"
  });

  return (
    <div>
      <Flex row>
        <div className="mb-2 d-flex flex-row align-items-center position-absolute end-100" style={{ marginRight: "8px" }}>
          {
            checkingDomain && <LoadingSpinner size={18} />
          }
        </div>
        <FieldWithLabel label="" inputClass={fieldClass} name={name} />
        <span className="fw-bold">.{RootUrl}</span>
      </Flex>
    </div>
  )
}