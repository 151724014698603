import { FieldArray, Form, Formik, FormikProps } from "formik";
import React, { useTransition } from "react";
import { IApiResponse, usersAddAlias, usersCreateNew, usersUpdate, usersUpdateClientAccess, usersUpdateSettings, usersUpdateDefaultTeam } from "../../api/Api";
import { ICreateAliasRequest, IUpdateUserClientsRequest, IUserUpdateDefaultTeamRequest, IUserUpdateSettingsRequest } from "../../api/ApiRequests";
import { AppColor } from "../../app/AppStyles";
import useApi from "../../hooks/useApi";
import { generateClassName } from "../../hooks/useAttributes";
import useTeamsUtil from "../../hooks/useTeamsUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { Locale } from "../../locale/Locale";
import { useClientContacts } from "../../state/api/clientContacts/useClientContacts";
import { useClients } from "../../state/api/clients/useClients";
import { useEmployees } from "../../state/api/employees/useEmployees";
import { useAnyUserAlias } from "../../state/api/user/useAnyUserAlias";
import { useAnyUserClients } from "../../state/api/user/useAnyUserClients";
import { useAnyUserDefaultTeam } from "../../state/api/user/useAnyUserDefaultTeam";
import { useUser } from "../../state/api/user/useUser";
import { useUsers } from "../../state/api/user/useUsers";
import { IPermission, IUser, Permission } from "../../types/ApiTypes";
import { IClient } from "../../types/client.schema";
import AliasForm from "../alias/AliasForm";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import CheckBox from "../forms/CheckBox";
import FieldWithLabel from "../forms/FormikField";
import Icon from "../icons/Icon";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import Typography from "../text/Typography";
import DeleteUserButton from "./DeleteUserButton";
import UserAuthorityFieldArray from "./UserAuthorityFieldArray";
import "./UserUpdateForm.css";
import Select from "../comboBox/Select";
import { Gender } from "../../types/salutation.schema";
import useGenderUtil from "../../hooks/useGenderUtil";
import { UserUpdateSettingsForm } from "./UserSettings";
import FastModalDialog from "../modal/FastModalDialog";
import ModalDialog from "../modal/ModalDialog";

interface IUserUpdateFormProps {
  user?: IUser,
  text?: string,
  title?: string,
  icon?: string,
  color?: AppColor,
  creationBase?: Partial<IUser>,
  isClientContact?: boolean,
  generalFieldsAreDisabled?: boolean,
}

interface IInternalUserUpdateFormProps extends IUserUpdateFormProps {
  getPermission: (key?: keyof IPermission) => Permission,
  isCreate: boolean
}

enum UserUpdateTab {
  General = "general",
  Teams = "teams",
  Clients = "clients",
  Communication = "communication",
  Alias = "alias"
}

export default function UserUpdateForm(props: IUserUpdateFormProps) {

  const {
    user,
    text,
    color,
    icon,
    isClientContact
  } = props;

  const isCreate = !user;

  const getPermission = (key?: keyof IPermission): Permission => {
    const realKey = key ? key : (isCreate ? "create" : "update");
    if (isClientContact) return `users.clientContacts.${realKey}`;
    else return `users.employees.${realKey}`;
  }

  const buttonText = text || (isCreate ? Locale.pages.userManagement.newUser : Locale.pages.userManagement.updateUserButton);
  const buttonColor = color || (isCreate ? "success" : "primary");
  const buttonIcon = icon || (isCreate ? "person-plus" : "pen");


  return (
    <WithPermissions permissions={[getPermission()]}>
      <FastModalDialog
        button={<Button icon={buttonIcon} text={buttonText} color={buttonColor} />}
        dialog={<UserUpdateFormInternal {...props} getPermission={getPermission} isCreate={isCreate} />}
      />
    </WithPermissions>
  )
}


function UserUpdateFormInternal(props: IInternalUserUpdateFormProps) {

  const {
    user,
    creationBase,
    title,
    isCreate,
    isClientContact = false,
    generalFieldsAreDisabled = false,
    getPermission
  } = props;

  const { defaultTeam, reloadDefaultTeam } = useAnyUserDefaultTeam(user ? user._id : "");
  const { userClients, reloadUserClients } = useAnyUserClients(user ? user._id : "");
  const { loadingUserAlias, reloadUserAlias, userAlias } = useAnyUserAlias(user ? user._id : "");

  const [tab, setTab] = React.useState<UserUpdateTab>(UserUpdateTab.General);

  const { getLabelForGender } = useGenderUtil();
  const { clients } = useClients();
  const { reloadClientContacts } = useClientContacts()
  const { reloadEmployees } = useEmployees();
  const { reloadUsers } = useUsers();
  const { user: currentUser, reloadUser } = useUser();
  const { normalizeUser } = useUserUtil();

  const {
    getCurrentTenantTeams
  } = useTeamsUtil();

  const callApi = useApi();

  const getSubmitColor = () => {
    if (isCreate) return "success";
    return "primary";
  }

  const formTitle = title || (isCreate ? Locale.pages.userManagement.addUser : Locale.pages.userManagement.updateUser);
  const realUser = normalizeUser(user || creationBase, true);

  const getContent = (formik: FormikProps<any>) => {

    switch (tab) {
      case UserUpdateTab.General:
        return (
          <Flex className="w-100" gap={3}>
            <Typography color="primary" bold>Allgemein</Typography>
            <Flex row fullWidth>
              <FieldWithLabel readOnly={generalFieldsAreDisabled} className="w-100" label="Titel" name="title" placeholder="z.B. Doktor, Steuerberater, WP, RA..." />
              {
                !isClientContact && <FieldWithLabel readOnly={generalFieldsAreDisabled} className="w-100" label="Prefix für Signatur" name="mailNamePrefix" placeholder="z.B. in Vertretung, i.V., i.A." />
              }
            </Flex>
            <Select
              label="Geschlecht"
              values={Object.values(Gender)}
              onChange={v => formik.setFieldValue("gender", v)}
              getLabel={getLabelForGender}
              value={formik.values.gender}
            />
            <Flex row fullWidth>
              <FieldWithLabel readOnly={generalFieldsAreDisabled} className="w-100" label="Vorname" name="firstName" />
              <FieldWithLabel readOnly={generalFieldsAreDisabled} className="w-100" label="Nachname" name="lastName" />
            </Flex>
            <FieldWithLabel readOnly={generalFieldsAreDisabled} className="w-100" label="E-Mail" name="mailAddress" />
            {
              !generalFieldsAreDisabled && <UserAuthorityFieldArray isCreate={isCreate} />
            }
            {
              !isClientContact && currentUser && currentUser.isSuperAdmin && (
                <div className="d-flex flex-column gap-2 mb-2 mt-3">
                  <Typography color="primary" bold>Sonderrechte</Typography>
                  <Flex row wrap className="w-100">
                    {
                      currentUser?.isSuperAdmin && <CheckBox name="isSuperAdmin" label="Globaler Administrator" />
                    }
                    {
                      currentUser?.isDeveloper && <CheckBox name="isDeveloper" label="Entwickler-Zugriff" />
                    }
                    {
                      currentUser?.isDeveloper && <CheckBox name="isTestingUser" label="Testnutzer (Ignoriert Einstellungen zu Mailversand)" />
                    }
                  </Flex>
                </div>
              )
            }
          </Flex>
        );

      case UserUpdateTab.Communication:

        return <UserUpdateSettingsForm />

      case UserUpdateTab.Teams:

        const availableTeams = getCurrentTenantTeams();

        return (
          <SearchableComboBox
            disabled={!availableTeams?.length}
            values={availableTeams}
            value={formik.values.defaultTeam}
            itemToId={(t) => t._id}
            className="mb-3 w-100"
            itemToString={(t) => t.name}
            label="Standard-Team"
            onChange={(t) => formik.setFieldValue("defaultTeam", t)}
          />
        )

      case UserUpdateTab.Clients:

        const getAvailableClients = () => {
          if (!clients?.length) return [];
          if (!userClients?.clients?.length) return clients;

          return clients.filter(c => !userClients.clients!.find(e => e._id === c._id));
        }

        const availableClients = getAvailableClients();

        const canBeAssignedToMoreClients = availableClients && availableClients.length;

        return (
          <Flex className="w-100">
            <FieldArray name="clients">
              {
                (arrayHelpers) => {
                  return (
                    <div className="user-clients-field-array-container d-flex flex-column align-items-start w-100">
                      <div className="d-flex align-items-start justify-content-between w-100 mb-1">
                        <strong>Zugewiesene Mandanten</strong>
                        <Button
                          text={canBeAssignedToMoreClients ? "Weiterer Mandant" : "Allen Mandanten zugewiesen"}
                          icon="plus"
                          color="primary"
                          disabled={!canBeAssignedToMoreClients}
                          onClick={async () => arrayHelpers.push({
                            name: ""
                          } as IClient)}
                        />
                      </div>
                      <div className="user-clients-field-array d-flex flex-column gap-2 w-100">
                        {
                          formik.values.clients && formik.values.clients.length
                            ? formik.values.clients.map((c: IClient, index: number) => (
                              <UserAssignedClient
                                key={c._id || `user-authority-clients-field-${index}`}
                                client={c}
                                clientIndex={index}
                                clients={availableClients}
                                setAsDefault={() => formik.setFieldValue("defaultClient", c)}
                                defaultClient={formik.values.defaultClient}
                                deleteValue={() => arrayHelpers.remove(index)}
                                saveValue={(c) => formik.setFieldValue(`clients.[${index}]`, c)}
                              />
                            ))
                            : <em>Kein Mandant zugewiesen.</em>
                        }
                      </div>
                    </div>
                  )
                }
              }
            </FieldArray>
          </Flex>
        );

      case UserUpdateTab.Alias: return <AliasForm data={userAlias} mutate={reloadUserAlias} isLoading={loadingUserAlias} />;
    }
  }

  if (!realUser && !isCreate) return null;

  return (
    <ModalDialog
      {...props}
      title={formTitle}
    >
      {
        close => (
          <Formik
            initialValues={{
              ...realUser,
              clients: userClients ? userClients.clients : [],
              defaultTeam: defaultTeam ? defaultTeam.defaultTeam : null,
              defaultClient: userClients ? userClients.defaultClient : undefined,
              id: realUser._id,
              aliasMailAddress: ""
            }}
            onSubmit={async (values) => {
              const getApiCall = (): Promise<IApiResponse> => {
                switch (tab) {
                  case UserUpdateTab.General: return isCreate ? usersCreateNew(values) : usersUpdate(values);
                  case UserUpdateTab.Teams: return usersUpdateDefaultTeam(values as IUserUpdateDefaultTeamRequest);
                  case UserUpdateTab.Clients: return usersUpdateClientAccess(values as IUpdateUserClientsRequest);
                  case UserUpdateTab.Alias: return usersAddAlias(values as ICreateAliasRequest);
                  case UserUpdateTab.Communication: return usersUpdateSettings({
                    ...values.settings,
                    id: values._id
                  } as IUserUpdateSettingsRequest);
                }

              }

              const result = await callApi(getApiCall());

              if (!result?.success) return false;

              await Promise.all([
                reloadUsers(),
                reloadEmployees(),
                reloadClientContacts(),
                reloadDefaultTeam(),
                reloadUserClients(),
                reloadUserAlias(),
                reloadUser()
              ]);

              return true;
            }}
          >
            {
              formik => (
                <Form className="w-100 d-flex flex-column gap-4">
                  {
                    !isCreate && (
                      <TabSwitcher
                        initialData={UserUpdateTab.General}
                        saveActiveTab={(t) => setTab(t as UserUpdateTab)}
                        tabs={[
                          { data: UserUpdateTab.General, label: "Allgemein" },
                          { data: UserUpdateTab.Communication, label: "Kommunikation" },
                          { data: UserUpdateTab.Teams, label: "Teams", hidden: isClientContact, permissions: ["teams.all.update"] },
                          { data: UserUpdateTab.Clients, label: "Mandantenzugriff", hidden: !isClientContact, permissions: ["employeeResponsibilities.all.update"] },
                          { data: UserUpdateTab.Alias, label: "Alias", hidden: !isClientContact, permissions: ["alias.all.update"] }
                        ]}
                      />
                    )
                  }
                  {getContent(formik)}
                  <Flex fullWidth row justify="between" align="center">
                    {
                      (!isCreate && user && tab === UserUpdateTab.General) && (
                        <WithPermissions permissions={[getPermission("delete")]}>
                          <DeleteUserButton userId={user._id} afterDelete={() => {
                            close();
                            reloadUsers();
                          }} />
                        </WithPermissions>
                      )
                    }
                    <Button
                      loading={formik.isSubmitting}
                      icon="save"
                      loadingText="Bitte warten..."
                      type="submit"
                      text="Speichern"
                      className="ms-auto"
                      color={getSubmitColor()}
                    />
                  </Flex>
                </Form>
              )
            }
          </Formik>
        )
      }
    </ModalDialog >
  )
}


interface IUserAssignedClientProps {
  client: IClient,
  defaultClient?: IClient,
  deleteValue: () => void,
  setAsDefault: () => void,
  saveValue: (c?: IClient) => void,
  clients: IClient[],
  clientIndex: number
}

function UserAssignedClient({ client, setAsDefault, deleteValue, saveValue, defaultClient, clients, clientIndex }: IUserAssignedClientProps) {

  const isDefaultClient = (defaultClient && defaultClient._id === client._id);
  const defaultClientIcon = isDefaultClient ? "star-fill" : "star";

  const className = generateClassName("d-flex p-2 user-clients-field-array-client flex-row align-items-center w-100 gap-2", {
    value: isDefaultClient,
    onTrue: "user-clients-field-array-client-favorite"
  });

  return (
    <div className={className} key={client._id || `user-clients-field-array-item-${clientIndex}`}>
      <Icon onClick={setAsDefault} icon={defaultClientIcon} className="user-clients-field-array-favorite-icon" size={16} />
      <SearchableComboBox
        placeholder="Mandant auswählen..."
        values={clients}
        className="w-100"
        onChange={saveValue}
        value={client}
        itemToId={(c: IClient) => c._id}
        itemToString={(c: IClient) => c.name}
      />
      <Icon onClick={deleteValue} icon="x" size={16} />
    </div>
  )
}