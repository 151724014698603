import React from "react";
import { IMailLog, MailLogType } from "../../../types/ApiTypes";
import { formatDateTiggiStyle, formatMailAddress } from "../../../util/formatter";
import Card from "../../card/Card";
import Flex from "../../container/Flex";
import Typography from "../../text/Typography";
import MailLogSourceBanner from "./MailLogSourceBanner";
import MailLogTypeBanner from "./MailLogTypeBanner";
import MailRejectReasonBanner from "./MailRejectReasonBanner";
import Divider from "../../menu/sidebar/Divider";

export default function MailLogCard({ item }: { item: IMailLog }) {

  return (
    <Card
      uniColor
      color={item.type === MailLogType.Incoming ? "backgroundDarker" : "backgroundLightest"}
      noPadding
    >
      <Flex fullWidth className="p-2">
        <Flex fullWidth gap="0" >
          {
            item.createdAt && <Typography size="12" color="primary">{formatDateTiggiStyle(item.createdAt)}</Typography>
          }
          {
            item.subject && (
              <Typography className="w-100" bold truncate color="primary">{item.subject}</Typography>
            )
          }
        </Flex>
        <Divider height="1" color="primary" opacity={0.1} />
        <Flex gap={0}>
          <Typography wrap color="primary" size="12" className="w-100">
            <strong>Von</strong><br />
            {formatMailAddress(item.fromName, item.fromMail)}
          </Typography>
          <Typography wrap color="primary" size="12" className="w-100">
            <strong>An</strong><br />
            {item.toMail}
          </Typography>
          {
            !!item.cc?.length && (
              <Typography wrap color="primary" size="12" className="w-100">
                <strong>CC</strong><br />
                {item.cc.join(",")}
              </Typography>
            )
          }
        </Flex>
        <Divider height="1" color="primary" opacity={0.1} />
        <Flex row>
          <MailLogTypeBanner type={item.type} />
          <MailLogSourceBanner source={item.source} />
          <MailRejectReasonBanner reason={item.rejectReason} />
        </Flex>
      </Flex>
    </Card >
  )
}