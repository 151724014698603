import { useSearchParams } from "react-router-dom";

export default function useFilterQuery() {

  const [query, setQuery] = useSearchParams();

  const updateQueryFromFilter = (filter: { [key: string]: any } | undefined) => {
    try {
      if (!filter) return;

      const values = Object.entries(filter).filter(a => !!a[1]);

      if (!values?.length) return;

      const existingQuery = Object.fromEntries(query.entries());

      const mergedQuery = { ...existingQuery, ...filter };

      const result: { [key: string]: any } = {};

      Object.keys(mergedQuery).forEach(key => {
        const value = mergedQuery[key];
        if (value) result[key] = getQueryValue(value);
      });

      setQuery(result, { replace: true });
    }
    catch (err) { }
  }

  const getValueFromQuery = <T>(key: string, defaultValue: T): T => {
    const v = query.get(key);
    if (!v) return defaultValue;
    return v as T;
  }

  const getQueryValue = (v: unknown): string | null => {
    if (v === undefined || v === null) return null;
    if (v instanceof Date) return v.toISOString();
    return v.toString();
  }

  const getUrlWithFilter = <T extends { [key: string]: any }>(endpoint: string | null, filter: T | undefined): string => {
    try {
      if (!endpoint) return "";
      if (!filter) return endpoint;

      var url = new URL(endpoint, "https://localhost");

      let hasQuery = false;

      Object.keys(filter).forEach(key => {
        const value = filter[key];
        const v = getQueryValue(value);

        if (v) {
          url.searchParams.set(key, value.toString());
          hasQuery = true
        }
      });

      if (!hasQuery) return endpoint;

      const result = `${url.pathname}?${url.searchParams.toString()}`

      return result;
    }
    catch (err) { }

    return "";
  }

  return {
    query,
    getUrlWithFilter,
    getValueFromQuery,
    updateQueryFromFilter
  }
}