import React from "react";
import { IApiResponse } from "../../api/Api";
import { AppColor } from "../../app/AppStyles";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";

export interface ITestBtnProps {
  apiCall: () => Promise<IApiResponse<any>>,
  text: string,
  icon: string,
  color: AppColor
}

export default function TestBtn(props: ITestBtnProps) {
  const {
    apiCall,
    color,
    icon,
    text
  } = props;

  const callApi = useApi();

  const click = async () => {
    const res = await callApi(apiCall(), true);
    console.log("TEST RESULT for " + text, res);
  }

  return (
    <Button
      color={color}
      onClick={click}
      icon={icon}
      text={text}
    />
  )
}