import React from "react";
import Page from "../../components/container/Page";
import PermissionManagement from "../../components/permissions/PermissionManagement";
import UpdateRoleForm from "../../components/permissions/UpdateRoleForm";

export default function RoleManagement() {
  return (
    <Page showBackButton header="Rollenverwaltung" actionButtons={[<UpdateRoleForm />]}>
      <PermissionManagement />
    </Page>
  )
}