import React from "react";
import { useSearchParams } from "react-router-dom";
import { SortDirection } from "../components/buttons/SortDirectionToggle";
import { SelectItem } from "../components/comboBox/Select";
import { ITabData } from "../components/tabswitcher/TabSwitcher";
import { useTeams } from "../state/api/teams/useTeams";
import { useCurrentTenantRole } from "../state/api/user/useCurrentTenantRole";
import { useUser } from "../state/api/user/useUser";
import { UserRole } from "../types/ApiTypes";
import useFilterQuery from "./useFilterQuery";
import { IQueryCursor } from "../types/query.schema";

export enum TicketSort {
  CreatedAt = "created",
  UpdatedAt = "updated",
  Status = "status",
  Subject = "subject",
  Type = "type"
}

export enum TicketSubcategory {
  All = "all",
  WaitingForClient = "waitingForClient",
  ClosedPastWeek = "closedPastWeek",
  Escalated = "escalated",
  CarbonCopy = "carbonCopy",
  Unanswered = "unanswered",
  Intern = "internal",
  External = "external",
  Resubmission = "resubmission"
}

export enum TicketNotAssignedType {
  Assignee = "assignee",
  ClientContact = "clientContact",
  Client = "client",
  Team = "team"
}

export enum TicketFilter {
  NotAssigned = "na",
  ForMe = "mine",
  MyTeam = "team",
  All = "all",
  Closed = "closed",
  Archive = "archived"
}

export interface ITicketFilter extends IQueryCursor {
  show?: TicketFilter,
  client?: string,
  subcategory?: TicketSubcategory,
  team?: string,
  text?: string,
  assignee?: string,
  sort?: TicketSort,
  notAssignedType?: TicketNotAssignedType,
  direction?: SortDirection
}

export interface ITicketFilterUpdate extends ITicketFilter {
  view?: TicketView,
}

export enum TicketView {
  List = "list",
  Grid = "grid"
}

export default function useFilteredTickets() {

  const [notAssignedType, setNotAssignedType] = React.useState<TicketNotAssignedType>(TicketNotAssignedType.Assignee);
  const [subcategory, setSubcategory] = React.useState<TicketSubcategory>(TicketSubcategory.All);
  const [view, setView] = React.useState<TicketView>(TicketView.Grid);
  const [team, setTeam] = React.useState<string>();
  const [client, setClient] = React.useState<string>();
  const [assignee, setAssignee] = React.useState<string>();
  const [filterText, setFilterText] = React.useState<string>("");
  const [sort, setSort] = React.useState<TicketSort | undefined>(TicketSort.UpdatedAt)
  const [direction, setDirection] = React.useState<SortDirection>(SortDirection.Descending);
  const [filter, setFilter] = React.useState<TicketFilter>(TicketFilter.ForMe);

  const { 
    query, 
    updateQueryFromFilter, 
    getValueFromQuery: qv 
  } = useFilterQuery();

  const { user } = useUser();
  const { role } = useCurrentTenantRole();

  const isClient = !!role && role.isClient;

  React.useEffect(() => {
    setSubcategory(qv<TicketSubcategory>("subcategory", TicketSubcategory.All));
    setFilter(qv<TicketFilter>("show", TicketFilter.ForMe));
    setSort(qv<TicketSort>("sort", TicketSort.UpdatedAt));
    setDirection(qv<SortDirection>("direction", SortDirection.Descending));
    setNotAssignedType(qv<TicketNotAssignedType>("notAssignedType", TicketNotAssignedType.Assignee));
    setFilterText(qv<string>("text", ""));
    setAssignee(qv<string>("assignee", ""));
    setTeam(qv<string>("team", ""));
    setView(qv<TicketView>("view", TicketView.Grid));
    setClient(qv<string>("client", ""));

  }, [query]);

  const getFilterBase = (): ITicketFilterUpdate => ({
    direction,
    show: filter,
    assignee,
    client,
    team,
    sort,
    text: filterText,
    notAssignedType,
    view,
    subcategory
  });

  const updateFilter = (update: ITicketFilterUpdate) => {
    const u = {
      ...getFilterBase(),
      ...update
    }

    updateQueryFromFilter(u);
  }

  const getSubcategoryTab = (): Array<ITabData> => {

    if (role && role.isClient) return [];

    const result: Array<ITabData> = [];

    result.push({ data: TicketSubcategory.All, label: "Alle" });

    if (filter !== TicketFilter.Closed) {
      result.push({ data: TicketSubcategory.Unanswered, label: "Unbeantwortet", permissions: ["statistics.own.read"] });
      result.push({ data: TicketSubcategory.WaitingForClient, label: "Auf Mandant warten", permissions: ["statistics.own.read"] });
      result.push({ data: TicketSubcategory.Escalated, label: "Eskaliert", permissions: ["statistics.own.read"] });
      result.push({ data: TicketSubcategory.CarbonCopy, label: "CC", permissions: ["statistics.own.read"] });

      if (filter !== TicketFilter.ForMe) {
        result.push({ data: TicketSubcategory.External, label: "Extern", permissions: ["tickets.external.read"] });
        result.push({ data: TicketSubcategory.Intern, label: "Intern", permissions: ["tickets.internal.read"] });
      }

      result.push({ data: TicketSubcategory.Resubmission, label: "In Wiedervorlage", permissions: ["statistics.own.read"] });
    }

    switch (filter) {
      case TicketFilter.Closed:

        result.push({ data: TicketSubcategory.ClosedPastWeek, label: "Letzte 7 Tage", permissions: ["statistics.own.read"] });
    }

    return result;
  }

  const getFilterTabs = (): Array<ITabData> => {
    const result: Array<ITabData> = [];

    result.push({ data: TicketFilter.Closed, label: "Geschlossen", priority: 2 });
    result.push({ data: TicketFilter.Archive, hidden: isClient, label: "Archiv", priority: 2 });
    result.push({ data: TicketFilter.NotAssigned, permissions: ["tickets.unassigned.read"], label: "Ohne Zuweisung", first: !isClient, priority: 1, hidden: !user?.isSuperAdmin && (!role || (role.displayName === UserRole.Clerk || role.displayName === UserRole.Client)) });
    result.push({ data: TicketFilter.ForMe, first: true, priority: 1, label: isClient ? "Offen" : "Für Mich" });
    result.push({ data: TicketFilter.MyTeam, hidden: isClient, priority: 1, label: "Team" });
    result.push({ data: TicketFilter.All, label: "Alle", hidden: isClient, priority: 1 });

    return result;
  }

  const getNotAssignedTypeTabs = (): Array<ITabData> => {
    const result: Array<ITabData> = [];

    result.push({ data: TicketNotAssignedType.Assignee, permissions: ["tickets.unassigned.read"], label: "Kein Bearbeiter" });
    result.push({ data: TicketNotAssignedType.ClientContact, permissions: ["tickets.unassigned.read"], label: "Kein Mandantenkontakt" });
    result.push({ data: TicketNotAssignedType.Client, permissions: ["tickets.unassigned.read"], label: "Kein Mandant" });
    result.push({ data: TicketNotAssignedType.Team, permissions: ["tickets.unassigned.read"], label: "Kein Team" });

    return result;
  }

  const getViewTabs = (): Array<ITabData> => [
    {
      icon: "grid-fill",
      data: TicketView.Grid
    },
    {
      icon: "list",
      data: TicketView.List,
      iconSize: 28
    }
  ]

  const getSortOptions = (): Array<SelectItem<string>> => {
    const result: Array<SelectItem<string>> = [];

    if (!isClient) result.push({ label: "Typ", data: TicketSort.Type });

    result.push({ label: "Erstellt am", data: TicketSort.CreatedAt });
    result.push({ label: "Status", data: TicketSort.Status });
    result.push({ label: "Betreff", data: TicketSort.Subject });
    result.push({ label: "Aktualisiert am", data: TicketSort.UpdatedAt });

    return result;
  }

  const getFilterForRoute = (options?: ITicketFilter): ITicketFilter => {

    const result: ITicketFilter = {
      ...getFilterBase(),
      limit: options?.limit ?? 100,
      skip: options?.skip ?? 0,
    }

    if (!options || typeof options !== "object") return result;

    return {
      ...result,
      ...options
    };
  }

  return {
    subcategory,
    getSubcategoryTab,
    getSortOptions,
    filter,
    client,
    sort,
    assignee,
    team,
    view,
    filterText,
    getViewTabs,
    direction,
    updateFilter,
    getFilterTabs,
    getNotAssignedTypeTabs,
    query,
    isShowingClosedTickets: filter === TicketFilter.Closed || filter === TicketFilter.Archive,
    getFilterForRoute
  }
}
