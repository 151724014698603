import React from "react";
import useLabels, { Label } from "../../hooks/useLabels";
import useTenantSettings from "../../hooks/useTenantSettings";
import { useTeams } from "../../state/api/teams/useTeams";
import { useAnyTeamTicketStatistics } from "../../state/api/tickets/statistics/useAnyTeamTicketStatistics";
import { useAnyUserTicketStatistics } from "../../state/api/tickets/statistics/useAnyUserTicketStatistics";
import { useUser } from "../../state/api/user/useUser";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import WithPermissions from "../permissions/WithPermissions";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import Typography from "../text/Typography";
import UserSelect from "../user/UserSelect";
import "./ProductivityOverview.css";
import ResetStatisticsButton from "./ResetStatisticsButton";
import ResponseTimeCard from "./ResponseTimeCard";
import TeamProductivityOverview from "./views/TeamProductivityOverview";
import TenantProductivityOverview from "./views/TenantProductivityOverview";
import UserProductivityOverview from "./views/UserProductivityOverview";

export enum TicketProductivityTab {
  Team = "team",
  Personal = "personal",
  Tenant = "tenant"
}

export default function ProductivityOverview() {

  const [teamId, setTeamId] = React.useState<string>();
  const [userId, setUserId] = React.useState<string>();

  const [tab, setTab] = React.useState<string>(TicketProductivityTab.Personal);

  const { tickets } = useTenantSettings(true);

  const getLabel = useLabels();

  const { user } = useUser();
  const { teams } = useTeams();

  React.useEffect(() => {
    if (!teams || !teams.length) return;
    if (teamId) return;

    setTeamId(teams[0]._id);
  }, [teams]);

  const { loadingUserTicketStatistics, userTicketStatistics } = useAnyUserTicketStatistics(userId || user?._id || "");
  const { loadingTeamTicketStatistics, teamTicketStatistics } = useAnyTeamTicketStatistics(teamId || "");

  const isPerformingOverRequirement = (val: number) => {
    const requirement = tickets?.maxMinutesToFirstResponse || 0;
    return val < requirement;
  }

  const getFilterComponent = () => {
    switch (tab) {
      case TicketProductivityTab.Team: return (
        <SearchableComboBox
          values={teams || []}
          itemToId={t => t._id}
          itemToString={t => t.name}
          value={teamId}
          onChange={(team) => setTeamId(team?._id)}
        />
      )

      case TicketProductivityTab.Personal: return (
        <WithPermissions permissions={["statistics.tenant.read"]}>
          <UserSelect
            useDefaultValue={false}
            placeholder="Mitarbeiter suchen..."
            className="text-right"
            onChange={u => {
              if (!u) setUserId(user?._id)
              else setUserId(u._id);
            }}
            displayed="employees"
            value={userId}
          />
        </WithPermissions>
      )
    }
  }

  return (
    <Flex className="w-100 mb-5" gap={4}>
      <Flex className="w-100">
        <Typography color="primary" className="w-100" bold size={24}>Dein Cockpit</Typography>
        <Flex gap="0">
          <Typography color="primary" size={20} >Antwortzeit erste Nachricht</Typography>
          <Typography color="primary" size={12} >Rollierender Durchschnitt der letzten 14 Tage</Typography>
        </Flex>
        <div className="response-times-grid w-100">
          <WithPermissions permissions={["statistics.own.read"]}>
            <ResponseTimeCard
              title="Du"
              icon="person-fill"
              color={isPerformingOverRequirement(userTicketStatistics?.durations?.minutesUntilFirstMessage || 0) ? "success" : "error"}
              value={userTicketStatistics?.durations?.minutesUntilFirstMessage || 0}
              loading={loadingUserTicketStatistics}
            />
          </WithPermissions>
          <WithPermissions permissions={["statistics.team.read"]}>
            <ResponseTimeCard
              title="Dein Team"
              icon="people-fill"
              color={isPerformingOverRequirement(teamTicketStatistics?.durations?.minutesUntilFirstMessage || 0) ? "success" : "error"}
              value={teamTicketStatistics?.durations?.minutesUntilFirstMessage || 0}
              loading={loadingTeamTicketStatistics}
            />
          </WithPermissions>
          <ResponseTimeCard title="Vorgabe" icon="arrow-up-right" color="muted" value={tickets?.maxMinutesToFirstResponse || 0} loading={false} />
        </div>
      </Flex>
      <Flex fullWidth>
        <Flex gap="0">
          <Typography color="primary" size="20">Tickets in Zahlen</Typography>
          <Typography color="primary" size="12">Aktueller Stand</Typography>
        </Flex>
        <Flex row fullWidth justify="between">
          <TabSwitcher
            tabQueryParamKey="view"
            size="small"
            saveActiveTab={(tab) => setTab(tab as string)}
            tabs={[
              { data: TicketProductivityTab.Personal, label: "Du", permissions: ["statistics.own.read"] },
              { data: TicketProductivityTab.Team, label: "Team", permissions: ["statistics.team.read"] },
              { data: TicketProductivityTab.Tenant, label: getLabel(Label.TenantName), permissions: ["statistics.tenant.read"] }
            ]}
          />
          <Flex row>
            {getFilterComponent()}
            <ResetStatisticsButton
              all={tab === TicketProductivityTab.Tenant}
              team={tab === TicketProductivityTab.Team ? teamId : undefined}
              user={tab === TicketProductivityTab.Personal ? userId : undefined}
            />
          </Flex>
        </Flex>
        {
          (() => {
            switch (tab) {
              case TicketProductivityTab.Personal: return <UserProductivityOverview userId={userId ?? user?._id ?? ""} />;
              case TicketProductivityTab.Team: return <TeamProductivityOverview teamId={teamId} />;
              case TicketProductivityTab.Tenant: return <TenantProductivityOverview />
            }
          })()
        }
      </Flex>
    </Flex>
  )
}
