import React from "react";
import { tenantsAddNew } from "../../api/Api";
import { ITenantCreateRequest } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import useLabels, { Label } from "../../hooks/useLabels";
import { useTenants } from "../../state/api/tenant/useTenants";
import { useUser } from "../../state/api/user/useUser";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import CheckBox from "../forms/CheckBox";
import FieldWithLabel from "../forms/FormikField";
import ModalForm from "../modal/ModalForm";

export default function TenantCreateForm() {

  const {
    user
  } = useUser();

  const { reloadTenants } = useTenants();

  const getLabel = useLabels();

  const callApi = useApi();

  if (!user?.isSuperAdmin) return null;

  return (
    <ModalForm
      title={`${getLabel(Label.TenantName)} hinzufügen`}
      button={<Button icon="plus-circle" text={`${getLabel(Label.TenantName)} hinzufügen`} color="success" />}
      initialValues={{
        mailAddress: "",
        onboardingIsManaged: false,
        isDebugResource: false
      } as ITenantCreateRequest}
      onSubmit={async (values, actions) => {
        const result = await callApi(tenantsAddNew(values), true);
        if (!result) return false;
        await reloadTenants();
        return true;
      }}
    >
      {
        (formik) => (
          <Flex className="w-100">
            <FieldWithLabel
              label="E-Mail für den Einladungslink"
              placeholder={`E-Mail des ${getLabel(Label.TenantName)}-Admins...`}
              name="mailAddress"
              className="w-100"
            />
            <CheckBox
              label={`Onboarding für ${getLabel(Label.TenantName)} wird von aktuellem Nutzer durchgeführt`}
              name="onboardingIsManaged"
            />
            <CheckBox
              name="isDebugResource"
              label="Test-Ressource (Keine Abrechnung und einige Einschränkungen, gut für Demos)"
            />
          </Flex>
        )
      }
    </ModalForm>
  )
}