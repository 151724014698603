import React from "react";
import { useAnyUserAbsence } from "../../../state/api/user/useAnyUserAbsence";
import { useUser } from "../../../state/api/user/useUser";
import { AbsenceType, IUser } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import Card from "../../card/Card";
import Expandable from "../../comboBox/Expandable";
import Flex from "../../container/Flex";
import LoadingSpinner from "../../loader/LoadingSpinner";
import WithPermissions from "../../permissions/WithPermissions";
import Table from "../../tables/Table";
import CreateUserAbsenceButton from "./CreateUserAbsenceButton";
import UserAbsenceListItem from "./UserAbsenceListItem";

export interface IUserAbsenceListProps {
  user: IUser
}

export default function UserAbsenceList({ user }: IUserAbsenceListProps) {

  const {
    user: currentUser
  } = useUser();

  const { loadingUserAbsence, userAbsence } = useAnyUserAbsence(user);
  const isOwn = currentUser && currentUser._id === user._id;

  if (loadingUserAbsence) return <LoadingSpinner />

  return (
    <WithPermissions permissions={isOwn ? ["absence.own.read"] : ["absence.all.read"]}>
      <Flex row justify="between" fullWidth>
        <h6 className="m-0 p-0 fw-bold">Urlaube</h6>
        <Expandable
          expander={<Button text="Neues Element" icon="plus" color="success" />}
        >
          <Flex className="p-2" fullWidth>
            <CreateUserAbsenceButton buttonClass="w-100" user={user} type={AbsenceType.Vacation} text="Neuen Urlaub buchen" title="Neuer Urlaub" />
            <CreateUserAbsenceButton buttonClass="w-100" user={user} type={AbsenceType.Sick} text="Neue Krankmeldung" title="Neue Krankmeldung" />
          </Flex>
        </Expandable>
      </Flex>
      <Table
        headers={["Von", "Bis", "Vertretung", "Erstellt von", "Typ", "Quelle", ""]}
        items={userAbsence ?? []}
        renderItem={v => <UserAbsenceListItem key={v._id} absence={v} user={user} />}
      />
    </WithPermissions>
  )
}