import { Form, Formik } from "formik";
import React, { useState } from "react";
import { usersRequestNewVerificationCode, usersVerifyLogIn } from "../../../api/Api";
import { ISessionVerificationRequest } from "../../../api/ApiRequests";
import Button from "../../../components/buttons/Button";
import Flex from "../../../components/container/Flex";
import OtpInput from "../../../components/forms/OtpInput";
import ResetSelectedVerificationMethodButton from "../../../components/user/session/ResetSelectedVerificationMethodButton";
import { ModalType } from "../../../config/ModalTypes";
import useApi from "../../../hooks/useApi";
import useModal from "../../../hooks/useModal";
import { Locale } from "../../../locale/Locale";
import { useSession } from "../../../state/api/session/useSession";
import LogInPage from "./LogInPage";
import "./VerifyLogIn.css";

export default function VerifyLogIn() {
  const [canRequestNewCode, setCanRequestNewCode] = React.useState<boolean>(true);
  const [canRequestNewCodeTimer, setCanRequestNewCodeTimer] = React.useState<any>();
  const [fetchingNewAccessCode, setFetchingNewAccessCode] = useState(false);

  const showModal = useModal();
  const callApi = useApi();

  const { reloadSession } = useSession();

  const fetchNewAccessCode = async (abortController?: AbortController) => {
    clearTimeout(canRequestNewCodeTimer);
    setFetchingNewAccessCode(true);
    setCanRequestNewCode(false);
    await callApi(usersRequestNewVerificationCode(abortController));
    const newCodeTimeout = setTimeout(() => setCanRequestNewCode(true), 2000);
    setCanRequestNewCodeTimer(newCodeTimeout);
    setFetchingNewAccessCode(false);
  }

  return (
    <LogInPage description="Bitte geben Sie den erhaltenen Code ein.">
      <Formik
        initialValues={{
          otp: ""
        } as ISessionVerificationRequest}
        onSubmit={async (values, actions) => {
          if (!values) return;

          if (!values.otp) {
            showModal({
              type: ModalType.Error,
              text: Locale.errors.logInVerification.noOtp
            });
            return;
          }

          const res = await usersVerifyLogIn(values);

          if (!res || !res.success) {
            showModal({
              type: ModalType.Error,
              text: Locale.errors.logInVerification.otpVerificationFailed
            });
            return;
          }

          await reloadSession();
        }}
      >
        {
          formik => (
            <Form className="d-flex flex-column gap-2 align-items-center" style={{ width: "fit-content", maxWidth: "500px" }}>
              <Flex row justify="between" fullWidth>
                <OtpInput
                  saveOtp={(otp) => {
                    formik.setFieldValue("otp", otp);
                    if (otp?.length === 6) formik.submitForm();
                  }}
                  readOnly={formik.isSubmitting}
                  text="Bitte geben Sie den Code ein, welchen wir Ihnen zugesendet haben."
                />
              </Flex>
              <Flex row align="start" justify="between" fullWidth>
                <Flex>
                  <Button
                    color="secondary"
                    disabled={!canRequestNewCode}
                    disabledText="Bitte warten..."
                    icon="arrow-clockwise"
                    onClick={async () => await fetchNewAccessCode()}
                    loading={fetchingNewAccessCode}
                    loadingText="Code wird gesendet"
                    text="Neuer Code"
                  />
                  <ResetSelectedVerificationMethodButton />
                </Flex>
                <Button type="submit" icon="door-open" preventFloatEndOnSubmit loading={formik.isSubmitting} color="bright" text="Einloggen" loadingText="Code wird überprüft" />
              </Flex>
            </Form>
          )
        }
      </Formik>
    </LogInPage>
  )
}