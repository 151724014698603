import React from "react";
import { useSearchParams } from "react-router-dom";
import useFilterQuery from "./useFilterQuery";

export default function useQueryBasedFilter<T extends Record<string, any>>(defaultValue: T) {

  const [value, setValue] = React.useState<T>(defaultValue);
  const { updateQueryFromFilter } = useFilterQuery();

  const [query] = useSearchParams();

  React.useEffect(() => {
    if (!query) return;

    let update: Record<string, any> = { ...defaultValue };

    for (const [key, value] of Object.entries(query)) {
      if (!(key in defaultValue)) continue;
      update[key] = value;
    }

    setValue(update as T);
  }, []);

  const updateFilter = (f: Partial<T>) => {

    const u = { ...value, ...f };

    setValue(u);
    updateQueryFromFilter(u);
  }

  return {
    filter: value,
    updateFilter
  }
}
