import React from "react";
import { generateClassName } from "../../hooks/useAttributes";
import IElementProps from "../../types/element.types";
import PageIndicator from "../pagination/PageIndicator";
import Typography from "../text/Typography";

interface IPaginatedProps<T> extends IElementProps {
  renderItem: (item: T) => React.ReactNode,
  itemShouldRender?: (item: T) => boolean,
  items: Array<T>,
  itemsPerPage?: number,
  responsive?: boolean,
}

export default function Paginated<T>(props: IPaginatedProps<T>) {

  const {
    itemsPerPage = 15,
    items,
    renderItem,
    className,
    itemShouldRender,
    responsive
  } = props;

  const [elementsPerPage, setElementsPerPage] = React.useState<number>(itemsPerPage);
  const [page, setPage] = React.useState<number>(0);

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const sizerTimeout = React.useRef<any>(null);

  React.useEffect(() => {
    if (!responsive) return;

    clearTimeout(sizerTimeout.current);

    sizerTimeout.current = setTimeout(() => {
      const availableHeight = wrapperRef.current?.clientHeight || 0;
      const tableHeight = containerRef.current?.clientHeight || 0;

      if (!availableHeight || !tableHeight) return;

      let scale = availableHeight / tableHeight;

      if ((tableHeight * scale) > window.innerHeight) {
        scale = window.innerHeight / tableHeight - 0.1;
      }

      const items = Math.floor(elementsPerPage * scale);

      setElementsPerPage(items < 1 ? 1 : items);

    }, 50);

    return () => clearTimeout(sizerTimeout.current);
  }, [wrapperRef.current, containerRef.current, responsive]);

  if (!items?.length) return <Typography>Keine Ergebnisse</Typography>

  const pages = Math.ceil(items.length / elementsPerPage);
  const start = page * elementsPerPage;

  const visible = items.slice(start, start + elementsPerPage);

  const wrapperClass = generateClassName("w-100 d-flex flex-column gap-3 overflow-auto", className, {
    value: responsive,
    onTrue: "h-100"
  });

  return (
    <>
      <div className={wrapperClass} ref={wrapperRef}>
        <div className="w-100 d-flex flex-column gap-2" ref={containerRef}>
          {
            visible.map(i => {
              if (itemShouldRender && !itemShouldRender(i)) return null;
              return renderItem(i)
            })
          }
        </div >
        <PageIndicator currentPage={page} onPageChange={setPage} totalPages={pages} />
      </div>
    </>
  )
}