import React from "react";
import { generateStyle } from "../../../hooks/useAttributes";
import { useTicketMessages } from "../../../state/api/tickets/messages/useTicketMessages";
import { useUser } from "../../../state/api/user/useUser";
import { IAttachment, ITicket } from "../../../types/ApiTypes";
import { ITicketMessage, ITicketMessageText } from "../../../types/ticketMessage.schema";
import { formatDate } from "../../../util/formatter";
import { getId } from "../../../util/mongoUtil";
import { getTextColorFromBackground } from "../../../util/util";
import Card from "../../card/Card";
import Flex from "../../container/Flex";
import SidebarHeader from "../../menu/sidebar/SidebarHeader";
import TicketChatMessageAttachment from "../ticketChat/messages/TicketChatMessageAttachment";
import TicketChatMessageAuthor from "../ticketChat/messages/TicketChatMessageAuthor";
import "./TicketAttachments.css";

export default function TicketAttachments({ ticket }: { ticket: ITicket }) {

  const {
    messages
  } = useTicketMessages(ticket._id);

  const [filter, setFilter] = React.useState<string>("");
  const [matchingMessages, setMatchingMessages] = React.useState<Array<ITicketMessage>>([]);

  const { user } = useUser();

  React.useEffect(() => {
    if (!messages) return;

    const upperFilter = filter?.toUpperCase();

    const matchingItems = messages.filter(m => {

      if (m.isSystemMessage) return false;
      if (!upperFilter) return true;

      if (m.content?.find((c: ITicketMessageText) => c.text && c.text.toUpperCase().includes(upperFilter))) return true;
      if (m.attachments?.find((a: IAttachment) => a.name && a.name.toUpperCase().includes(upperFilter))) return true;
      return false;
    })

    setMatchingMessages(matchingItems);
  }, [messages, filter]);

  return (
    <div className="d-flex flex-column gap-2 w-100 h-100">
      <SidebarHeader text="Anhänge" />
      {
        !!messages?.length && <input className="form-control" placeholder="Dateien oder Nachrichten suchen..." onChange={(e) => setFilter(e.target.value)}></input>
      }
      {
        !!matchingMessages?.length
          ? matchingMessages.map(m => {

            const isOwn = getId(m.sentBy) === getId(user);

            const cardStyle = generateStyle({
              name: "backgroundColor",
              value: isOwn ? "var(--backgroundDarker)" : "var(--primaryAccent)",
            }, {
              name: "color",
              value: isOwn ? getTextColorFromBackground("primaryAccent") : "#000000"
            });

            return (
              <Card className="w-100" style={cardStyle} color="backgroundLighter">
                <div key={m._id} className="d-flex flex-column w-100 gap-1">
                  <Flex row align="start" justify="between">
                    <TicketChatMessageAuthor message={m} isOwn={isOwn} colorMap={{}} />
                    <strong>{formatDate(m.createdAt, true, true)}</strong>
                  </Flex>
                  <div className="d-flex flex-column gap-2 w-100">
                    {
                      m.attachments?.map(a => <TicketChatMessageAttachment size="small" fullWidth attachment={a} message={m} key={a.googleCloudFileId} />)
                    }
                  </div>
                </div>
              </Card>
            )
          })
          : <span>{!!filter ? "Keine Ergebnisse" : "Keine Nachrichten mit Anhang"}</span>
      }
    </div>
  )
}