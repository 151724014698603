import React from "react";
import { useAnyUserStatistics } from "../../../state/api/statistics/useAnyUserStatistics";
import { useUser } from "../../../state/api/user/useUser";
import WithPermissions from "../../permissions/WithPermissions";
import UserSelect from "../../user/UserSelect";
import TicketStatsCardGrid from "../TicketStatsCardGrid";
import { TicketProductivityTab } from "../ProductivityOverview";

export interface IUserProductivityOverviewProps {
  userId: string
}

export default function UserProductivityOverview({ userId }: IUserProductivityOverviewProps) {
  const { loadingUserStatistics, userStatistics } = useAnyUserStatistics(userId);

  return (
    <WithPermissions permissions={["statistics.own.read"]}>
      <TicketStatsCardGrid
        isPersonal
        currentTab={TicketProductivityTab.Personal}
        resetTarget={userId}
        isLoading={loadingUserStatistics}
        statistics={userStatistics}
        cardColor="background"
      />
    </WithPermissions>
  )
}