import React from "react";
import { useTenant } from "../../../state/api/tenant/useTenant";
import { IMailLog, MailRejectReason } from "../../../types/ApiTypes";
import { formatDate } from "../../../util/formatter";
import Button from "../../buttons/Button";
import Flex from "../../container/Flex";
import ModalDialog from "../../modal/ModalDialog";
import Pill from "../../pills/Pill";
import TableCell from "../../tables/TableCell";
import TableRow from "../../tables/TableRow";
import Typography from "../../text/Typography";
import MailLogCreatedByBanner from "./MailLogCreatedByBanner";
import MailLogSourceBanner from "./MailLogSourceBanner";
import MailLogTypeBanner from "./MailLogTypeBanner";
import MailRejectReasonBanner from "./MailRejectReasonBanner";

export default function MailLogOverviewItem({ logEntry, onlyActiveTenant }: { logEntry: IMailLog, onlyActiveTenant?: boolean }) {

  const { tenant } = useTenant();

  if (!tenant) return null;
  if (onlyActiveTenant && logEntry.tenant._id !== tenant._id) return null;

  return (
    <TableRow>
      <TableCell>
        <MailLogTypeBanner type={logEntry.type} />
      </TableCell>
      <TableCell>
        <Flex gap="0">
          <Typography bold>{formatDate(logEntry.sentAt || logEntry.createdAt, false, false, false, true)}</Typography>
          <Typography>{formatDate(logEntry.sentAt || logEntry.createdAt)}</Typography>
        </Flex>
      </TableCell>
      <TableCell>
        <Flex fullWidth gap="0">
          {
            logEntry.fromName && <strong>{logEntry.fromName}</strong>
          }
          {
            logEntry.fromName !== logEntry.fromMail && <Typography>{logEntry.fromMail}</Typography>
          }
        </Flex>
      </TableCell>
      <TableCell content={logEntry.toMail} />
      <TableCell content={logEntry.subject} />
      <TableCell>
        <MailLogSourceBanner source={logEntry.source} />
      </TableCell>
      <TableCell>
        {
          logEntry.rejected
            ? <MailRejectReasonBanner reason={logEntry.rejectReason} />
            : <Pill icon="check" color="success" />
        }
      </TableCell>
      <TableCell>
        <ModalDialog
          button={<Button variant="subtle">Mehr anzeigen</Button>}
          title="Mail-Inhalt"
        >
          <div className="d-flex flex-column align-items-start justify-content-start gap-2">
            {
              logEntry.rejectMessage && (
                <Flex>
                  <strong>Abgelehnt, weil</strong>
                  <span>{logEntry.rejectMessage}</span>
                </Flex>
              )
            }
            {
              logEntry.subject && (
                <div className="d-flex flex-column">
                  <strong>Betreff</strong>
                  <span>{logEntry.subject}</span>
                </div>
              )
            }
            {
              logEntry.officeAccount && (
                <div className="d-flex flex-column">
                  <strong>Mail-Account</strong>
                  <span>{logEntry.officeAccount}</span>
                </div>
              )
            }
            {
              logEntry.sourceId && (
                <div className="d-flex flex-column">
                  <strong>Quell-ID</strong>
                  <span>{logEntry.sourceId}</span>
                </div>
              )
            }
            {
              logEntry.mailId && (
                <div className="d-flex flex-column">
                  <strong>Mail-ID</strong>
                  <span>{logEntry.mailId}</span>
                </div>
              )
            }
            {
              logEntry.eventId && (
                <div className="d-flex flex-column">
                  <strong>Event-ID</strong>
                  <span>{logEntry.eventId}</span>
                </div>
              )
            }
            {
              logEntry.rejected && logEntry.rejectReason === MailRejectReason.ExistsInLog && logEntry.alreadyProcessedItem && (
                <div className="d-flex flex-column">
                  <strong>Verweis auf Log-Eintrag</strong>
                  <span>{logEntry.alreadyProcessedItem._id}</span>
                </div>
              )
            }
            {
              logEntry.additonalData && (
                <>
                  {
                    logEntry.additonalData.runningNotifierCount !== undefined && (
                      <div className="d-flex flex-column">
                        <strong>Aktive Notifier</strong>
                        <span>{logEntry.additonalData.runningNotifierCount}</span>
                      </div>
                    )
                  }
                  {
                    logEntry.additonalData.runningNotifiers && !!logEntry.additonalData.runningNotifiers.length && (
                      <div className="d-flex flex-column">
                        <strong>Liste der aktiven Notifier</strong>
                        <table>
                          <thead>
                            <tr>
                              <th>
                                ID
                              </th>
                              <th>
                                Account-ID
                              </th>
                              <th>
                                Erstellt am
                              </th>
                              <th>
                                Erstellt von
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              logEntry.additonalData.runningNotifiers.map(l => (
                                <tr>
                                  <td>{l.notifierId}</td>
                                  <td>{l.accountId}</td>
                                  <td>{formatDate(l.createdOn, true)}</td>
                                  <td><MailLogCreatedByBanner creator={l.createdBy} /></td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    )
                  }
                  {
                    logEntry.additonalData.queueLength !== undefined && (
                      <div className="d-flex flex-column">
                        <strong>Länge der Verarbeitungswarteschlange</strong>
                        <span>{logEntry.additonalData.queueLength}</span>
                      </div>
                    )
                  }
                  {
                    logEntry.additonalData.sourceCreatedOn !== undefined && (
                      <div className="d-flex flex-column">
                        <strong>Notifier erstellt am</strong>
                        <span>{formatDate(logEntry.additonalData.sourceCreatedOn, true)}</span>
                      </div>
                    )
                  }
                  {
                    logEntry.additonalData.sourceCreator !== undefined && (
                      <div className="d-flex flex-column">
                        <strong>Notifier erstellt durch</strong>
                        <MailLogCreatedByBanner creator={logEntry.additonalData.sourceCreator} />
                      </div>
                    )
                  }
                </>
              )
            }
          </div>
        </ModalDialog>
      </TableCell>
    </TableRow>
  )
}