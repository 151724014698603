import React from "react";
import useFilteredUsers from "../../hooks/useFilteredUsers";
import useUserUtil from "../../hooks/useUserUtil";
import { IMailTemplate } from "../../types/ApiTypes";
import SearchableComboBox, { ISearchableComboBoxBase } from "../comboBox/SearchableComboBox";
import { useMailTemplates } from "../../state/api/templates/useMailTemplates";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";

export interface IMailTemplateSelectProps  {
  onChange: (template: IMailTemplate | undefined) => void,
  value?: string,
  bold?: boolean,
  label?: string,
  placeholder?: string
}

export default function MailTemplateSelect(props: ISearchableComboBoxBase<IMailTemplate>) {

  const {
    loadingMailTemplates,
    mailTemplates
  } = useMailTemplates();

  const {
    getStringified
  } = useTemplateUtil();

  return (
    <SearchableComboBox
      {...props}
      values={mailTemplates ?? []}
      loading={loadingMailTemplates}
      itemToId={m => m._id}
      itemToString={m => `${getStringified(m.subject)}`}
    />
  )

}