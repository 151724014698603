import React from "react";
import ClientContactList from "../../components/clientContacts/ClientContactList";
import Page from "../../components/container/Page";
import WithPermissions from "../../components/permissions/WithPermissions";
import UserUpdateForm from "../../components/user/UserUpdateForm";
import useTenantSettings from "../../hooks/useTenantSettings";
import { Locale } from "../../locale/Locale";

export default function ClientContactManagement() {

  const { options } = useTenantSettings(true);

  return (
    <WithPermissions permissions={["users.clientContacts.read"]}>
      <Page
        header={Locale.titles.clientContactManagement}
        actionButtons={
          options?.interfaces?.datev?.canCreateClientContactsManually
            ? <UserUpdateForm isClientContact={true} color="primary" title="Neuen Mandantenkontakt erstellen" text="Neuer Mandantenkontakt" />
            : undefined
        }
        showBackButton
      >
        <ClientContactList />
      </Page>
    </WithPermissions>
  )
}