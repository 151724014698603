
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IUserClients } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useAnyUserClients = (id: string) => {
  const { data, isLoading, mutate } = useTiggiQuery<IUserClients>(ApiRoutes.User.Authority.AnyUserClients.getValue({ param: RouteParam.UserId, value: id }), {
    enabled: !!id,
  });

  return {
    userClients: data,
    loadingUserClients: isLoading,
    reloadUserClients: mutate
  }
}