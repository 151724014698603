import React from "react";
import { ITenant, TenantDeleteReason, TenantStatus } from "../../types/ApiTypes";
import { formatDate } from "../../util/formatter";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import Flex from "../container/Flex";

export default function TenantStatusBanner({ tenant }: { tenant: ITenant }) {

  if (!tenant) return <span>-</span>

  const getDeleteReasonText = () => {
    switch (tenant.deleteReason) {
      case TenantDeleteReason.Blocked: return "Blockiert";
      case TenantDeleteReason.Manual: return "Manuelle Löschung";
      case TenantDeleteReason.NoSubscription: return "Kein aktives Abo";
      case TenantDeleteReason.OnboardingNotFinished: return "Onboarding nicht beendet";
      case TenantDeleteReason.VerificationNotCompleted: return "Verifizierung nicht vollständig";
      default: return "";
    }
  }

  const getColorOfStatus = () => {
    switch (tenant.status) {
      case TenantStatus.AboutToBeDeleted:
      case TenantStatus.Blocked:

        return "#DF0000";

      case TenantStatus.Onboarding: return "#BEEBB7";
      case TenantStatus.OnboardingFinished: return "#19D17F";
      case TenantStatus.Active: return "#19D17F";
      case TenantStatus.Unverified: return "#F0F0F0";
    }
  }

  const getIconForStatus = () => {
    switch (tenant.status) {
      case TenantStatus.AboutToBeDeleted: return "trash";
      case TenantStatus.Onboarding: return "arrow-right"
      case TenantStatus.OnboardingFinished: return "check"
      case TenantStatus.Unverified: return "clock";
      case TenantStatus.Blocked: return "x";
      default: return "building";
    }
  }

  const getTextForStatus = () => {
    switch (tenant.status) {
      case TenantStatus.AboutToBeDeleted: return `Wird gelöscht am ${formatDate(tenant.willBeDeletedOn)}`;
      case TenantStatus.Onboarding: return "Onboarding";
      case TenantStatus.OnboardingFinished: return "Onboarding abgeschlossen";
      case TenantStatus.Unverified: return "Unverifiziert";
      case TenantStatus.Blocked: return "Blockiert";
      default: return "Aktiv";
    }
  }

  const color = getColorOfStatus();

  return (
    <Pill
      color={color}
      withDivider
      icon={getIconForStatus()}
    >
      <Flex gap="0">
        <Typography bold basedOnThisBackground={color} size={14} >{getTextForStatus()}</Typography>
        {
          tenant.status === TenantStatus.AboutToBeDeleted
            ? <Typography basedOnThisBackground={color} size={12}>{getDeleteReasonText()}</Typography>
            : null
        }
      </Flex>
    </Pill>
  )
}