import React from "react";
import Icon from "../../icons/Icon";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Page from "../../container/Page";
import { SessionVerificationMethod } from "../../../types/ApiTypes";
import "./VerificationMethodSelect.css";
import { generateClassName } from "../../../hooks/useAttributes";
import { AppColor } from "../../../app/AppStyles";

export default function VerificationMethodSelect({ method, disabled, saveMethod, loading }: { loading: boolean, disabled: boolean, method: SessionVerificationMethod, saveMethod: (m: SessionVerificationMethod) => void }) {
  const className = generateClassName("verification-method-select ps-5 pb-3 pe-5 pt-3 d-flex flex-column text-center gap-2", {
    value: disabled || loading,
    onTrue: "verification-method-select-disabled"
  }, {
    value: loading,
    onTrue: "verification-method-select-loading"
  });

  const iconClass = generateClassName("verification-method-icon", {
    value: disabled || loading,
    onTrue: "verification-method-icon-disabled"
  })

  return (
    <div className={className} onClick={(loading || disabled) ? undefined : () => saveMethod(method)}>
      <Icon className={iconClass} loading={loading} icon={method === SessionVerificationMethod.Mail ? "envelope" : "phone"} size={48} />
      <strong className="text-uppercase verification-select-text">{method === SessionVerificationMethod.Mail ? "Mail" : "SMS"}</strong>
    </div>
  )
}