import React from "react";
import LinkOfficeAccountButton from "../../components/mail/account/LinkOfficeAccountButton";
import PageLoader from "../../components/loader/PageLoader";
import Page from "../../components/container/Page";
import { useCurrentUserOfficeAccount } from "../../state/api/office/useCurrentUserOfficeAccount";
import { useUser } from "../../state/api/user/useUser";
import { OfficeAccountStatus } from "../../types/officeAccount.types";

export default function ReAuthOfficeAccountPage() {

  const { user } = useUser();

  const { currentUserOfficeAccount, loadingCurrentUserOfficeAccount } = useCurrentUserOfficeAccount();

  if (loadingCurrentUserOfficeAccount) return <PageLoader />

  const needsReAuth = currentUserOfficeAccount && currentUserOfficeAccount.status === OfficeAccountStatus.NeedsReAuth;

  return (
    <Page fullscreen className="p-5">
      <h1 className="display-1 fw-bold">
        {
          needsReAuth
            ? "Office365-Verknüpfung abgelaufen"
            : "Aktualisierung erforderlich"
        }
      </h1>
      <h5>
        {
          needsReAuth
            ? "Ihre Office365-Verknüpfung ist ausgelaufen. Bitte melden Sie sich erneut an, um fortzufahren."
            : "Bitte aktualisieren Sie diese Seite, um fortzufahren."
        }
      </h5>
      {
        currentUserOfficeAccount && (
          user
            ? (
              needsReAuth && (
                <LinkOfficeAccountButton
                  accountType={currentUserOfficeAccount.type}
                  onBehalfOfUser={user ? user._id : undefined}
                  reAuthAccount={currentUserOfficeAccount._id}
                />
              )
            )
            : <span>Bitte melden Sie sich an.</span>
        )
      }
    </Page>
  )
}