import React from "react";
import { officeAccountStartMailService, officeAccountStopMailService } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import { useOfficeMailListenerStatus } from "../../../state/api/office/useOfficeMailListenerStatus";
import Button from "../../buttons/Button";
import WithPermissions from "../../permissions/WithPermissions";
import { IOfficeAccount } from "../../../types/officeAccount.types";
import { useUser } from "../../../state/api/user/useUser";

export default function OfficeAccountMailListenerStatusButton({ officeAccount, isStop }: { officeAccount: IOfficeAccount, isStop?: boolean }) {
  const {
    loadingOfficeMailListenerStatus,
    officeMailListenerStatus,
    reloadOfficeMailListenerStatus
  } = useOfficeMailListenerStatus(officeAccount._id);

  const {
    user
  } = useUser();

  const {
    getAccountPermissions
  } = useInboxPermissions();

  const callApi = useApi();

  const isStart = !user?.isSuperAdmin || !isStop;


  const clickHandler = async () => {
    if (isStart) await callApi(officeAccountStartMailService({ id: officeAccount._id }));
    else callApi(officeAccountStopMailService({ id: officeAccount._id }));
    await reloadOfficeMailListenerStatus();
  }

  const permissions = getAccountPermissions("read", officeAccount);

  if (!isStart && !officeMailListenerStatus) return null;
  if (officeMailListenerStatus && !user?.isSuperAdmin) return null;

  return (
    <WithPermissions permissions={permissions} >
      <Button
        size="small"
        loading={loadingOfficeMailListenerStatus}
        loadingText="Status wird überprüft..."
        text={isStart ? "Starten" : "Service stoppen"}
        color={isStart ? "success" : "error"}
        onClick={clickHandler}
        icon={isStart ? "chevron-left" : "x"}
      />
    </WithPermissions>
  )
}