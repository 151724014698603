import { ApiRoutes } from "../../../api/ApiRoutes";
import useFilterQuery from "../../../hooks/useFilterQuery";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IMailLog, MailLogType } from "../../../types/ApiTypes";
import { IQueryCursor } from "../../../types/query.schema";

export interface IMailLogFilter extends IQueryCursor {
  officeAccount?: string;
  ticket?: string;
  ticketMessage?: string;
  createdAtStart: Date;
  createdAtEnd: Date;
  type?: MailLogType;
  from?: string;
  to?: string;
  subject?: string;
}



export const useMailLog = (f?: IMailLogFilter) => {
  const {
    getUrlWithFilter
  } = useFilterQuery();

  const { data, isLoading, mutate } = useTiggiQuery<Array<IMailLog>>(getUrlWithFilter(ApiRoutes.MailLog.GetAll, f), {
    enabled: !!f
  });

  return {
    mailLogs: data,
    loadingMailLogs: isLoading,
    reloadMailLogs: mutate
  }
}