import React from "react";
import { MailLogType } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";

export default function MailLogTypeBanner({ type }: { type: MailLogType }) {

  const getColor = (): string => {
    switch (type) {
      case MailLogType.Incoming: return "#7733F0";
      case MailLogType.Outgoing: return "#F03377";
    }
  }

  return (
    <Pill
      color={getColor()}
      size="small"
      icon={type === MailLogType.Outgoing ? "arrow-left" : "arrow-right"}
      text={type === MailLogType.Incoming ? "EIN" : "AUS"}
    />
  )
}