import React from "react";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";
import Icon from "../../../icons/Icon";
import FastModalDialog from "../../../modal/FastModalDialog";
import ModalDialog from "../../../modal/ModalDialog";
import RoleBanner from "../../../user/RoleBanner";
import "./TicketMessageReadIndicator.css";

export default function TicketMessageReadIndicator({ message }: { message: ITicketMessage }) {
  const isRead = !!message.readBy?.length;

  return (
    <FastModalDialog
      disabled={!isRead}
      button={<Icon icon={isRead ? "check-all" : "check"} color={isRead ? "success" : undefined} />}
      dialog={<TicketMessageReadBy readBy={message.readBy} />}
    />
  )
}

function TicketMessageReadBy(props: { readBy: string[] }) {

  const { readBy } = props;

  return (
    <ModalDialog
      {...props}
      title="Gelesen von"
    >
      {
        readBy?.map(id => <RoleBanner key={id} user={id} showOnEmptyText="Externer Nutzer" />)
      }
    </ModalDialog>
  )
}