import { IMongooseDocument } from "./ApiTypes";
import { IDebuggableResource } from "./debug.schema";

export enum ResourceChangeType {
  Created = "created",
  Updated = "updated"
}

export interface IOfficeAccountSubscription {
  resource: SubscribeableResource,
  changeTypes: Array<ResourceChangeType>
}

export enum SubscribeableResource {
  Inbox = "/me/mailfolders('inbox')/messages",
  SentItems = "/me/mailfolders('SentItems')/messages",
}

export const AllOfficeSubscriptions: Array<IOfficeAccountSubscription> = [
  {
    changeTypes: [ResourceChangeType.Created],
    resource: SubscribeableResource.Inbox
  },
  {
    changeTypes: [ResourceChangeType.Created],
    resource: SubscribeableResource.SentItems
  }
];

export enum GraphSubscriptionStatus {
  Active,
  Inactive,
  InvalidToken,
  Unverified
}

export interface IGraphSubscription extends IMongooseDocument, IDebuggableResource {
  status: GraphSubscriptionStatus,
  expires: Date,
  subscriptionId: string,
  resource: SubscribeableResource,
  changeType: string,
  officeAccount: string,
  lastEventLog: string,
  lastEventReceived?: Date,
  tenant: string
}
