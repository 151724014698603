import React from "react";
import { useAllTeamsStatistics } from "../../../state/api/statistics/useAllTeamsStatistics";
import { useAnyTeamUserStatistics } from "../../../state/api/statistics/useAnyTeamUserStatistics";
import WithPermissions from "../../permissions/WithPermissions";
import { TicketProductivityTab } from "../ProductivityOverview";
import TicketStatsCardGrid from "../TicketStatsCardGrid";

export interface ITeamProductivityOverviewProps {
  teamId?: string
}

export default function TeamProductivityOverview({ teamId }: ITeamProductivityOverviewProps) {

  const { loadingTeamUserStatistics, teamUserStatistics } = useAnyTeamUserStatistics(teamId || "");
  const { allTeamsStatistics, loadingAllTeamsStatistics } = useAllTeamsStatistics();

  return (
    <WithPermissions permissions={["statistics.team.read"]}>
      <TicketStatsCardGrid
        currentTab={TicketProductivityTab.Team}
        resetTarget={teamId ?? ""}
        allStatistics={allTeamsStatistics}
        hasTeam
        cardColor="background"
        isLoading={loadingTeamUserStatistics}
        statistics={teamUserStatistics?.statistics || undefined}
      />
    </WithPermissions>
  )
}