import React, { PropsWithChildren } from "react";
import IElementProps from "../../../../types/element.types";
import { IErrorBoundaryState } from "../../../../app/ErrorBoundary";

export interface IErrorBoundaryProps {
  children: (err: boolean) => React.ReactElement
}

export default class ElementsErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  logErrorToMyService(error: any, errorInfo: any) {
    console.log("ELEMENTS Error at " + Date.now().toLocaleString() + ":")
    console.log(error);
    console.log(errorInfo);
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.logErrorToMyService(error, errorInfo);
  }

  render() {
    return this.props.children(this.state.hasError);
  }
}