import React from "react";
import { officeAccountsTestMailRecognition } from "../../../../api/Api";
import { IOfficeAccountMailByIdRequest } from "../../../../api/ApiRequests";
import useApi from "../../../../hooks/useApi";
import Button from "../../../buttons/Button";
import WithPermissions from "../../../permissions/WithPermissions";
import { IAddMailAsMessageToTicketButtonProps } from "./AddMailToTicketButton";

export default function TestMailRecognitionButton({ account, mailId }: IAddMailAsMessageToTicketButtonProps) {

  const callApi = useApi();

  if (!account?._id) return null;
  if (!mailId) return null;

  return (
    <WithPermissions superAdminOnly>
      <Button text="Mailerhalt testen" size="small" color="error" iconPosition="end" onClick={async () => {
        await callApi(officeAccountsTestMailRecognition({
          id: account._id,
          messageId: mailId
        } as IOfficeAccountMailByIdRequest), true);
      }} />
    </WithPermissions>
  );
}