import React from "react";
import { testingSocket } from "../../api/Api";
import { SocketEvents } from "../../config/TicketEvents";
import useApi from "../../hooks/useApi";
import { useUser } from "../../state/api/user/useUser";
import { ITestSocketPayload } from "../../types/socket.types";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import ModalForm from "../modal/ModalForm";
import Typography from "../text/Typography";

export default function TestSocketBtn() {

  const { user } = useUser();
  const callApi = useApi();

  if (!user?.isSuperAdmin) return null;

  const socketEvents = [
    SocketEvents.Tickets.Escalated,
    SocketEvents.System.Message
  ]

  const getLabelForSocketEvent = (s: string) => {
    switch (s) {
      case SocketEvents.Tickets.Escalated: return "Ticket eskaliert";
      case SocketEvents.System.Message: return "System-Nachricht";
    }

    return "";
  }

  return (
    <ModalForm
      button={<Button color="primary" text="Socket testen" icon="rulers" />}
      initialValues={{
        event: "",
        ticket: "test-ticket"
      } as ITestSocketPayload}
      onSubmit={async (values) => {
        await callApi(testingSocket(values));
        return false;
      }}
      sidebar={formik => <Button className="mt-auto" text="Test senden" icon="envelope" onClick={async () => await formik.submitForm()} loading={formik.isSubmitting} />}
      title="Socket testen"
    >
      {
        formik => (
          <Flex>
            <Typography>Der Test wird an den aktuellen Nutzer gesendet.</Typography>
            <SearchableComboBox
              label="Socket-Event"
              value={formik.values.event}
              values={socketEvents}
              itemToId={t => t}
              itemToString={t => getLabelForSocketEvent(t)}
              onChange={t => formik.setFieldValue("event", t)}
              renderItem={t => <Typography bold color="primary">{getLabelForSocketEvent(t)}</Typography>}
            />
          </Flex>
        )
      }
    </ModalForm>
  )
}