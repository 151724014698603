import Page from "../components/container/Page";
import React from "react";
import { StatusCodes } from "http-status-codes";
import { useNavigate } from "react-router-dom";
import Icon from "../components/icons/Icon";
import { generateStyle } from "../hooks/useAttributes";
import { Locale } from "../locale/Locale";
import Flex from "../components/container/Flex";
import Typography from "../components/text/Typography";
import Button from "../components/buttons/Button";

interface IErrorProps {
  message?: string,
  status?: number,
  error?: Error | null,
  hideBackButton?: boolean
}

export default function Error({ message = Locale.errors.generic, error, status = StatusCodes.INTERNAL_SERVER_ERROR, hideBackButton = false }: IErrorProps) {

  const navigate = useNavigate();

  return (
    <Page fullscreen centered className="p-5" colored>
      <Flex gap="5">
        <Flex gap="2">
          <Flex row reverse gap="3">
            <Flex row reverse gap="3">
              {
                !hideBackButton && (
                  <Button
                    color="secondary"
                    size="large"
                    icon="arrow-left-short"
                    variant="subtle"
                    text="Zurück"
                    onClick={() => {
                      navigate(-1);
                      window.location.reload();
                    }}
                  />
                )
              }
              <Button
                color="secondary"
                size="large"
                icon="house"
                variant="subtle"
                text="Home"
                onClick={() => {
                  navigate("/")
                  window.location.reload();
                }}
              />
              {
                error && (
                  <Button color="error" size="large" icon="bug" variant="subtle" text="Fehler kopieren" onClick={() => navigator.clipboard.writeText(`${error.message} / ${error.cause} / ${error.stack}`)} />
                )
              }
            </Flex>
          </Flex>
          <Typography bold size="100" noLinePadding upper color="bright">{status}</Typography>
        </Flex>
        <Flex gap="0">
          <Typography size="16" color="muted">{message}</Typography>
        </Flex>
      </Flex>
    </Page>
  )
}