import React from "react";
import Page from "../../components/container/Page";
import AddDomainForm from "../../components/domain/AddDomainForm";
import DomainList from "../../components/domain/DomainList";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { Locale } from "../../locale/Locale";
import { useTenantDomains } from "../../state/api/domains/useTenantDomains";

export default function TenantDomainManagement() {
  const { tenantDomains, loadingTenantDomains } = useTenantDomains();

  return (
    <Page
      header={Locale.titles.tenantDomainManagement}
      showBackButton
      actionButtons={<AddDomainForm />}
    >
      {
        loadingTenantDomains
          ? <LoadingSpinner />
          : <DomainList domains={tenantDomains || []} />
      }
    </Page>
  )
}