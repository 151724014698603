import React from "react";
import { ITicket, ITicketEscalationWorkflow } from "../../../types/ApiTypes";
import ModalForm from "../../modal/ModalForm";
import { ITicketEscalateManuallyRequest } from "../../../api/ApiRequests";
import Button from "../../buttons/Button";
import useApi from "../../../hooks/useApi";
import { ticketEscalationWorkflowTest, ticketsEscalateManually } from "../../../api/Api";
import { useTicketEscalationLog } from "../../../state/api/tickets/useTicketEscalationLog";
import Flex from "../../container/Flex";
import { useTicketEscalationWorkflow } from "../../../state/api/escalation/useTicketEscalationWorkflows";
import SearchableComboBox from "../../comboBox/SearchableComboBox";
import LoadingSpinner from "../../loader/LoadingSpinner";
import WithPermissions from "../../permissions/WithPermissions";
import TicketOverview from "../lists/TicketOverview";
import useModalNotifications from "../../../hooks/useModalNotifications";
import { ModalType } from "../../../config/ModalTypes";

export interface ITestTicketEscalationButtonProps {
  workflowId: string
}

export default function TestTicketEscalationButton(props: ITestTicketEscalationButtonProps) {

  const callApi = useApi();

  const showNotification = useModalNotifications();

  return (
    <WithPermissions superAdminOnly>
      <ModalForm
        fullscreen
        submitText="Testen"
        title="Testen"
        initialValues={{
          workflowId: props.workflowId,
          ticketId: ""
        }}
        button={<Button text="Ticket-Matching testen" variant="subtle" />}
        onSubmit={async (values) => {

          if (!values.ticketId) {
            showNotification({ text: "Bitte wählen Sie ein Ticket aus!", type: ModalType.Error });
            return false;
          }

          const res = await callApi(ticketEscalationWorkflowTest(values), true, "Ticket matcht!");

          if (!res?.success) return false;

          return false;
        }}
      >
        {
          formik => (
            <Flex>
              <TicketOverview
                onSelectionChange={t => {
                  if (!t.size) formik.setFieldValue("ticketId", "");
                  else formik.setFieldValue("ticketId", t.values().next().value?._id);
                }}
                maxSelectedItems={1} />
            </Flex>
          )
        }
      </ModalForm>
    </WithPermissions>
  )
}