import React from "react";
import { ticketAssignmentsAdd, ticketAssignmentsUpdate } from "../../../api/Api";
import { ITicketAssignmentAddRequest, ITicketAssignmentUpdateRequest } from "../../../api/ApiRequests";
import { AppColor } from "../../../app/AppStyles";
import useApi from "../../../hooks/useApi";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import useUserUtil from "../../../hooks/useUserUtil";
import { useTicketAssignee } from "../../../state/api/tickets/assignments/useTicketAssignee";
import { useTicketAssignments } from "../../../state/api/tickets/assignments/useTicketAssignments";
import { useTicketClientContact } from "../../../state/api/tickets/assignments/useTicketClientContact";
import { useTicketMessages } from "../../../state/api/tickets/messages/useTicketMessages";
import { useTicket } from "../../../state/api/tickets/useTicket";
import { useTicketActivity } from "../../../state/api/tickets/useTicketActivity";
import { ITicket, ITicketAssignment, IUser } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import ClientSelect from "../../clients/ClientSelect";
import Flex from "../../container/Flex";
import CheckBox from "../../forms/CheckBox";
import FormikField from "../../forms/FormikField";
import Icon from "../../icons/Icon";
import FastModalDialog from "../../modal/FastModalDialog";
import ModalForm from "../../modal/ModalForm";
import WithPermissions from "../../permissions/WithPermissions";
import Pill from "../../pills/Pill";
import Typography from "../../text/Typography";
import UserSelect from "../../user/UserSelect";
import CustomCheckBox from "../../forms/CustomCheckBox";

export interface IUpdateTicketAssignmentFormProps {
  ticket: ITicket,
  assignment?: ITicketAssignment
}

export default function UpdateTicketAssignmentForm(props: IUpdateTicketAssignmentFormProps) {

  const {
    ticket,
    assignment
  } = props;

  const permissions = useTicketPermission("update", ticket);
  const buttonText = assignment ? "Bearbeiten" : "Neue Zuweisung";
  const buttonIcon = assignment ? "pen" : "plus";
  const buttonColor: AppColor = assignment ? "primary" : "success";

  return (
    <WithPermissions permissions={permissions}>
      <FastModalDialog
        button={<Button variant="text" icon={buttonIcon} text={buttonText} color={buttonColor} />}
        dialog={<UpdateTicketAssignmentFormContent {...props} />}
      />
    </WithPermissions>
  )

}


function UpdateTicketAssignmentFormContent(props: IUpdateTicketAssignmentFormProps) {

  const { ticket, assignment } = props;

  const [freeForm, setFreeForm] = React.useState<boolean>(!!assignment?.mailAddress);


  const { reloadTicketAssignee } = useTicketAssignee(ticket);
  const { reloadTicketClientContact } = useTicketClientContact(ticket);
  const { reloadTicketAssignments } = useTicketAssignments(ticket);
  const { reloadMessages } = useTicketMessages(ticket._id);
  const { reloadTicketActivity } = useTicketActivity(ticket);
  const { reloadTicket } = useTicket(ticket._id, true);

  const {
    getCurrentTenantRole
  } = useUserUtil();

  const callApi = useApi();

  const text = assignment ? "Zuweisung bearbeiten" : "Neue Zuweisung";

  if (assignment && !assignment.isActive) return null;

  return (
    <ModalForm
      {...props}
      initialValues={
        assignment ?? {
          ticket: ticket._id,
          client: "",
          isCc: true,
          isMainAssignee: false,
          isMainClientContact: false,
          user: "",
          mailAddress: "",
          isActive: true
        }}
      title={text}
      onSubmit={async (values) => {

        const res = assignment
          ? await callApi(ticketAssignmentsUpdate(values as ITicketAssignmentUpdateRequest))
          : await callApi(ticketAssignmentsAdd(values as ITicketAssignmentAddRequest));

        if (!res || !res.success) return false;

        await Promise.all([
          reloadTicketAssignee(),
          reloadTicketAssignments(),
          reloadTicketClientContact(),
          reloadMessages(),
          reloadTicket(),
          reloadTicketActivity()
        ]);

        return true;
      }}
    >
      {
        formik => {

          const changeUser = (u: IUser | undefined) => {
            formik.setFieldValue("user", u?._id);
            formik.setFieldValue("client", undefined);
            formik.setFieldValue("mailAddress", u?.mailAddress);
          }

          const role = getCurrentTenantRole(formik.values.user);

          const isClient = !role || role.isClient;

          return (
            <Flex fullWidth gap="4">
              <CustomCheckBox
                initialValue={!!assignment?.mailAddress}
                label="E-Mail-Zuweisung erlauben (freie Eingabe)"
                onChange={v => {
                  setFreeForm(v);
                  formik.setFieldValue("user", undefined);
                  formik.setFieldValue("mailAddress", v ? assignment?.mailAddress : "");
                }}
              />
              {
                freeForm
                  ? (
                    <FormikField
                      label="E-Mail"
                      name="mailAddress"
                      className="w-100"
                    />
                  )
                  : (
                    <UserSelect
                      useDefaultValue={false}
                      displayed={formik.values.isMainAssignee ? "employees" : "all"}
                      onChange={changeUser}
                      value={formik.values.user}
                      label="Nutzer"
                      className="w-100"
                    />
                  )
              }
              <Flex row gap={4}>
                {
                  (assignment?.isMainClientContact || (formik.values.user && isClient)) && <CheckBox name="isMainClientContact" readOnly={assignment?.isMainClientContact} label="Mandantenkontakt des Tickets" className="w-100" />
                }
                {
                  (assignment?.isMainAssignee || (formik.values.user && !isClient)) && <CheckBox name="isMainAssignee" readOnly={assignment?.isMainAssignee} label="Bearbeiter des Tickets" className="w-100" />
                }
                {
                  !formik.values.isMainAssignee && !formik.values.isMainClientContact && (
                    <Pill color="#F0F0F0">
                      <Icon color="primary" icon="info-circle" />
                      <Typography italic>{assignment ? "CC-Zuweisung" : "Zuweisung wird als CC-Zuweisung erstellt."}</Typography>
                    </Pill>
                  )
                }
              </Flex>
              {
                !formik.values.isMainAssignee && isClient && (
                  <ClientSelect
                    clientContactId={formik.values.user}
                    showAllClients={!formik.values.user}
                    clientId={formik.values.client}
                    saveClient={(client) => formik.setFieldValue("client", client?._id)}
                  />
                )
              }
            </Flex>
          )
        }
      }
    </ModalForm >

  )
}
