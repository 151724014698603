import React from "react";
import { useTeams } from "../../state/api/teams/useTeams";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import Table from "../tables/Table";
import TeamsListItem from "./TeamsListItem";

export default function TeamsList() {

  const { teams, loadingTeams } = useTeams();

  return (
    <WithPermissions permissions={["teams.all.read"]}>
      {
        loadingTeams
          ? <LoadingSpinner centered={false} />
          : (
            teams && teams.length
              ? (
                <Table
                  responsive
                  border
                  headers={[{ label: "Name" }, { label: "Vorgesetzter" }, { label: "Mitglieder" }, { label: "" }]}
                  renderItem={t => <TeamsListItem key={t._id} team={t} />}
                  items={teams}
                />
              )
              : <span>Keine Teams gefunden.</span>
          )
      }
    </WithPermissions>
  )
}