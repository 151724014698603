import React from "react";
import useUserUtil from "../../../hooks/useUserUtil";
import { useSession } from "../../../state/api/session/useSession";
import { useUser } from "../../../state/api/user/useUser";
import Flex from "../../container/Flex";
import Typography from "../../text/Typography";
import EndImpostersessionButton from "./EndImposterSessionButton";
import "./ImposterSessionIndicator.css";

export default function ImposterSessionIndicator() {

  const { session } = useSession();
  const { user } = useUser();
  const { getName } = useUserUtil();

  if (!session || !session.isImposterSession) return null;

  return (
    <div className="imposter-session-user-indicator d-flex flex-row align-items-center justify-content-between" >
      <Flex row align="center" justify="between" className="imposter-session-user-indicator-content">
        <Typography basedOnThisBackground="error">Angemeldet als <strong>{getName(user)}</strong></Typography>
        <EndImpostersessionButton />
      </Flex>
    </div>
  )
}