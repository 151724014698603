import { FieldArray, FormikProps } from "formik";
import React from "react";
import { IPlanCreateRequest, IPlanRequestFeature, IPlanUpdateRequest } from "../../api/requests/subscriptions/subscriptionRequests";
import { ModalType } from "../../config/ModalTypes";
import useModal from "../../hooks/useModal";
import { useFeatures } from "../../state/api/subscriptions/useFeatures";
import LabelButton from "../buttons/LabelButton";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import FieldWithLabel from "../forms/FormikField";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";

export default function PlanFeaturesArray({ formik }: { formik: FormikProps<IPlanCreateRequest> | FormikProps<IPlanUpdateRequest> }) {

  const showModal = useModal();

  const { features, loadingFeatures } = useFeatures();

  if (loadingFeatures) return <LoadingSpinner />

  if (!features || !features.length) return (
    <div className="d-flex flex-column gap-2">
      <strong>Features</strong>
      <span>Um diesem Plan features hinzuzufügen, erstellen Sie bitte erst ein Feature.</span>
    </div>
  )

  const fieldName = "features";
  const getFieldNameForIndex = (ind: number, key: string) => `${fieldName}[${ind}].${key}`;

  const remainingFeatures = features.filter(f => !formik.values.features || !formik.values.features.length || !formik.values.features.find(b => b.feature === f._id));

  return (
    <FieldArray name={fieldName}>
      {
        (arrayHelpers) => {

          const addFeature = async () => {
            if (!remainingFeatures || !remainingFeatures.length) {
              showModal({
                text: "Sie haben bereits alle Features hinzugefügt. Erstellen Sie mehr Features, um fortzufahren.",
                type: ModalType.Error
              });
              return;
            }

            arrayHelpers.push({ feature: "", limit: "", remarks: "" } as IPlanRequestFeature)
          }

          return (
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <strong>Features</strong>
                <LabelButton text="Neues Feature" icon="plus" onClick={addFeature} />
              </div>
              <div className="d-flex flex-column gap-2">
                {
                  formik.values.features && !!formik.values.features.length
                    ? formik.values.features.map((f, index) => (
                      <div key={`${f.feature}-${index}`} className="d-flex flex-row align-items-start gap-3">
                        <strong>{index + 1}</strong>
                        <SearchableComboBox
                          className="w-100"
                          label="Feature"
                          values={features}
                          itemToId={f => f._id}
                          itemToString={f => f.name}
                          onChange={f => formik.setFieldValue(getFieldNameForIndex(index, "feature"), f?._id)}
                        />
                        <FieldWithLabel name={getFieldNameForIndex(index, "limit")} className="w-100" label="Limitation" placeholder="100, 20 Sekunden, 5 pro Tag..." />
                        <FieldWithLabel name={getFieldNameForIndex(index, "remarks")} className="w-100" label="Notiz" placeholder="Erweiterte Erklärung zur Limitation oder zum Feature..." />
                        <Icon icon="x" size={18} onClick={() => arrayHelpers.remove(index)} />
                      </div>
                    ))
                    : <span>Sie haben noch keine Features hinzugefügt. Features sind optional und ermöglichen es, die Vorteile eines Abos hervorzuheben.</span>
                }
              </div>
            </div>
          )
        }
      }
    </FieldArray>
  )
}