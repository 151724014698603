import React from "react";
import { AppRoutes } from "../config/AppRoutes";
import { ModalType } from "../config/ModalTypes";
import { SocketEvents } from "../config/TicketEvents";
import { useLatestClientImportLog } from "../state/api/clients/useLatestClientImportLog";
import { useTicket } from "../state/api/tickets/useTicket";
import { useTicketMessages } from "../state/api/tickets/messages/useTicketMessages";
import { useTicketsWithFilter } from "../state/api/tickets/useTicketsWithFilter";
import { useUser } from "../state/api/user/useUser";
import { ITicketEmit, ITicketMessagEmit, ITicketSocketMessageReadResponse } from "../types/SocketTypes";
import useFilteredTickets, { TicketFilter, TicketSubcategory } from "./useFilteredTickets";
import useFilterQuery from "./useFilterQuery";
import useModalNotifications from "./useModalNotifications";
import TiggiSocket from "../api/TiggiSocket";

export default function useTiggySocket() {

    const [lastAffectedTicket, setLastAffectedTicket] = React.useState<string>("");

    const { user } = useUser();
    const { reloadMessages } = useTicketMessages(lastAffectedTicket || "");
    const { reloadAndUpdateAllTickets } = useTicket(lastAffectedTicket || "");
    const { reloadTickets } = useTicketsWithFilter();

    const showNotification = useModalNotifications();
    
    const {
        getFilterForRoute,
    } = useFilteredTickets();

    const {
        getUrlWithFilter
    } = useFilterQuery();

    const { 
        reloadTickets: reloadFilteredTickets,
    } = useTicketsWithFilter(getFilterForRoute());

    const {
        reloadLatestClientImportLog
    } = useLatestClientImportLog({ enabled: false });

    const removeTicketSocketListeners = () => {
        TiggiSocket.off(SocketEvents.System.Error);
        TiggiSocket.off(SocketEvents.System.Message);
        TiggiSocket.off(SocketEvents.Tickets.MessageRead);
        TiggiSocket.off(SocketEvents.Tickets.MessageNew);
        TiggiSocket.off(SocketEvents.Tickets.New);
        TiggiSocket.off(SocketEvents.Tickets.Updated);
        TiggiSocket.off(SocketEvents.Tickets.RemoveOrDelete);
        TiggiSocket.off(SocketEvents.Tickets.UserTyping);
        TiggiSocket.off(SocketEvents.Tickets.UserStoppedTyping);
        TiggiSocket.off(SocketEvents.Clients.ImportProgress);
    }

    const connectToTicketSocket = () => {
        if (!user || !user._id) {
            TiggiSocket.disconnect();
            return;
        }
        
        if (TiggiSocket.connected) return;
        
        const host = window.location.href;

        console.log(`Verbinde mit dem Ticketsystem von '${host}'...`);

        TiggiSocket.auth = {
            userId: user._id,
            url: host
        };
    
        const updateTicketFromSocket = () => setTimeout(() => reloadAndUpdateAllTickets(), 100);

        TiggiSocket.on("connect", () => {
            console.info(`Connection established via ${TiggiSocket.io.engine.transport.name}...`);
        });
        
        TiggiSocket.on("close", () => {
            console.info("Connection closed.");
        });

        TiggiSocket.on(SocketEvents.System.Error, (err: Error) => {
            console.log("Connection error:", err)
        });

        TiggiSocket.on(SocketEvents.System.Message, (msg: string) => console.log(msg));
        TiggiSocket.on(SocketEvents.Tickets.MessageNew, (msg: ITicketMessagEmit) => {
            console.log("NEW MESSAGE:", msg.messageId);
            if (msg.notForUser && msg.notForUser === user._id) return;
            setLastAffectedTicket(msg.ticketId);
            updateTicketFromSocket();
            reloadMessages();
        });
        
        TiggiSocket.on(SocketEvents.Tickets.Escalated, (data: { ticket: string }) => {
            const link = getFilterForRoute({
                subcategory: TicketSubcategory.Escalated,
                show: TicketFilter.ForMe
            });
            
            showNotification({
                id: data.ticket,
                text: "Es wurde ein Ticket an Sie eskaliert. Bitte überprüfen Sie Ihre Tickets.",
                type: ModalType.Error,
                title: "Eskalation",
                action: {
                    text: "Tickets überprüfen",
                    to: getUrlWithFilter(AppRoutes.Tickets.path, link)
                }
            })
        });

        TiggiSocket.on(SocketEvents.Tickets.New, (ticket: ITicketEmit) => {
            console.log("NEW TICKET:", ticket.ticketId);
            reloadFilteredTickets();
            if (ticket.notForUser && ticket.notForUser === user._id) return;
            setLastAffectedTicket(ticket.ticketId);
            updateTicketFromSocket();
        });
        
        TiggiSocket.on(SocketEvents.Tickets.Updated, (ticket: ITicketEmit) => {
            console.log("TICKET UPDATED:", ticket.ticketId);
            setLastAffectedTicket(ticket.ticketId);
            updateTicketFromSocket();
        });

        TiggiSocket.on(SocketEvents.Tickets.RemoveOrDelete, (ticket: ITicketEmit) => {
            console.log("TICKET DELETED:", ticket.ticketId);
            reloadFilteredTickets();
            if (ticket.notForUser && ticket.notForUser === user._id) return;
            setLastAffectedTicket(ticket.ticketId);
            reloadTickets();
        });
        
        TiggiSocket.on(SocketEvents.Tickets.MessageRead, (readResponse: ITicketSocketMessageReadResponse) => {
            console.log("MESSAGE READ: ", readResponse.messageId);
        });

        TiggiSocket.on(SocketEvents.Clients.ImportProgress, async (logId: string) => {
            console.debug("IMPORT PROGRESS FOR :", logId);
            await reloadLatestClientImportLog()
        });

        TiggiSocket.connect();
    }

   

    const sendTyping = (ticketId: string) => TiggiSocket.emit(SocketEvents.Tickets.UserTyping, ticketId);
    const sendStopTyping = (ticketId: string) => TiggiSocket.emit(SocketEvents.Tickets.UserStoppedTyping, ticketId);

    return {
        connectToTicketSocket,
        removeTicketSocketListeners,
        sendTyping,
        sendStopTyping
    }
}