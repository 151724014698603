import React from "react";
import ResetPasswordForm from "../../components/user/ResetPasswordForm";
import Page from "../../components/container/Page";
import Flex from "../../components/container/Flex";
import Logo from "../../components/logo/Logo";
import Typography from "../../components/text/Typography";

export default function ResetPassword() {
  return (
    <Page fullscreen centered colored>
      <Flex gap={3}>
        <Logo />
        <Typography basedOnThisBackground="primary" size={24} bold >Passwort-Reset anfrangen</Typography>
        <ResetPasswordForm />
      </Flex>
    </Page>
  )
}