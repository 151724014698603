import React from "react";
import useFilteredUsers, { IWithFilteredUsers } from "../../hooks/useFilteredUsers";
import useUserUtil from "../../hooks/useUserUtil";
import { IUser } from "../../types/ApiTypes";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import Divider from "../menu/sidebar/Divider";
import Typography from "../text/Typography";
import UserRoleBanner from "./RoleBanner";

export interface IUserPickerProps extends IWithFilteredUsers {
  label?: string,
  bold?: boolean,
  description?: string,
  values: string[],
  saveValues: (values: string[]) => void
}

interface ISortedByRoleUsers {
  users: Array<IUser>,
  role: string
}

export default function UserPicker({ label, bold, saveValues, values, description, displayed = "all", showTheseIds, showWithoutRole }: IUserPickerProps) {

  const [sortedUsers, setSortedUsers] = React.useState<Array<ISortedByRoleUsers>>([]);

  const {
    users,
    loading
  } = useFilteredUsers({ displayed, showTheseIds, showWithoutRole });

  const {
    getRole
  } = useUserUtil();

  React.useEffect(() => {
    const noRole: string = "Keine Rolle";

    const result: Array<ISortedByRoleUsers> = [];
    const noRoleUsers: Array<IUser> = [];

    if (!users?.length) {
      setSortedUsers(result);
      return;
    }

    for (const user of users) {
      const role = getRole(user);

      if (!role?.displayName) {
        noRoleUsers.push(user);
        continue;
      }

      const existing = result.findIndex(r => r.role === role.displayName);

      if (existing < 0) result.push({
        users: [user],
        role: role.displayName
      })
      else result[existing].users.push(user);

    }

    if (noRoleUsers.length) result.push({
      users: noRoleUsers,
      role: noRole
    })

    setSortedUsers(result);

  }, [users])

  const isUserSelected = (userId: string) => {
    if (!values || !values.length) return false;
    return values.includes(userId);
  }

  const toggleUserSelection = (userId: string) => {
    if (isUserSelected(userId)) return removeUserFromSelection(userId);
    saveValues([...values, userId]);
  }

  const removeUserFromSelection = (userId: string) => {
    if (!values?.length) return;
    if (!values.includes(userId)) return;
    saveValues(values.filter(v => v !== userId));
  }

  return (
    <Flex fullWidth>
      {
        (label || description) && (
          <Flex gap={0}>
            <Typography bold={bold} color="primary">{label}</Typography>
            {
              description && <Typography size={12} color="primary">{description}</Typography>
            }
          </Flex>
        )
      }
      {
        loading
          ? <LoadingSpinner />
          : (
            <Flex fullWidth>
              {
                sortedUsers && !!sortedUsers.length
                  ? (
                    sortedUsers.map((su: ISortedByRoleUsers, index: number) => (
                      <Flex key={su.role} fullWidth style={{ maxHeight: "200px", overflowY: "auto" }}>
                        <Typography size={12} color="secondary" >{su.role}</Typography>
                        <Flex row fullWidth wrap>
                          {
                            su.users.map(u => (
                              <UserRoleBanner
                                displayNameAsValue
                                showMail
                                inactive={!isUserSelected(u._id)}
                                user={u}
                                onClick={async () => toggleUserSelection(u._id)}
                              />
                            ))
                          }
                        </Flex>
                        {
                          ((index + 1) < sortedUsers.length) && <Divider height={1} color="muted" opacity={0.5} />
                        }
                      </Flex>
                    ))
                  )
                  : <Typography italic>Keine Nutzer</Typography>
              }
            </Flex>
          )
      }
    </Flex>
  )
}