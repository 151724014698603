import React from "react";
import Page from "../../components/container/Page";
import UserList from "../../components/user/UserList";
import UserUpdateForm from "../../components/user/UserUpdateForm";
import { Locale } from "../../locale/Locale";
import { useUsers } from "../../state/api/user/useUsers";

export default function UserManagement() {

  const { users } = useUsers();

  const getTitle = () => {
    if (!users || !users.length) return Locale.titles.userManagement;

    return `${users.length} ${Locale.titles.userManagement.replace("Alle", "")}`;
  }

  return (
    <Page showBackButton header={getTitle()} actionButtons={<UserUpdateForm />}>
      <UserList />
    </Page>
  )
}