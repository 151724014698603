import { Form, Formik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { usersSetNewPassword, usersVerifyResetToken } from "../../api/Api";
import { IUserNewPasswordRequest } from "../../api/ApiRequests";
import { AppRouteParams, AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import useApi from "../../hooks/useApi";
import { min } from "../../hooks/useLocale";
import useModal from "../../hooks/useModal";
import usePassword from "../../hooks/usePassword";
import { useSession } from "../../state/api/session/useSession";
import { Cookies, setCookie } from "../../util/cookies";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import PasswordField from "../forms/PasswordField";
import PasswordValidationResult from "./session/PasswordValidationResult";
import SuggestPassword from "./session/SuggestPassword";

interface IUserNewPasswordValues extends IUserNewPasswordRequest {
  newPasswordRepeat: string
}

export default function ResetPasswordForm() {

  const {
    isSecurePassword
  } = usePassword();

  const verificationToken = AppRouteParams.VerificationToken.getValue(useParams());

  const { reloadSession } = useSession();

  const callApi = useApi();
  const showModal = useModal();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!verificationToken) {
      showModal({
        text: "Bitte geben Sie einen gültiges Passwort-Token an.",
        type: ModalType.Error
      });

      navigate(AppRoutes.Home.path);
      return;
    }

    const abortController = new AbortController();

    const verifyToken = async () => {
      if (!verificationToken) return;

      const res = await callApi(usersVerifyResetToken({ token: verificationToken }, abortController));

      if (!res) return;

      if (!res.success) {
        navigate(AppRoutes.Home.path);
        return;
      }
    }

    verifyToken();

    return () => abortController.abort();
  }, [verificationToken]);

  if (!verificationToken) return null;

  return (
    <Formik
      initialValues={{
        newPassword: "",
        newPasswordRepeat: "",
        token: verificationToken
      } as IUserNewPasswordValues}
      validationSchema={yup.object().shape({
        newPassword: yup.string().required("Neues Passwort").min(8, min(8)),
        newPasswordRepeat: yup.string().required("Bitte wiederholen Sie das Passwort.").min(8, min(8)),
      })}
      onSubmit={async (values) => {
        if (values.newPasswordRepeat !== values.newPassword) {
          showModal({
            text: "Die Passwörter stimmen nicht überein. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.",
            type: ModalType.Error
          })
          return;
        }

        if (!isSecurePassword(values.newPassword)) {
          showModal({
            text: "Ihr Passwort ist zu unsicher. Bitte geben Sie ein anderes Passwort ein.",
            type: ModalType.Error
          });
          return;
        }

        const res = await callApi(usersSetNewPassword(values), true);

        if (!res) return;


        if (res.success) {
          showModal({
            text: "Ihr Passwort wurde erfolgreich zurückgesetzt.",
            type: ModalType.Success
          });
        }

        const {
          session
        } = res.data;

        if (!session) return;

        setCookie(Cookies.Session, session);
        await reloadSession();

        navigate(AppRoutes.Home.path);
      }}
    >
      {
        (formik) => (
          <Form className="w-100">
            <Flex fullWidth gap={3}>
              <SuggestPassword />
              <PasswordField rounded className="w-100" name="newPassword" labelColor="bright" label="Neues Passwort" />
              <PasswordValidationResult password={formik.values.newPassword} />
              <PasswordField rounded className="w-100" name="newPasswordRepeat" labelColor="bright" label="Neues Passwort wiederholen" />
              <Button
                type="submit"
                text="Bestätigen"
                color="bright"
                loading={formik.isSubmitting}
                disabled={!formik.values.newPassword || !isSecurePassword(formik.values.newPassword) || !formik.values.newPasswordRepeat}
              />
            </Flex>
          </Form>
        )
      }

    </Formik>
  )
}