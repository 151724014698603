import React from "react";
import useAttachments from "../../hooks/useAttachments";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { IFileMetadata } from "../../types/ApiTypes";
import { formatFileSize } from "../../util/formatter";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import Pill from "../pills/Pill";
import { hexWithOpacity } from "../../util/util";
import Typography from "../text/Typography";
import Tooltip from "../tooltip/Tooltip";

export interface IMailAttachmentProps {
  attachment: IFileMetadata,
  size?: "small" | "regular",
  onClick?: () => (void | Promise<void>),
  hoverIcon?: string
}

export default function MailAttachment(props: IMailAttachmentProps) {

  const {
    attachment,
    size,
    hoverIcon,
    onClick
  } = props;

  const [hover, setHover] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    getColor,
    getIcon
  } = useAttachments();

  const clickHandler = async () => {

    if (!onClick) return;
    if (loading) return;

    setLoading(true);

    try {
      await onClick();
    }
    catch { }
    finally {
      setLoading(false);
    }
  }

  const color = getColor(attachment, true);

  const getPillIcon = () => {
    if (hover && !!onClick && !!hoverIcon) return hoverIcon;
    return getIcon(attachment);
  }

  return (
    <Pill
      color={color}
      onClick={clickHandler}
      loading={loading}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => setHover(true)}
      icon={getPillIcon()}
      iconSize={size === "small" ? 20 : 24}
      className="mw-100"
      withDivider
    >
      <Flex gap="0" fullWidth className="pt-1 pb-1">
        <Typography
          basedOnThisBackground={color}
          className="w-100"
          size={size === "small" ? "12" : "14"}
          bold={size === "regular"}
          wrap
        >
          {attachment.name}
        </Typography>
        <Typography
          basedOnThisBackground={color}
          className="w-100"
          size={size === "small" ? "9" : "12"}
          truncate
          breakWords
        >
          {formatFileSize(attachment.size, true)}
        </Typography>
      </Flex>
    </Pill>
  )
}