import React from "react";
import { useCoupons } from "../../state/api/subscriptions/useCoupons";
import Flex from "../container/Flex";
import PageLoader from "../loader/PageLoader";
import WithPermissions from "../permissions/WithPermissions";
import CouponGroup from "./CouponGroup";

export default function CouponOverview() {

  const { coupons, loadingCoupons, reloadCoupons } = useCoupons();

  return (
    <WithPermissions superAdminOnly>
      <Flex fullWidth wrap>
        {
          loadingCoupons
            ? <PageLoader />
            : (
              !!coupons?.length
                ? coupons.map(c => <CouponGroup key={c._id} coupon={c} />)
                : <span>Keine Rabattgruppen. Legen Sie eine Rabattgruppe an, um Gutscheincodes zu erstellen.</span>
            )
        }
      </Flex>
    </WithPermissions>
  )
}