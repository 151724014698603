import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";
import useTiggiQuery, { TiggiQueryOpts } from "../../../../hooks/useTiggiQuery";
import { IGraphSubscription } from "../../../../types/graphSubscription.schema";
import { IOfficeAccount } from "../../../../types/officeAccount.types";

export const useGraphSubscriptionMsStatus = (account: IOfficeAccount, subscription: IGraphSubscription, opts: TiggiQueryOpts) => {
  const { data, isLoading, mutate } = useTiggiQuery<microsoftgraph.Subscription>(ApiRoutes.OfficeAccounts.GetMsSubscriptionStatus.getValue({
    param: RouteParam.OfficeAccountId, value: account._id
  }, {
    param: RouteParam.graphSubscriptionId, value: subscription._id
  }), opts);

  return {
    msSubscription: data,
    loadingMsSubscription: isLoading,
    reloadMsSubscription: mutate
  }
}
