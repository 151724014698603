import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IRole } from "../../../types/ApiTypes";
import { Sorted } from "../../../types/collection.types";

export const useRoles = () => {
  const { data, isLoading, mutate } = useTiggiQuery<Sorted<IRole>>(ApiRoutes.Roles.All);

  return {
    roles: data?.items ?? [],
    rolesById: data?.byId ?? {},
    loadingRoles: isLoading,
    reloadRoles: mutate
  }
}