import React from "react";
import { testingQueue, testingWorker, testingWorkerResponsiveness } from "../../api/Api";
import Flex from "../../components/container/Flex";
import Page from "../../components/container/Page";
import TestBtn from "../../components/testing/TestBtn";
import TestSocketBtn from "../../components/testing/TestSocketBtn";
import Typography from "../../components/text/Typography";

export default function Testing() {

  return (
    <Page limitMaxHeight={false} header="Testing" showBackButton>
      <Flex fullWidth>
        <Typography size={24} bold>Tasks & Performance</Typography>
        <Flex>
          <Typography bold>Worker</Typography>
          <Flex row>
            <TestBtn
              apiCall={testingWorker}
              color="primary"
              icon="play"
              text="Test Worker"
            />
            <TestBtn
              apiCall={async () => {
                for (let i = 0; i < 100; i++) {
                  await testingWorkerResponsiveness();
                }

                return testingWorkerResponsiveness();
              }}
              color="primary"
              icon="play"
              text="Test Worker Responsiveness"
            />
          </Flex>
        </Flex>
        <Flex>
          <Typography bold>Queue</Typography>
          <Flex row>
            <TestBtn
              apiCall={testingQueue}
              color="primary"
              icon="play"
              text="Test Queue"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex fullWidth>
        <Typography size={24} bold>Socket</Typography>
        <TestSocketBtn />
      </Flex>
    </Page>
  )
}