import React from "react";
import { useOfficeAccounts } from "../../../state/api/office/useOfficeAccounts";
import { useUser } from "../../../state/api/user/useUser";
import { IOfficeAccount, OfficeAccountStatus } from "../../../types/officeAccount.types";
import Flex from "../../container/Flex";
import PageLoader from "../../loader/PageLoader";
import Paginated from "../../pagination/Paginated";
import WithPermissions from "../../permissions/WithPermissions";
import Pill from "../../pills/Pill";
import TableCell from "../../tables/TableCell";
import TableRow from "../../tables/TableRow";
import TeamBanner from "../../teams/TeamBanner";
import Typography from "../../text/Typography";
import UserRoleIndicator from "../../user/RoleBanner";
import LinkOfficeAccountButton from "./LinkOfficeAccountButton";
import OfficeMailAccountListenerStatus from "./OfficeAccountMailListenerStatus";
import OfficeAccountMailListenerStatusButton from "./OfficeAccountMailListenerStatusButton";
import OfficeAccountTypeBanner from "./OfficeAccountTypeBanner";
import RemoveOfficeAccountButton from "./RemoveOfficeAccountButton";
import UpdateOfficeAccountForm from "./UpdateOfficeAccountForm";
import Card from "../../card/Card";
import Icon from "../../icons/Icon";
import { useOfficeAccountGraphSubscriptions } from "../../../state/api/office/useOfficeAccountGraphSubscriptions";
import LoadingSpinner from "../../loader/LoadingSpinner";
import { IGraphSubscription, SubscribeableResource } from "../../../types/graphSubscription.schema";
import { formatDate } from "../../../util/formatter";
import { useGraphSubscriptionMsStatus } from "../../../state/api/office/graphSubscriptions/useGraphSubscriptionMsStatus";
import ModalDialog from "../../modal/ModalDialog";
import Button from "../../buttons/Button";

export default function LinkedAccountsOverview() {
  const { loadingOfficeAccounts, officeAccounts } = useOfficeAccounts();

  if (loadingOfficeAccounts) return <PageLoader />
  if (!officeAccounts) return <span>Keine Konten verknüpft</span>

  return (
    <WithPermissions permissions={["inboxes.all.read"]}>
      <Paginated
        responsive
        items={officeAccounts}
        renderItem={account => <Account key={account._id} account={account} />}
      />
    </WithPermissions>
  )
}

interface IAccountProps {
  account: IOfficeAccount
}

function Account(props: IAccountProps) {
  const {
    account
  } = props;

  const { user } = useUser();
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const inboxWatch = (
    <>
      <Flex>
        <Typography bold>Postfachüberwachung</Typography>
        {expanded && <Typography>Letzter Start: {formatDate(account.lastSubscriptionStart, true)}</Typography>}
      </Flex>
      <Flex row>
        <OfficeMailAccountListenerStatus officeAccount={account} />
        {
          account.status === OfficeAccountStatus.Active
            ? <OfficeAccountMailListenerStatusButton officeAccount={account} />
            : <LinkOfficeAccountButton accountType={account.type} onBehalfOfUser={account.assignments?.personalUser} reAuthAccount={account._id} />
        }
        {
          (account.isDebugResource && user?.isSuperAdmin) && <OfficeAccountMailListenerStatusButton officeAccount={account} isStop />
        }
      </Flex>
    </>
  )

  return (
    <Card
      color="background"
      header={(
        <Flex fullWidth justify="between" row>
          <Flex row onClick={() => setExpanded(!expanded)} >
            <Icon icon={expanded ? "chevron-down" : "chevron-right"} color="secondary" size={28} />
            <Flex gap="0">
              <Typography size="11">{account.accountName}</Typography>
              <Typography bold>{account.accountMail}</Typography>
            </Flex>
            <Flex row>
              {
                account.assignments && account.assignments.personalUser && <UserRoleIndicator user={account.assignments.personalUser} displayNameAsValue />
              }
              {
                account.assignments && account.assignments.team && <TeamBanner team={account.assignments.team} />
              }
              {account.isDebugResource && <Pill color="error" icon="bug" text="Debug-Konto" />}
            </Flex>
          </Flex>
          <Flex row>
            {!expanded && inboxWatch}
          </Flex>
        </Flex>
      )}
    >
      {
        expanded && (
          <Flex fullWidth gap="4">
            <Flex>
              <Typography bold>Typ</Typography>
              <OfficeAccountTypeBanner type={account.type} />
            </Flex>
            <Flex fullWidth>
              <Flex row fullWidth justify="between">
                {inboxWatch}
              </Flex>
              <AccountSubscriptions account={account} />
            </Flex>
            <Flex row justify="end" fullWidth>
              <UpdateOfficeAccountForm account={account} />
              <RemoveOfficeAccountButton account={account} />
            </Flex>
          </Flex>
        )
      }
    </Card>
  )
}

function AccountSubscriptions(props: IAccountProps) {
  const {
    account
  } = props;

  const { graphSubscriptions, loadingGraphSubscriptions } = useOfficeAccountGraphSubscriptions(account);


  if (loadingGraphSubscriptions) return <LoadingSpinner />

  return (
    <Flex row wrap fullWidth>
      {
        graphSubscriptions?.length
          ? graphSubscriptions.map(g => <GraphSubscription key={g._id} account={account} subscription={g} />)
          : <Typography>Keine aktiven Überwachungen</Typography>
      }
    </Flex >
  )
}

interface IGraphSubscriptionProps extends IAccountProps {
  subscription: IGraphSubscription
}

function GraphSubscription(props: IGraphSubscriptionProps) {
  const {
    subscription,
    account
  } = props;

  const {
    reloadMsSubscription,
    msSubscription,
    loadingMsSubscription
  } = useGraphSubscriptionMsStatus(account, subscription, { enabled: false });

  const getText = () => {
    switch (subscription.resource) {
      case SubscribeableResource.Inbox: return "Posteingang";
      case SubscribeableResource.SentItems: return "Postausgang";
    }
  }

  const getIcon = () => {
    switch (subscription.resource) {
      case SubscribeableResource.Inbox: return "mailbox";
      case SubscribeableResource.SentItems: return "send";
    }
  }

  return (
    <Card fitContent color="backgroundDarker" key={subscription._id} header={(
      <Flex row fullWidth justify="between" gap="5">
        <Flex row>
          <Icon icon={getIcon()} color="primary" />
          <Typography bold>{getText()}</Typography>
        </Flex>
        <ModalDialog
          button={<Button size="small" color="error" icon="bug" variant="subtle" >Debug</Button>}
          title="Details"
        >
          <Typography bold>Interne Daten</Typography>
          <Typography>Aktiv seit: {formatDate(subscription.createdAt, true)}</Typography>
          <Typography bold>Microsoft-Daten</Typography>
          {
            msSubscription
              ? <Typography>Expires: {formatDate(msSubscription.expirationDateTime, true)}</Typography>
              : <Typography>Bitte Daten laden.</Typography>
          }
          <Button onClick={async () => await reloadMsSubscription()} loading={loadingMsSubscription} color="primary" variant="subtle">Daten abrufen</Button>
        </ModalDialog>
      </Flex>
    )
    }>
      <Flex>
        <Typography>Letzte Aktivität: {formatDate(subscription.lastEventReceived, true)}</Typography>
        <Typography>Läuft ab: {formatDate(subscription.expires, true)}</Typography>
      </Flex>
    </Card >
  )
}