import React from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { Permission } from "../../types/ApiTypes";
import IElementProps from "../../types/element.types";
import { hexWithOpacity } from "../../util/util";
import LabelButton from "../buttons/LabelButton";
import LoadingSpinner from "../loader/LoadingSpinner";
import "./Table.css";
import TableCell from "./TableCell";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import TableCheckbox from "./TableCheckbox";
import Flex from "../container/Flex";
import PageIndicator from "../pagination/PageIndicator";

type TableHeader<T> = string | ITableHeader<T>;

interface ITableHeader<T> {
  label: string,
  hidden?: boolean,
  filterItem?: (item: T, filter: string) => boolean,
  permissions?: Array<Permission>,
  valueKey?: string
}

interface IActiveFilter<T> {
  header: ITableHeader<T>,
  index: number,
  filterValue: string
}

interface ITableProps<T> extends IElementProps {
  searchBoxAlwaysVisible?: boolean,
  canSelect?: boolean,
  selectedItems?: Map<string, T>,
  onSelectionChange?: (ids: Map<string, T>) => void,
  getItemId?: (item: T) => string,
  renderItem: (item: T, checkbox?: React.ReactNode) => React.ReactNode,
  itemShouldRender?: (item: T) => boolean,
  items: Array<T>,
  maxSelectedItems?: number,
  border?: boolean,
  responsive?: boolean,
  itemsPerPage?: number,
  smallHeader?: boolean,
  headers: TableHeader<T>[]
}

export default function Table<T>(props: ITableProps<T>) {

  const {
    itemsPerPage = 15,
    maxSelectedItems,
    selectedItems,
    itemShouldRender,
    searchBoxAlwaysVisible,
    renderItem,
    headers,
    responsive,
    className,
    items,
    onSelectionChange,
    canSelect,
    getItemId,
    border = true
  } = props;

  const [elementsPerPage, setElementsPerPage] = React.useState<number>(itemsPerPage);

  const [filteredItems, setFilteredItems] = React.useState<Array<T>>([]);
  const [activeFilters, setActiveFilters] = React.useState<Array<IActiveFilter<T>>>([]);
  const [page, setPage] = React.useState<number>(0);
  const [filtering, setFiltering] = React.useState<boolean>(false);

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const tableRef = React.useRef<HTMLTableElement>(null);
  const sizerTimeout = React.useRef<any>(null);

  const filteringTimeoutRef = React.useRef<any>(null);

  React.useEffect(() => {
    let filterableItems = [...items];

    for (const filter of activeFilters) {
      if (!filter.filterValue) continue;

      filterableItems = filterableItems.filter(i => {
        try {
          if (!i) return false;
          if (!filter.header) return false;

          if (filter.header.valueKey) {
            const itemValue = ((i as any)[filter.header.valueKey] as any).toString().toUpperCase();
            return (itemValue as string).includes(filter.filterValue);
          }

          if (filter.header.filterItem) return filter.header.filterItem(i, filter.filterValue);
        }
        catch {
          return false;
        }
      })
    }

    setFilteredItems(filterableItems);

  }, [activeFilters, items]);

  React.useEffect(() => {
    if (!responsive) return;

    clearTimeout(sizerTimeout.current);

    sizerTimeout.current = setTimeout(() => {
      const availableHeight = wrapperRef.current?.clientHeight || 0;
      const tableHeight = tableRef.current?.clientHeight || 0;

      if (!availableHeight || !tableHeight) return;

      const scale = availableHeight / tableHeight;
      const items = Math.floor((elementsPerPage + 0.5) * scale);

      setElementsPerPage(items < 1 ? 1 : items);

    }, 50);

    return () => clearTimeout(sizerTimeout.current);
  }, [wrapperRef.current, tableRef.current, responsive]);

  const handleFilter = (header: TableHeader<T>, value: string, filterId: number) => {
    if (filteringTimeoutRef.current) clearTimeout(filteringTimeoutRef.current);
    if (typeof header === "string") return;

    setFiltering(true);

    filteringTimeoutRef.current = setTimeout(() => {
      try {
        const currentFilters = [...activeFilters];

        const existingIndex = currentFilters.findIndex(c => c.index === filterId);

        if (existingIndex < 0) {
          if (!value) return;

          currentFilters.push({ filterValue: value, header: header, index: filterId });
          setActiveFilters(currentFilters);
          return;
        }

        if (!value) {
          setActiveFilters(currentFilters.filter(x => x.index !== filterId));
          return;
        }

        currentFilters[existingIndex].filterValue = value.toUpperCase().trim();
        setActiveFilters(currentFilters);
      }
      finally {
        setFiltering(false);
      }
    }, 350);
  }

  const getIdFromItem = (item: T) => getItemId ? getItemId(item) : `${item}`;

  const handleSelect = (item: T) => {
    const id = getIdFromItem(item);
    const newSelection = new Map(selectedItems);

    if (newSelection.has(id)) newSelection.delete(id);
    else newSelection.set(id, item);

    onSelectionChange?.(newSelection);
  }

  if (!items?.length) return <span>Keine Elemente</span>

  const pages = Math.ceil(filteredItems.length / elementsPerPage);
  const start = page * elementsPerPage;

  const visible = filteredItems.slice(start, start + elementsPerPage);

  const wrapperClass = generateClassName("table-wrapper w-100", className, {
    value: border,
    onTrue: "table-wrapper-border"
  }, {
    value: responsive,
    onTrue: "h-100"
  });

  return (
    <>
      <div className={wrapperClass} ref={wrapperRef}>
        <table className="table w-100 table-responsive" ref={tableRef}>
          <thead style={{ backgroundColor: "var(--backgroundLighter)"}} >
            <tr>
              {
                canSelect && (
                  <TableCheckbox
                    as="th"
                    isSelected={selectedItems?.size === filteredItems?.length}
                    onChange={val => {
                      const ids = new Map<string, T>();
                      if (val) filteredItems.forEach(i => {
                        const id = getIdFromItem(i);
                        ids.set(id, i);
                      });
                      onSelectionChange?.(ids);
                    }}
                  />
                )
              }
              {
                headers && !!headers.length && (
                  headers.map((h: TableHeader<T>, index: number) => {

                    const headerValue = typeof h === "string" ? h : h.label;

                    return (
                      <TableHeader
                        key={`table-header-${headerValue || index}`}
                        searchBoxAlwaysVisible={typeof h !== "string" && searchBoxAlwaysVisible}
                        hidden={typeof h !== "string" && h.hidden}
                        onFilter={(v: string) => handleFilter(h, v, index)}
                        canFilter={typeof h !== "string" && (!!h.filterItem || !!h.valueKey)}
                        value={headerValue}
                      />
                    )
                  })
                )
              }
            </tr>
          </thead>
          <tbody className="w-100 table-content">
            {
              filtering
                ? <LoadingSpinner asTableRow text="Lädt Ergebnisse..." />
                : (
                  <>
                    {
                      visible?.length
                        ? visible.map(c => {
                          if (itemShouldRender && !itemShouldRender(c)) return null;
                          return renderItem(c, canSelect && (
                            <TableCheckbox
                              isSelected={!!selectedItems?.has(getIdFromItem(c))}
                              onChange={() => handleSelect(c)}
                            />
                          ))
                        })
                        : <TableRow><TableCell>Keine Einträge</TableCell></TableRow>
                    }
                  </>
                )
            }
          </tbody>
        </table>
      </div>
      <PageIndicator currentPage={page} onPageChange={setPage} totalPages={pages} />
    </>
  )
}