import { OfficeAccountType } from "../types/officeAccount.types";
import useLabels, { Label } from "./useLabels";

export default function useOfficeAccountUtil() {
  
  const getLabel = useLabels();

  const getColor = (type: OfficeAccountType) => {
    switch (type) {
      case OfficeAccountType.Personal: return "#F0F0F0";
      case OfficeAccountType.Team: return "#A0A0FF";
      case OfficeAccountType.Tenant: return "#FF5050";
    }
  }

  const getIcon = (type: OfficeAccountType) => {
    switch (type) {
      case OfficeAccountType.Personal: return "person";
      case OfficeAccountType.Team: return "people";
      case OfficeAccountType.Tenant: return "building";
    }
  }

  const getText = (type: OfficeAccountType) => {
    switch (type) {
      case OfficeAccountType.Personal: return "Persönlich";
      case OfficeAccountType.Team: return "Team";
      case OfficeAccountType.Tenant: return getLabel(Label.TenantName);
    }
  }


  return {
    getColor,
    getIcon,
    getText
  }
}