import React from "react";
import { usersMassImportEmployees } from "../../api/Api";
import { IEmployeesImportRequest } from "../../api/ApiRequests";
import { ModalType } from "../../config/ModalTypes";
import useApi from "../../hooks/useApi";
import useModal from "../../hooks/useModal";
import useRoleUtil from "../../hooks/useRoleUtil";
import { useEmployees } from "../../state/api/employees/useEmployees";
import { UserRole } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import LabelButton from "../buttons/LabelButton";
import Flex from "../container/Flex";
import CsvSelect from "../files/CsvSelect";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import Table from "../tables/Table";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import "./ImportEmployeeForm.css";

export enum UploadableEmployeeKeys {
    FirstName = "Vorname",
    LastName = "Nachname",
    MailAddress = "Email",
    RoleId = "Rolle",
    IsTenantAdmin = "Tenant-Admin"
}

export interface IUploadableEmployee {
    firstName: string,
    lastName: string,
    mailAddress: string,
    roleId: string,
    isTenantAdmin: boolean
}

export enum UploadableEmployeeCsvRole {
    Secretary = "0",
    Clerk = "1",
    TeamLead = "2",
    Partner = "3"
}

interface IImportEmployeesResponse {
    successfulImports: number,
    errors: [{
        row: number,
        error: string
    }?]
}

export default function ImportEmployeeForm() {

    const [apiResponse, setApiResponse] = React.useState<IImportEmployeesResponse>();
    
    const callApi = useApi();

    const showModal = useModal();

    const { reloadEmployees  } = useEmployees();

    const {
        getRoleId
    } = useRoleUtil();

    const downloadSampleCsv = async () => {
        const link = document.createElement('a');
        const data = [
            [
                UploadableEmployeeKeys.FirstName,
                UploadableEmployeeKeys.LastName,
                UploadableEmployeeKeys.MailAddress,
                UploadableEmployeeKeys.RoleId,
                UploadableEmployeeKeys.IsTenantAdmin
            ],
            [
                "Sekretariat", "Muster", "info@mustermail.de", "0", "1"
            ],
            [
                "Sachbearbeiter", "Muster", "sb@mustermail.de", "1", "0"
            ],
            [
                "Teamleiter", "Muster", "teamlead@mustermail.de", "2", "0"
            ],
            [
                "Partner", "Muster", "partner@mustermail.de", "3", "0"
            ]
        ];

        const csv = data.map(x => x.join(";")).join("\n");
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        link.download = 'employees.csv'
        link.click();
    }

    if (1) return null;

    return (
        <WithPermissions permissions={[ "users.employees.create" ]}>

            <ModalForm 
                title="Mitarbeiter importieren" 
                button={<Button icon="upload" text="Mitarbeiter importieren" color="secondary" />}
                initialValues={{
                    employees: [] as IUploadableEmployee[]
                } as IEmployeesImportRequest}
                onSubmit={async (values) => {
                    setApiResponse(undefined);

                    if (!values || !values.employees || !values.employees.length) {
                        showModal({
                            text: "Es wurden keine verarbeitbaren Daten gefunden. Bitte überprüfen Sie das Format Ihres Uploads.",
                            type: ModalType.Error
                        });
                        return false;
                    }

                    const res = await callApi<IImportEmployeesResponse>(usersMassImportEmployees(values));

                    if (!res) return false;

                    setApiResponse(res.data);
                    await reloadEmployees();
                    return true;
                }}
                sidebar={formik => <Button disabled={!formik.dirty} loading={formik.isSubmitting} icon="save" loadingText="Bitte warten..." type="submit" text={formik.dirty && formik.values.employees && formik.values.employees.length ? `${formik.values.employees.length} Mitarbeiter hochladen` : "Datei auswählen"} color="success" />}
            >
                {
                    (formik) => {

                        const getRoleIdFromCsvRoleId = (csvRole: string): string | null=> {
                            if (!csvRole) return null;

                            let role: UserRole = UserRole.Clerk;

                            switch(csvRole) {
                                case UploadableEmployeeCsvRole.Secretary: role = UserRole.Secretary; break;
                                case UploadableEmployeeCsvRole.Partner: role = UserRole.Partner; break;
                                case UploadableEmployeeCsvRole.TeamLead: role = UserRole.TeamLead; break;
                            }

                            return getRoleId(role);
                        }

                        const handleCsvUpload = (data: Papa.ParseStepResult<string[]>[]) => {
                            if (!data || !data.length) {
                                showModal({text: "Bitte laden Sie eine CSV-Datei im angegebenen Format hoch.", type: ModalType.Error});
                                return;
                            }

                            const employeesToImport = data.map((d: Papa.ParseStepResult<any>): IUploadableEmployee | null => {
                                try {

                                    if (!d || !d.data || !d.data) return null;

                                    const role = d.data[UploadableEmployeeKeys.RoleId];
                                    const firstName = d.data[UploadableEmployeeKeys.FirstName];
                                    const lastName = d.data[UploadableEmployeeKeys.LastName];
                                    const isTenantAdminIndicator = d.data[UploadableEmployeeKeys.IsTenantAdmin];
                                    const mailAddress = d.data[UploadableEmployeeKeys.MailAddress];

                                    if (!role) return null;

                                    const roleId = getRoleIdFromCsvRoleId(role);

                                    if (!roleId) return null;

                                    const isTenantAdmin = !!isTenantAdminIndicator && isTenantAdminIndicator === "1";

                                    if (!firstName || !lastName || !mailAddress) return null;

                                    return {
                                        firstName: firstName,
                                        lastName: lastName,
                                        mailAddress: mailAddress,
                                        roleId: roleId,
                                        isTenantAdmin: isTenantAdmin
                                    };
                                }
                                catch {
                                    return null;
                                }
                            }).filter((e: any) => e !== null);
                            
                            if (!employeesToImport || !employeesToImport.length) {
                                showModal({
                                    text: "Es wurden keine verarbeitbaren Daten gefunden. Bitte vergleichen Sie das Format Ihres Uploads mit dem Beispieldokument oder verwenden Sie dieses.",
                                    type: ModalType.Error
                                });
                                return;
                            }

                            formik.setFieldValue("employees", employeesToImport);
                        }

                        return (
                            <Flex className="w-100">
                                <CsvSelect processResult={handleCsvUpload} />
                                {
                                    apiResponse && (
                                        <div className="d-flex flex-column">
                                            <h6 className="fw-bold">Ergebnis</h6>
                                            <span><strong>{apiResponse.successfulImports}</strong> erfolgreiche Importvorgänge</span>
                                            {
                                                apiResponse.errors && apiResponse.errors.length
                                                ? (
                                                    <>
                                                        <span><strong style={{color: "red"}}>{apiResponse.errors && apiResponse.errors.length}</strong> Fehler</span>
                                                        <div className="import-employee-result-container mt-2" style={{fontSize: "0.9em"}}>
                                                            <Table 
                                                                border 
                                                                headers={[{label: "Zeile"}, {label: "Fehler"}]}
                                                                items={apiResponse.errors}
                                                                renderItem={e => e && (
                                                                    <TableRow key={`${e.row}${e.error}`}>
                                                                        <TableCell content={e.row} />
                                                                        <TableCell content={e.error} />
                                                                    </TableRow>
                                                                )}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                                : <span>Keine Fehler</span>
                                            }
                                        </div>
                                    )
                                }
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="fw-bold">Beispielformat</h6>
                                    <LabelButton onClick={downloadSampleCsv} text="Beispieldatei herunterladen" />
                                </div>
                                <Table 
                                    border 
                                    headers={[{label: UploadableEmployeeKeys.FirstName}, {label: UploadableEmployeeKeys.LastName}, {label: UploadableEmployeeKeys.MailAddress}, {label: UploadableEmployeeKeys.RoleId}, {label: UploadableEmployeeKeys.IsTenantAdmin}]}
                                    items={[123]}
                                    renderItem={x => (
                                        <TableRow>
                                            <TableCell>Test</TableCell>
                                            <TableCell>Testson</TableCell>
                                            <TableCell>test.testson@test.de</TableCell>
                                            <TableCell>
                                                <div className="d-flex flex-column">
                                                    <span>0 = {UserRole.Secretary}</span>
                                                    <span>1 = {UserRole.Clerk}</span>
                                                    <span>2 = {UserRole.TeamLead}</span>
                                                    <span>3 = {UserRole.Partner}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="d-flex flex-column">
                                                    <span>0 oder leer = Nein</span>
                                                    <span>1 = Ja</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                />
                                <em>Beim Import wird jeder Mitarbeiter zum Anlegen eines Kontos gebeten und erhält dazu eine E-Mail.</em>
                            </Flex>
                        )
                    }
                }
            </ModalForm>    
        </WithPermissions>
    )
}