import { NoInfer, UndefinedInitialDataOptions, useMutation, useMutationState, useQuery, useQueryClient } from "@tanstack/react-query";

export interface IMutateOpts {
  revalidate?: boolean
}

export type TiggiQueryOpts<T = any> = Omit<UndefinedInitialDataOptions<T>, "queryKey">;

export default function useTiggiQuery<ResultT = any>(key: string | null, options: TiggiQueryOpts<ResultT> = {}) {

  const queryClient = useQueryClient();

  const queryKey = [key ?? ""];

  const queryOpts: UndefinedInitialDataOptions<ResultT> = {
    staleTime: 10000,
    ...options,
    queryKey
  };

  const {
    data,
    isLoading,
    isRefetching,
    refetch
  } = useQuery<ResultT>(queryOpts);

  const mutate = async (updateFn?: (prev: NoInfer<ResultT> | undefined) => ResultT | undefined | null, opts: IMutateOpts = { revalidate: false }) => {
    const {
      revalidate
    } = opts;

    const res = (
      updateFn
        ? await queryClient.setQueryData<ResultT>(queryKey, old => updateFn(old) ?? undefined)
        : (await refetch())?.data
    );

    if (revalidate) queryClient.invalidateQueries({ queryKey });

    return res;
  }

  return {
    data,
    isRefetching,
    isLoading,
    isValidating: isLoading,
    mutate
  }
}