import React from "react";
import Page from "../../components/container/Page";
import TeamsCreateFromResponsibilityButton from "../../components/teams/TeamsCreateForDatevAreasOfResponsibility";
import TeamsList from "../../components/teams/TeamsList";
import TeamUpdateForm from "../../components/teams/TeamUpdateForm";
import { Locale } from "../../locale/Locale";

export default function TeamsManagement() {
  return (
    <Page
      showBackButton
      header={Locale.titles.teamsManagement}
      actionButtons={[
        <TeamsCreateFromResponsibilityButton key="teams-create-from-responsibilities-button" />,
        <TeamUpdateForm key="team-create-button" />
      ]}
    >
      <TeamsList />
    </Page>
  )
}