import React from "react";
import AddDomainForm from "../../components/domain/AddDomainForm";
import DomainList from "../../components/domain/DomainList";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import Page from "../../components/container/Page";
import { Locale } from "../../locale/Locale";
import { useDomains } from "../../state/api/domains/useDomains";

export default function AllDomainsManagement() {
  const { domains, loadingDomains } = useDomains();

  return (
    <Page header={Locale.titles.domainManagement} showBackButton actionButtons={<AddDomainForm />}>
      {
        loadingDomains
          ? <LoadingSpinner />
          : <DomainList domains={domains || []} />
      }
    </Page>
  )
}