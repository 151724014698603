import React from "react";
import TenantRequestAccountForm from "../../components/tenant/TenantRequestAccountForm";
import Page from "../../components/container/Page";

export default function TenantRequestOnboarding() {
  return (
    <Page fullscreen>
      <TenantRequestAccountForm />
    </Page>
  )
}