import React from "react";
import { useNavigate } from "react-router-dom";
import UserProfileHeader from "../../components/user/profile/UserProfileHeader";
import { AppRoutes } from "../../config/AppRoutes";
import { useAppSelector } from "../../hooks/reduxHooks";
import Page from "../../components/container/Page";
import { Locale } from "../../locale/Locale";
import UserVacationList from "../../components/user/vacations/UserAbsenceList";
import { useUser } from "../../state/api/user/useUser";

export default function Profile() {

  const { user } = useUser();

  if (!user) return null;

  return (
    <Page showBackButton header={Locale.titles.profile}>
      <UserProfileHeader />
      <UserVacationList user={user} />
    </Page>
  )
}