import React from "react";
import Flex from "../container/Flex";
import { useFormikContext } from "formik";
import { IWithIcon } from "../../types/withIcon.schema";
import Typography from "../text/Typography";
import FormikField from "../forms/FormikField";
import Icon from "../icons/Icon";
import Button from "../buttons/Button";
import Hyperlink from "../links/Hyperlink";
import ImageSelect from "../files/ImageSelect";
import ColorPicker from "../forms/ColorPicker";
import CustomCheckBox from "../forms/CustomCheckBox";

export interface IIconSettingsProps {
  optionalColor?: boolean
}

export default function IconSettings(props: IIconSettingsProps) {

  const {
    optionalColor
  } = props;

  const {
    values,
    setFieldValue
  } = useFormikContext<IWithIcon>();

  const [customColor, setCustomColor] = React.useState<boolean>(!optionalColor || !!values?.color);

  return (
    <Flex fullWidth>
      {
        (!values.iconBase64 || values.iconName) && (
          <Flex fullWidth>
            <Typography color="primary" bold>Icon</Typography>
            <Flex row fullWidth>
              <FormikField
                placeholder="Name des Icons"
                name="iconName"
                className="w-100"
              >
                {
                  values.iconName && <Icon color="muted" icon={values.iconName} wrapperClass="position-absolute top-50 end-0 translate-middle" />
                }
              </FormikField>
              {
                values.iconName && (
                  <Button
                    text="Icon entfernen"
                    onClick={() => setFieldValue("iconName", "")}
                    color="error"
                    icon="trash"
                    variant="text"
                    size="small"
                  />
                )
              }
            </Flex>
            <Typography wrap color="muted">Icons: <Hyperlink externalLink openInNewTab to="https://icons.getbootstrap.com/">Bootstrap Icons.</Hyperlink>Bitte kopieren Sie den in der Liste angezeigten Namen in das Feld. Sollte ein Icon nicht angezeigt werden, ist es nicht verfügbar.</Typography>
          </Flex>
        )
      }
      {
        (!values.iconName || values.iconBase64) && (
          <ImageSelect
            saveImage={(v) => setFieldValue("iconBase64", v)}
            image={values.iconBase64}
            render={select => (
              <Flex fullWidth>
                <Typography color="primary">Eigene Datei</Typography>
                <Flex row fullWidth justify="between" gap="4">
                  {
                    values.iconBase64
                      ? <img src={values.iconBase64} width="50" height="50" />
                      : <Typography color="muted">Kein Icon ausgewählt</Typography>
                  }
                  <Flex row>
                    <Button
                      text="Auswählen"
                      onClick={select}
                      icon="image"
                      variant="subtle"

                    />
                    {
                      values.iconBase64 && (
                        <Button
                          text="Entfernen"
                          onClick={() => setFieldValue("iconBase64", "")}
                          color="error"
                          icon="trash"
                          variant="text"
                        />)
                    }
                  </Flex>
                </Flex>
              </Flex>
            )}
          />
        )
      }
      <Flex fullWidth>
        <Flex row>
          <Typography color="primary" bold>Farbe</Typography>
          {
            optionalColor && (
              <CustomCheckBox
                initialValue={!!values.color}
                label="Eigene Farbe verwenden?"
                onChange={v => {
                  setCustomColor(v);
                  if (!v) setFieldValue("color", "");
                  else setFieldValue("color", "#000000");
                }}
              />
            )
          }
        </Flex>
        {
          customColor && <ColorPicker name="color" />
        }
      </Flex>
    </Flex>
  )
}