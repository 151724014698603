import { useFormikContext } from "formik";
import React from "react";
import { IUploadableFile } from "../../../types/ApiTypes";
import FileSelectButton, { IFileSelectButtonPropsBase } from "../../buttons/FileSelectButton";
import Flex from "../../container/Flex";
import MailAttachment from "../MailAttachment";
import { IOfficeMailFormValues } from "./OfficeMailForm";
import Typography from "../../text/Typography";

export interface IAttachmentSelectProps extends IFileSelectButtonPropsBase {
  label?: string
}

export default function AttachmentSelect(props: IAttachmentSelectProps) {

  const {
    disabled,
    label
  } = props;

  const {
    values,
    setFieldValue
  } = useFormikContext<IOfficeMailFormValues>();

  const attachments = values["attachments"] ?? [];

  const removeAttachment = (index: number) => {
    const newAttachments = attachments.filter((a, i) => i !== index);
    setFieldValue("attachments", newAttachments);
  }

  return (
    <Flex fullWidth align="start" justify="end">
      <Flex row justify="between" fullWidth>
        {label && <Typography bold color="primary">{label}</Typography>}
        <FileSelectButton
          {...props}
          values={attachments}
          accept="*"
          onChange={a => setFieldValue("attachments", a)}
        />
      </Flex>
      <Flex row wrap fullWidth>
        {
          attachments && !!attachments.length && attachments.map((a, i) => (
            <MailAttachment
              key={i}
              attachment={a}
              onClick={disabled ? undefined : () => removeAttachment(i)}
              hoverIcon="trash"
            />
          ))
        }
      </Flex>
    </Flex>
  )

}