import axios from "axios";
import { useAddressees } from "../state/api/addressees/useAddressees";
import { useClients } from "../state/api/clients/useClients";
import { useClientContacts } from "../state/api/clientContacts/useClientContacts";
import { useDatevEmployees } from "../state/api/employees/useDatevEmployees";
import { useEmployees } from "../state/api/employees/useEmployees";
import { useUsers } from "../state/api/user/useUsers";
import { DatevEndpoint } from "../types/datev.import.types";

export interface IDatevClientQuery {
  clientIdFrom: string,
  clientIdTo: string,
  consultantIds: string,
  alsoFetchInactiveData: boolean
}

export interface IDatevLogInData {
  username: string,
  password: string
}

export default function useDatevImport() {

  const { reloadAddressees } = useAddressees();
  const { reloadClients } = useClients();
  const { reloadClientContacts } = useClientContacts();
  const { reloadDatevEmployees } = useDatevEmployees();
  const { reloadEmployees } = useEmployees();
  const { reloadUsers } = useUsers();

  const reloadDatevImport = async () => {
    await reloadAddressees();
    await reloadClients();
    await reloadClientContacts();
    await reloadDatevEmployees();
    await reloadEmployees();
    await reloadUsers();
  }

  const localhostUrl = "https://localhost:58452/datev/api/master-data/v1/";

  const fetchEndpoint = async (abortController: AbortController, endpoint: DatevEndpoint, auth: IDatevLogInData, query?: IDatevClientQuery, isSingleAddressee?: boolean, singleAddresseeId?: string): Promise<any> => {
    try {
      const url = isSingleAddressee && singleAddresseeId ? getSingleAddresseeEndpoint(singleAddresseeId) : getEndpoint(endpoint, query);
      const res = await axios.get(url, { auth: auth, signal: abortController.signal });

      if (!res || !res.status) return null;

      if (res.status < 200 || res.status >= 300) return null;

      return res.data;
    }
    catch (err: any) {
      console.log(err);
    }

    return null;
  }

  const getEndpoint = (endpoint: DatevEndpoint, query?: IDatevClientQuery): string => {
    const url = new URL(endpoint, localhostUrl);

    const filterValue = getClientFilterValue(endpoint, query);
    if (!!filterValue) url.searchParams.append("filter", filterValue);

    return url.toString();
  }

  const getSingleAddresseeEndpoint = (id: string): string => {
    const endpoint = getEndpoint(DatevEndpoint.Addressees);
    return `${endpoint}/${id}?expand=*`;
  }

  const getConsultantIdQuery = (consultantIds: string): string => {
    if (!consultantIds) return "";
    if (typeof consultantIds !== "string") return "";
    const ids = consultantIds.split(";");
    if (!ids || !ids.length) return "";
    const queries = ids.map(i => `establishment_number eq ${i}`);
    return queries.join(" or ");
  }

  const getClientFilterValue = (endpoint: DatevEndpoint, query?: IDatevClientQuery): string => {

    if (endpoint != DatevEndpoint.Clients) return "";
    if (!query) return "";

    const filter: Array<string> = [];

    if (query.clientIdFrom) filter.push(`number le ${query.clientIdFrom}`);
    if (query.clientIdTo) filter.push(`number ge ${query.clientIdTo}`);

    const establishmentQuery = getConsultantIdQuery(query.consultantIds);

    if (establishmentQuery && !!establishmentQuery.length) filter.push(establishmentQuery);

    const filterValue = filter.join(" and ");

    return filterValue;
  }

  return {
    getEndpoint,
    reloadDatevImport,
    fetchEndpoint,
    getSingleAddresseeEndpoint
  }
}