import React from "react";
import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IPropsWithStyle } from "../../types/element.types";
import { generateClassName } from "../../hooks/useAttributes";
import "./Editor.css";

export interface IEditorProps extends IPropsWithStyle {
  content?: string,
  onChange: (val: string) => void,
  disabled?: boolean,
  onSubmitRequested?: () => void,
  onPaste?: (file: File) => void,
  onBlur?: ((event: any, editor: ClassicEditor) => void) | undefined,
  onFocus?: ((event: any, editor: ClassicEditor) => void) | undefined,
  useFieldStyle?: boolean,
  placeholder?: string
}

export default function Editor({ content, placeholder, useFieldStyle, onSubmitRequested, onPaste, onChange, onBlur, onFocus, className, disabled, style }: IEditorProps) {

  const editorClass = generateClassName("editor", className, {
    value: useFieldStyle,
    onTrue: "editor-field"
  });

  function CustomPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
      return {
        upload: async () => {
          const f = await loader.file;
          onPaste?.(f);
          return new Promise((resolve, reject) => reject())
        }
      }
    }
  }

  return (
    <div className={editorClass} style={style}>
      <CKEditor
        editor={ClassicEditor}
        config={{
          licenseKey: "GPL",
          placeholder,
          extraPlugins: [CustomPlugin]
        }}
        onReady={e => {
          e.editing.view.document.on("keydown", async (evt, data) => {
            if (!data.ctrlKey) return;

            switch (data.keyCode) {
              case 13: //enter
                onSubmitRequested?.();
                break;
            }
          });
        }}
        data={content}
        disabled={disabled}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          onChange(data);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  )
}