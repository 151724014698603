import { Form } from "formik";
import React from "react";
import { officeAccountsTransformMailToTicketMessage } from "../../../../api/Api";
import { IOfficeAccountAddMailAsMessageToTicketRequest } from "../../../../api/ApiRequests";
import { ModalType } from "../../../../config/ModalTypes";
import useApi from "../../../../hooks/useApi";
import useModal from "../../../../hooks/useModal";
import { IOfficeAccount } from "../../../../types/officeAccount.types";
import Button from "../../../buttons/Button";
import ModalForm from "../../../modal/ModalForm";
import TicketOverview from "../../../ticketing/lists/TicketOverview";

export interface IAddMailAsMessageToTicketButtonProps {
  account: IOfficeAccount,
  mailId?: string
}

export default function AddMailToTicketButton({ account, mailId }: IAddMailAsMessageToTicketButtonProps) {

  const callApi = useApi();
  const showModal = useModal();

  if (!account?._id) return null;
  if (!mailId) return null;

  return (
    <ModalForm
      fullscreen
      button={<Button text="An Ticket anhängen" variant="subtle" color="secondary" size="small" iconPosition="end" icon="chat" />}
      title="Mail als Nachricht an Ticket anhängen"
      initialValues={{
        id: account._id,
        mailId,
        ticketId: ""
      } as IOfficeAccountAddMailAsMessageToTicketRequest}
      onSubmit={async (values) => {

        if (!values.ticketId) {
          showModal({
            text: "Bitte wählen Sie Ticket aus",
            type: ModalType.Error
          });

          return false;
        }

        const res = await callApi(officeAccountsTransformMailToTicketMessage(values));
        return !!res?.success;
      }}
      sidebar={formik => <Button type="submit" text="Als Nachricht hinzufügen" icon="plus" loading={formik.isSubmitting} disabled={!formik.values.ticketId} />}
    >
      {
        formik => (
          <Form className="w-100">
            <TicketOverview
              maxSelectedItems={1}
              appearance="compact"
              onSelectionChange={t => {
                const first = t?.values()?.next()?.value;
                formik.setFieldValue("ticketId", first?._id)
              }}
            />
          </Form >
        )
      }
    </ModalForm >
  );
}