import React from "react";
import Page from "../../components/container/Page";
import EmployeeList from "../../components/employees/EmployeeList";
import ImportEmployeeForm from "../../components/employees/ImportEmployeeForm";
import UserUpdateForm from "../../components/user/UserUpdateForm";
import { Locale } from "../../locale/Locale";

export default function EmployeeManagement() {
  return (
    <Page
      showBackButton
      header={Locale.titles.employeeManagement}
      actionButtons={[<ImportEmployeeForm key="import-employee-form" />, <UserUpdateForm key="create-employee-form" />]}
    >
      <EmployeeList />
    </Page>
  )
}