import React from "react";
import useFilteredUsers, { DisplayedUsers, IWithFilteredUsers } from "../../hooks/useFilteredUsers";
import useUserUtil from "../../hooks/useUserUtil";
import { DbId, IUser } from "../../types/ApiTypes";
import SearchableComboBox, { ISearchableComboBoxBase } from "../comboBox/SearchableComboBox";
import UserRoleBanner from "./RoleBanner";
import TabSwitcher from "../tabswitcher/TabSwitcher";

export interface IUserSelectProps extends IWithFilteredUsers, ISearchableComboBoxBase<IUser> {
  shouldItemRender?: (item: IUser) => boolean,
  value?: string | null,
  icon?: string,
  useDefaultValue?: boolean,
  allowAnyValue?: boolean,
  className?: string,
  bold?: boolean,
  label?: string,
  placeholder?: string
}

export default function UserSelect(props: IUserSelectProps) {

  const {
    showTheseIds,
    useDefaultValue = true,
    initialTab = "all",
    displayed = "all",
    showWithoutRole
  } = props;

  const [currentDisplayed, setCurrentDisplayed] = React.useState<DisplayedUsers>(initialTab);

  const {
    loading,
    users
  } = useFilteredUsers({ displayed: displayed === "all" ? currentDisplayed : displayed, showWithoutRole, showTheseIds });

  const {
    getName
  } = useUserUtil();

  return (
    <SearchableComboBox
      {...props}
      values={users}
      loading={loading}
      filterPlaceholder="Nutzer suchen..."
      useDefaultValue={useDefaultValue}
      itemToId={(u) => u._id}
      additionalFilterComponents={(
        displayed === "all" && (
          <TabSwitcher
            initialData={currentDisplayed}
            saveActiveTab={t => setCurrentDisplayed(t as DisplayedUsers)}
            size="tiny"
            tabs={[
              { data: "all", label: "Alle" },
              { data: "employees", label: "Mitarbeiter" },
              { data: "clients", label: "Mandanten" },
            ]}
          />
        )
      )}
      renderItem={(u) => <UserRoleBanner className="w-100" displayNameAsValue user={u} showMail />}
      itemToString={(u, e) => {
        if (!e) return `${u}`;
        return u.mailAddress;
      }}
      filterFunction={(u, f) => {
        if (!u) return false;

        const mailIncludesValue = u.mailAddress.toUpperCase().includes(f);

        if (mailIncludesValue) return true;

        const name = getName(u);

        if (!name) return false;

        return name.toUpperCase().includes(f);
      }}
    />
  )

}