import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IGraphSubscription } from "../../../types/graphSubscription.schema";
import { IOfficeAccount } from "../../../types/officeAccount.types";

export const useOfficeAccountGraphSubscriptions = (account: IOfficeAccount) => {
  const { data, isLoading, mutate } = useTiggiQuery<IGraphSubscription[]>(ApiRoutes.OfficeAccounts.GetActiveGraphSubscriptionsForAccount.getValue({ param: RouteParam.OfficeAccountId, value: account._id }));

  return {
    graphSubscriptions: data ?? [],
    loadingGraphSubscriptions: isLoading,
    reloadGraphSubscriptions: mutate
  }
}
