import React from "react";
import useClientResponsibility from "../../hooks/useClientResponsibility";
import PageLoader from "../loader/PageLoader";
import WithPermissions from "../permissions/WithPermissions";
import Table from "../tables/Table";
import ClientResponsibilityItem from "./ClientResponsibilityItem";

export default function ClientResponsibilityList() {

  const {
    loading,
    sortedClientResponsibility
  } = useClientResponsibility();

  if (loading) return <PageLoader />

  return (
    <WithPermissions permissions={["clientResponsibilities.all.read"]}>
      <Table
        responsive
        headers={[{ label: "Mandantennummer" }, { label: "Mandant" }, { label: "Zuständigkeiten" }]}
        items={sortedClientResponsibility}
        renderItem={s => <ClientResponsibilityItem key={s.client._id} responsibilities={s} />}
      />
    </WithPermissions>
  )
}