import React from "react";
import { IDomain } from "../../types/ApiTypes";
import WithPermissions from "../permissions/WithPermissions";
import Table from "../tables/Table";
import DomainListItem from "./DomainListItem";

export default function DomainList({ domains }: { domains: IDomain[] | null }) {
  if (!domains || !domains.length) return <span>Keine Domains gefunden.</span>

  return (
    <WithPermissions permissions={["domains.tenant.read"]}>
      <Table
        border
        responsive
        headers={[{ label: "Domain" }, { label: "Verifiziert" }, { label: "" }]}
        items={domains}
        renderItem={(d: IDomain) => <DomainListItem key={d._id} domain={d} />}
      />
    </WithPermissions>
  )
}