import { IMongooseDocument } from "./ApiTypes"
import { IDebuggableResource } from "./debug.schema"

export enum NewTicketMailBehaviour {
  DoNotCreateTicket = "doNotCreateTicket",
  CreateFromKnownMailAddresses = "createFromKnownMailAddresses",
  CreateFromAllMailAddresses = "createFromAllMailAddresses"
}

export enum OfficeAccountType {
  Tenant,
  Team,
  Personal
}

export enum OfficeAccountStatus {
  Active,
  TokenExpired,
  NeedsReAuth,
  Inactive
}

export interface IOfficeAccountDocument {
  isMainTenantAccount: boolean,
  assignments: {
    personalUser?: string,
    team?: string,
  },
  autoAssignTicketMailsToEmployees: boolean,
  isAvailableForAllUsers: boolean,
  availableForUsers?: string[],
  newTicketMailBehaviour: NewTicketMailBehaviour
}

export interface IOfficeAccount extends IOfficeAccountDocument, IMongooseDocument, IDebuggableResource {
  officeAccountId: string,
  lastSubscriptionStart: Date,
  accessToken: string,
  tokenExpires: Date,
  status: OfficeAccountStatus,
  accountMail: string,
  invariantAccountMail: string,
  accountName: string,
  type: OfficeAccountType
}
